import type { ColumnDef, HeaderContext } from '@tanstack/vue-table'

export const createColumnHeader = (
  column: ColumnDef<any, any>,
  slots: ReturnType<typeof useSlots>,
  props: HeaderContext<any, any>,
) => {
  const header =
    typeof column.header === 'function' ? column.header(props) : column.header
  const slot = slots[`header-${props.column.id}`] ?? slots.header

  if (slot) {
    const slotProps = column.meta?.slotProps ?? {}
    return slot({
      ...slotProps,
      id: props.column.id,
      header,
    })
  }

  return header
}
