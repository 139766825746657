<script setup lang="ts">
import type { IOptionExerciseDetailsValue } from '@register/components/Review/Form/OptionExerciseDetails/types'
const props = defineProps<{
  includable: boolean
  item: IOptionExerciseDetailsValue
  previousItem?: IOptionExerciseDetailsValue
  nextItem?: IOptionExerciseDetailsValue
  isLast: boolean
  isFirst: boolean
}>()
defineEmits<{
  (e: 'includeInForecast', value: IOptionExerciseDetailsValue): void
}>()

const isNextOptionIncluded = computed(
  () => props.includable && !props.isLast && props.nextItem?.includeInForecast,
)

const isPreviousOptionNotIncluded = computed(
  () =>
    props.includable &&
    !props.isFirst &&
    !props.previousItem?.includeInForecast,
)

const tooltipClass = computed(() =>
  isNextOptionIncluded.value || isPreviousOptionNotIncluded.value
    ? 'bg-warning text-gray-950'
    : 'text-white bg-gray-950',
)

const tooltipContent = computed(() => {
  if (!props.includable) return undefined

  if (isNextOptionIncluded.value)
    return 'You cannot exclude this option from forecast because the next option is included'

  if (isPreviousOptionNotIncluded.value)
    return 'You cannot include this option in forecast because the previous option is not included'

  return props.item?.includeInForecast
    ? 'Exclude this option'
    : 'Include this option'
})

const isButtonDisabled = computed(
  () =>
    !props.includable ||
    isNextOptionIncluded.value ||
    isPreviousOptionNotIncluded.value,
)
</script>

<template>
  <Tooltip
    class="max-w-68 ml-2 text-center text-xs"
    :class="tooltipClass"
    :content="tooltipContent"
    placement="left"
  >
    <span class="relative ml-4 flex h-full w-[160px] items-center">
      <!--  -->
      <Button
        :disabled="isButtonDisabled"
        :color="
          isButtonDisabled
            ? 'opacitySecondary'
            : item?.includeInForecast
              ? 'primary'
              : 'opacityPrimary'
        "
        size="sm"
        :class="[
          isButtonDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        ]"
        @click.prevent="
          () => (isButtonDisabled ? {} : $emit('includeInForecast', item))
        "
      >
        <Icon
          v-if="item?.includeInForecast && !isButtonDisabled"
          name="check"
        />
        {{
          item?.includeInForecast
            ? 'Included in Forecast'
            : 'Include in Forecast'
        }}
      </Button>
    </span>
  </Tooltip>
</template>
