import type { LeaseDocumentValue } from '../types'
import type {
  FormKitValidationRule,
  FormKitValidationMessage,
} from '@formkit/validation'
import { sentence as s } from '@formkit/i18n'
import { isNullish } from '@morev/utils'

/**
 * Determine if the given input's value length is full width.
 */
export const rule: FormKitValidationRule = (node, maxLength = Infinity) => {
  maxLength = isNaN(parseInt(maxLength)) ? Infinity : parseInt(maxLength)
  const { value } = node.value as LeaseDocumentValue
  if (isNullish(value)) return true
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length <= maxLength
  } else if (value && typeof value === 'object') {
    const length = Object.keys(value).length
    return length <= maxLength
  }
  return false
}

export const message: FormKitValidationMessage = ({
  name,
  args: [maxLength],
}) => {
  return `${s(name)} must be less than or equal to ${maxLength} characters.`
}
