import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { createGroupURIFactory } from '@manager'
import type {
  ILeaseManagerSidebarSection,
  INodeGroupTemplate,
  INodeGroup,
} from '@manager'

const fetchSidebar = async (divisionId: string, leaseId: string) => {
  const createGroupURI = createGroupURIFactory(divisionId, leaseId)

  const response = await fetchAPI<INodeGroupTemplate>(
    `/companies/${divisionId}/leases/${leaseId}/card/configuration`,
  )
  const groups = (response.nodes ?? []) as INodeGroup[]
  return groups.map((group) => {
    return {
      id: String(group.id!),
      title: group.label!,
      referenceName: group.name!,
      items: (group.nodes ?? []).map((section) => {
        const id = String(section.id)
        const referenceName = section.name
        const icon =
          'review/' +
          section.name?.charAt(0).toUpperCase() +
          section.name?.slice(1)

        return {
          id,
          title: section.label,
          icon,
          referenceName,
          to: createGroupURI({ id, referenceName }),
        }
      }),
    }
  }) as ILeaseManagerSidebarSection[]
}

export const useApiLeaseCardSidebar = (divisionId: string, leaseId: string) => {
  return useQuery({
    queryKey: [
      'companies',
      divisionId,
      'leases',
      leaseId,
      'card',
      'configuration',
    ],
    queryFn: () => fetchSidebar(divisionId, leaseId),
  })
}

export const fetchLeaseCardSidebar = async (
  divisionId: string,
  leaseId: string,
) => {
  const queryClient = useQueryClient()
  return queryClient.ensureQueryData({
    queryKey: [
      'companies',
      divisionId,
      'leases',
      leaseId,
      'card',
      'configuration',
    ],
    queryFn: () => fetchSidebar(divisionId, leaseId),
  })
}
