// Based on https://github.com/TanStack/table/blob/main/packages/table-core/src/core/column.ts#L78
import type { ColumnDefResolved } from '@tanstack/vue-table'

export const resolveColumnId = (
  resolvedColumnDef: ColumnDefResolved<any, any>,
) => {
  const accessorKey = resolvedColumnDef.accessorKey
  return (
    resolvedColumnDef.id ??
    (accessorKey ? accessorKey.replace('.', '_') : undefined) ??
    (typeof resolvedColumnDef.header === 'string'
      ? resolvedColumnDef.header
      : undefined)
  )
}
