export const Roles = {
  OrganizationAdmin: {
    id: '7d6b3b1f-78d1-439a-88ab-b5c8b4a4032e',
    name: 'organizationAdmin',
    displayName: 'Organization Admin',
  },
  CompanyAdmin: {
    id: '122a049b-9cff-4b19-b3f6-c08abd96af03',
    name: 'companyAdmin',
    displayName: 'Division Admin',
  },
  DivisionAdmin: {
    id: '122a049b-9cff-4b19-b3f6-c08abd96af03',
    name: 'divisionAdmin',
    displayName: 'Division Admin',
  },
  LeaseContributor: {
    id: '5623d2af-dcf0-4ad2-9b1f-078a6fef16cb',
    name: 'leaseContributor',
    displayName: 'Lease Contributor',
  },
  Unknown: {
    id: '0000000-0000-0000-0000-000000000000',
    name: 'unknown',
    displayName: 'Unknown Role',
  },
}
