import type {
  Column,
  ColumnDefResolved,
  ColumnPinningState,
} from '@tanstack/vue-table'
import type { TanstackTablePropsWithDefaults } from '..'
import { resolveColumnId } from '@ui/components/TanstackTable/utils'
import { useScroll } from '@vueuse/core'
import type { Ref } from 'vue'

export const usePinning = (
  props: TanstackTablePropsWithDefaults,
  wrapper: Ref<HTMLElement | null>,
) => {
  const columnPinning = computed(() => {
    const columns = props.columns as ColumnDefResolved<any, any>[]
    const columnPinningState = columns.reduce<ColumnPinningState>(
      (acc, item) => {
        const id = resolveColumnId(item)

        const fixed = resolveColumnPinning(item)

        if (id && !!fixed) {
          acc[fixed] = acc[fixed] || []
          acc[fixed]!.push(id)
        }

        return acc
      },
      {},
    )

    const hasLeftPinnedColumns = (columnPinningState.left?.length ?? 0) > 0

    if (hasLeftPinnedColumns && props.selection) {
      columnPinningState.left!.unshift('select')
    }
    return columnPinningState
  })

  const { x, arrivedState } = useScroll(wrapper)

  const tableHasOverflowXResize = ref(false)
  useEventListener('resize', () => {
    if (!wrapper.value) return
    tableHasOverflowXResize.value =
      wrapper.value.scrollWidth > wrapper.value.clientWidth
  })

  const getCommonPinningStyles = (column: Column<any>) => {
    const isPinned = column.getIsPinned()

    return {
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
      width: column.getSize(),
    }
  }

  const getCommonPinningVariants = (column: Column<any>) => {
    const isPinned = column.getIsPinned()
    const isLastLeftPinnedColumn =
      isPinned === 'left' && column.getIsLastColumn('left')
    const isFirstRightPinnedColumn =
      isPinned === 'right' && column.getIsFirstColumn('right')

    return {
      isPinned: !!isPinned,
      get isLastLeftPinnedColumn() {
        if (!isLastLeftPinnedColumn) return false
        const tableHasScrolled = !!wrapper.value && x.value > 0
        return tableHasScrolled && !arrivedState.left
      },
      get isFirstRightPinnedColumn() {
        if (!isFirstRightPinnedColumn || !wrapper.value) return false
        const tableHasOverflowXScroll =
          wrapper.value.scrollWidth > wrapper.value.clientWidth
        return arrivedState.right
          ? false
          : tableHasOverflowXScroll || tableHasOverflowXResize.value
      },
    }
  }

  return { columnPinning, getCommonPinningStyles, getCommonPinningVariants }

  function resolveColumnPinning(item: ColumnDefResolved<any, any>) {
    if (item.meta?.fixed === undefined) return
    return typeof item.meta.fixed === 'function'
      ? item.meta.fixed()
      : item.meta.fixed
  }
}
