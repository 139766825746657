<script
  setup
  lang="ts"
  generic="T extends RentReviewTableValue = RentReviewTableValue"
>
import { createColumnHelper } from '@tanstack/vue-table'
import {
  type TanstackTableColumn,
  useTanstackTableVariants,
} from '@ui/components/TanstackTable'
import type { RentReviewTableValue } from './types'
import { formatFixedAmountValue, formatRentIncreaseValue } from './utils'
import { IncreaseTableMethod, localizeRentReviewMethod } from '@register'

const props = withDefaults(
  defineProps<{
    data?: T[]
    label?: string
    disabled?: boolean
    isRequired?: boolean
    isApproved?: boolean
    type?: 'review' | 'option'
    showRent?: boolean
    showAmount?: boolean
    showMethod?: boolean
    expiryDate?: Date | string | null
    commencementDate?: Date | string | null
    baseRent?: string
    divisionDateFormat?: string
    leaseDateFormat: string
    reviewDateFormat?: 'start' | 'both'
    canAdd?: boolean
    canEdit?: boolean
    canDelete?: boolean
  }>(),
  {
    data: () => [],
    label: '',
    disabled: false,
    isRequired: false,
    isApproved: false,
    type: 'review',
    showRent: false,
    showAmount: false,
    showMethod: false,
    divisionDateFormat: undefined,
    expiryDate: undefined,
    commencementDate: undefined,
    baseRent: undefined,
    reviewDateFormat: 'start',
    canAdd: true,
    canEdit: true,
    canDelete: true,
  },
)
defineEmits<{
  edit: [type: T]
  delete: [type: T]
  add: []
}>()

const dayjs = useDayjs()

const sortedItems = computed<RentReviewTableValue[]>(() => {
  // We destruct the array, so we can sort without modifying the original. But we don't destruct its objects
  // in order to have the same reference and be able to find their indexes later.
  return [...props.data].sort((a, b) => {
    if (!a || !b) return 0

    // Compare by 'option'
    if (props.type === 'option' && a.option! !== b.option!) {
      return parseInt(a.option!) - parseInt(b.option!)
    }

    // If 'option' is equal or not option rent increase, compare by 'date'
    const aDate = dayjs(a.date, props.divisionDateFormat)
    const bDate = dayjs(b.date, props.divisionDateFormat)

    return bDate.isAfter(aDate) ? -1 : 1
  })
})

const optionDescription = computed(() => {
  const optionDescriptions: Record<string, number> = {}
  return sortedItems.value.map((obj) => {
    const option = props.type === 'option' ? obj.option! : undefined

    if (option) {
      if (!optionDescriptions[option]) {
        optionDescriptions[option] = 1
      }

      const _option = `${option} - Review ${optionDescriptions[option]}`
      optionDescriptions[option]++

      return _option
    }
    return option
  })
})

const formatDate = (date: string, index: number) => {
  if (props.reviewDateFormat === 'start') {
    return dayjs(date, props.divisionDateFormat).format(props.leaseDateFormat)
  }

  const _date = dayjs(date, props.divisionDateFormat)
  let nextDate =
    props.type === 'review' && props.expiryDate
      ? dayjs(props.expiryDate, props.divisionDateFormat)
      : undefined

  // If the date is after the expiry date, and is the last item in the table, we don't show the next date.
  if (
    nextDate &&
    _date.isAfter(nextDate) &&
    index === sortedItems.value.length - 1
  ) {
    // TODO: Should we format to lease date format or company date format?
    return _date.format(props.leaseDateFormat)
  }

  // If there is more items, we get the next date from the next item (next item date - 1 day)
  if (index < sortedItems.value.length - 1) {
    // TODO: Get date format from company config
    nextDate = dayjs(
      sortedItems.value[index + 1].date,
      props.divisionDateFormat,
    ).subtract(1, 'day')
  } else if (props.type === 'option') {
    nextDate = dayjs(date, props.divisionDateFormat)
      .add(1, 'year')
      .subtract(1, 'day')
  }

  // TODO: Should we format to lease date format or company date format?
  return `${_date.format(props.leaseDateFormat)} - ${
    // If we don't have lease expiry date, fallback to 'until lease expires'
    nextDate?.format(props.leaseDateFormat) ?? 'until lease expires'
  }`
}

const columnHelper = createColumnHelper<RentReviewTableValue>()
const columns = [
  columnHelper.display({
    id: 'review',
    header: () => (props.type === 'review' ? 'Review' : 'Option'),
    cell: ({ row }) =>
      props.type === 'review'
        ? row.index + 1
        : optionDescription.value[row.index],
  }),
  columnHelper.accessor('date', {
    header: 'Review date',
    cell: ({ getValue, row }) => formatDate(getValue(), row.index),
  }),
  columnHelper.accessor('method', {
    header: 'Method',
    cell: ({ row, getValue }) => {
      const method = getValue()

      // This method is only used in Marketing Levy
      if (method === 'Percentage') {
        return 'Percentage of rent'
      }

      if (
        method === IncreaseTableMethod.FIXED_AMOUNT ||
        method === IncreaseTableMethod.FIXED_PERCENT
      ) {
        return localizeRentReviewMethod(method)
      }

      const item = row.original
      return formatRentIncreaseValue(item)
    },
  }),
  columnHelper.accessor('value', {
    header: 'Value',
    cell: ({ getValue, row }) => {
      const item = row.original
      const value = getValue()

      if (item.status !== 'edited' && value) return value
      if (item.method === IncreaseTableMethod.MARKET && item.marketReviewAmount)
        return formatFixedAmountValue(item.marketReviewAmount)

      return formatRentIncreaseValue(item)
    },
  }),
  columnHelper.accessor('rent', {
    header: 'Rent',
    meta: {
      visible: props.showRent,
    },
  }),
  columnHelper.accessor('amount', {
    header: 'Amount',
    meta: {
      visible: props.showAmount,
    },
  }),
  columnHelper.display({
    id: 'actions',
  }),
]

const variant = useTanstackTableVariants(
  {
    slots: {
      base: '',
      tbodyTd: 'h-[56px]',
    },
    variants: {
      rounded: {
        true: {
          base: 'border',
        },
      },
      isApproved: {
        true: {
          base: 'border-primary ring-[1px] ring-primary/70',
        },
        false: {
          base: 'border-gray-650',
        },
      },
      status: {
        present: {
          tbodyTr: '!bg-white/5',
        },
      },
    },
  },
  {
    get isApproved() {
      return props.isApproved
    },
  },
)

const today = dayjs()
const statuses = computed(() => {
  const previousDates = props.data.filter((item) =>
    dayjs(item.date).isBefore(today),
  )
  const hasPreviousDates = previousDates.length > 0
  return props.data.map((item) => {
    if (
      hasPreviousDates &&
      previousDates.indexOf(item) === previousDates.length - 1
    ) {
      return 'present'
    } else if (hasPreviousDates && previousDates.indexOf(item) !== -1) {
      return 'past'
    } else {
      return 'future'
    }
  })
})
</script>

<template>
  <div class="mb-4">
    <h3
      class="mb-2 flex flex-auto justify-between text-sm font-medium text-gray-900 dark:text-white"
    >
      <span>
        {{ label }}
        <span v-if="isRequired">*</span>
      </span>
      <time
        v-if="label && !label?.includes('Option')"
        class="self-end px-2 text-xs"
      >
        <Tooltip
          content="Commencement date"
          class="border border-gray-700 bg-gray-950 text-xs"
          placement="top"
          outer-class="cursor-help"
        >
          {{
            commencementDate
              ? dayjs(commencementDate, divisionDateFormat).format(
                  leaseDateFormat,
                )
              : 'N/A'
          }}
        </Tooltip>
        -
        <Tooltip
          content="Expiry date"
          class="border border-gray-700 bg-gray-950 text-xs"
          placement="top"
          outer-class="cursor-help"
        >
          {{
            expiryDate
              ? dayjs(expiryDate, divisionDateFormat).format(leaseDateFormat)
              : 'N/A'
          }}
        </Tooltip>
      </time>
    </h3>

    <slot name="header" />

    <!-- TODO: Tanstack table cache is not updating, so we need to display the items (hidden) to force the cache to update -->
    <div class="hidden">
      {{ sortedItems }}
    </div>

    <TanstackTable
      class="border border-gray-800"
      :columns="columns"
      :data="[...sortedItems]"
      :variant="variant"
      :sticky="{ scroller: null }"
      rounded
      :class-tbody-tr="
        (row) => {
          return {
            status: statuses[row.index],
          }
        }
      "
    >
      <template #empty>
        <div
          class="flex items-center justify-center px-4 py-3 text-sm text-gray-500"
        >
          {{
            type === 'option'
              ? 'No option rent increases added'
              : 'No rent increases added'
          }}
        </div>
      </template>

      <template
        #item-review="{
          value,
          index,
        }: TanstackTableColumn<RentReviewTableValue>"
      >
        <span
          class="font-bold"
          :class="{
            'ml-4': type === 'review',
            'text-success': statuses[index] === 'present',
            'text-success/30': statuses[index] === 'past',
            'text-gray-400': statuses[index] === 'future',
          }"
        >
          {{ value }}
        </span>
      </template>

      <template
        #item-value="{
          item: rentIncrease,
          value,
        }: TanstackTableColumn<RentReviewTableValue>"
      >
        <Tooltip
          :content="title(rentIncrease.method)"
          class="border-700 bg-gray-950 text-xs"
          placement="top"
        >
          <div class="flex items-center gap-2">
            <span
              :class="{
                'rounded-lg bg-gray-800 px-2 py-px group-hover/row:bg-gray-700':
                  rentIncrease.formula,
              }"
            >
              {{ value }}
            </span>
          </div>
        </Tooltip>
      </template>

      <template
        #item-date="{ value }: TanstackTableColumn<RentReviewTableValue>"
      >
        <time>{{ value }}</time>
      </template>

      <template
        v-if="showRent"
        #item-rent="{ item, value }: TanstackTableColumn<RentReviewTableValue>"
      >
        <span v-if="item.status === 'edited' && !item.evaluatedAt"> - </span>
        <CurrencyDisplay v-else :value="value" prefix="$" />
      </template>

      <template
        v-if="showAmount"
        #item-amount="{ value }: TanstackTableColumn<RentReviewTableValue>"
      >
        <CurrencyDisplay :value="value" prefix="$" />
      </template>

      <template #item-actions="{ item: rentIncrease }: TanstackTableColumn<T>">
        <div
          v-if="!isApproved"
          class="flex justify-end gap-2 opacity-0 transition-all duration-100 group-hover/tr:opacity-100"
        >
          <button
            v-if="canEdit"
            class="text-primary hover:bg-primary-600 flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg transition duration-150 hover:text-black"
            :class="[disabled && 'pointer-events-none']"
            @click.prevent="$emit('edit', rentIncrease)"
          >
            <Icon name="edit" filled class="text-sm" />
          </button>
          <button
            v-if="type === 'review' && canDelete"
            class="flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg text-red-500 transition duration-150 hover:bg-red-500 hover:text-red-100"
            :class="[disabled && 'pointer-events-none']"
            @click.prevent="$emit('delete', rentIncrease)"
          >
            <Icon name="trash" filled class="text-sm" />
          </button>
        </div>
      </template>
      <template v-if="canAdd" #footer>
        <ButtonAddNewSection
          v-if="type === 'review' && !isApproved"
          title="Add rental review"
          icon="plus"
          size="xs"
          :disabled="disabled"
          hide-line
          @click.prevent="$emit('add')"
        />
        <slot name="footer" />
      </template>
    </TanstackTable>

    <div v-if="showRent" class="flex pt-1.5">
      <ul class="flex flex-row gap-4 text-xs text-gray-500">
        <li class="flex items-center gap-2">
          <span class="bg-success/40 flex h-2 w-2 rounded-full" />
          Previous rent
        </li>
        <li class="flex items-center gap-2">
          <span class="bg-success flex h-2 w-2 rounded-full" />
          Current rent
        </li>
        <li class="flex items-center gap-2">
          <span class="flex h-2 w-2 rounded-full bg-gray-300" />
          Forecast rent
        </li>
      </ul>
    </div>
  </div>
</template>
