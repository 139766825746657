import type { FieldConfigFactory } from '../../types'
import { isValidValue } from '../../utils'
import Textarea from '../../inputs/Textarea.vue'

export const stringFactory: FieldConfigFactory = (node) => {
  return {
    component: markRaw(Textarea),
    type: 'textarea',
    toFormatted: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return String(value)
    },
    toAPI: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return String(value)
    },
    toInput: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return String(value)
    },
    fromDocument: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return String(value)
    },
  }
}
