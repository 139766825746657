import { IncreaseTableMethod } from '@register'

export const RENT_INCREASE_TABLE_METHODS = [
  {
    label: 'Fixed Percent',
    value: IncreaseTableMethod.FIXED_PERCENT,
    fields: [
      {
        name: 'fixedPercent',
        label: 'Fixed Percentage',
        type: 'text',
      },
    ],
  },
  {
    label: 'Fixed Amount',
    value: IncreaseTableMethod.FIXED_AMOUNT,
    fields: [
      {
        name: 'fixedAmount',
        label: 'Fixed Amount',
        type: 'text',
      },
    ],
  },
  {
    label: 'CPI',
    value: IncreaseTableMethod.CPI,
    fields: [
      {
        name: 'cpiCap',
        label: 'CPI Cap',
        type: 'text',
      },
      {
        name: 'cpiCollar',
        label: 'CPI Collar',
        type: 'text',
      },
    ],
  },
  {
    label: 'CPI Plus',
    value: IncreaseTableMethod.CPI_PLUS,
    fields: [
      {
        name: 'cpiPercent',
        label: 'CPI Plus',
        type: 'text',
      },
      {
        name: 'cpiCap',
        label: 'CPI Cap',
        type: 'text',
      },
      {
        name: 'cpiCollar',
        label: 'CPI Collar',
        type: 'text',
      },
    ],
  },
  {
    label: 'Market',
    value: IncreaseTableMethod.MARKET,
    fields: [
      {
        name: 'marketReviewDate',
        label: 'Market Review Date',
        type: 'date',
      },
      {
        name: 'marketReviewCap',
        label: 'Market Review Cap',
        type: 'text',
      },
      {
        name: 'marketReviewCollar',
        label: 'Market Review Collar',
        type: 'text',
      },
    ],
  },
  {
    label: 'Formula',
    value: IncreaseTableMethod.FORMULA,
  },
  // {
  //   label: 'Multiple',
  //   value: IncreaseTableMethod.MULTIPLE,
  // },
]
