<script setup lang="ts">
import ActionButton from '../ActionButton.vue'
import type { LeaseDocumentInputContext } from '../../types'
import { computed } from 'vue'
import { twMerge } from 'tailwind-merge'
interface Props {
  context: LeaseDocumentInputContext
}

const props = defineProps<Props>()

const isApproved = computed(() => props.context.isApproved)
const confidence = computed(() => props.context.confidence)
const disabled = computed(() => props.context.disabled)

const toggle = () => {
  if (disabled.value) return

  props.context.fns.toggleTable()

  useAnalytics({
    name: 'Lease Review Change',
    data: {
      source: 'Toggle Table Clicked',
      value: true,
      'Confidence Score': confidence.value,
      'Is Edited': props.context.isEdited,
      'Field ID': props.context.id,
      'Field Value': props.context._value.value || props.context.value,
    },
  })
}

const buttonClass = computed(() => {
  const classes = ['hover:ring  h-7 w-7 z-10']
  classes.push(
    isApproved.value
      ? 'bg-primary hover:ring-primary/10 dark:text-black'
      : 'bg-gray-750 hover:bg-gray-300 hover:text-neutral-500 hover:ring-neutral-500/50',
  )
  return twMerge(classes)
})
</script>

<template>
  <ActionButton
    tooltip="Switch to table"
    icon="table_edit_icon"
    :disabled="disabled"
    class="z-[501]"
    :button-class="buttonClass.toString()"
    @click="toggle"
  />
</template>
