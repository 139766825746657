<script setup lang="ts">
import type { IOptionExerciseDetailsValue } from '@register/components/Review/Form/OptionExerciseDetails/types'
const props = defineProps<{
  exercisable: boolean
  item: IOptionExerciseDetailsValue
  previousItem?: IOptionExerciseDetailsValue
  nextItem?: IOptionExerciseDetailsValue
  isLast: boolean
  isFirst: boolean
}>()
defineEmits<{
  (e: 'exercise', value: IOptionExerciseDetailsValue): void
}>()

const dayjs = useDayjs()

const isNextOptionExercised = computed(
  () => props.exercisable && !props.isLast && props.nextItem?.isExercised,
)

const isPreviousOptionNotExercised = computed(
  () => props.exercisable && !props.isFirst && !props.previousItem?.isExercised,
)

const isStartDateBeforeToday = computed(
  () =>
    !props.item?.isExercised && dayjs().isBefore(props.item.exerciseStartDate),
)

const tooltipClass = computed(() =>
  isNextOptionExercised.value ||
  isPreviousOptionNotExercised.value ||
  isStartDateBeforeToday.value
    ? 'bg-warning text-gray-950'
    : 'text-white bg-gray-950',
)

const tooltipContent = computed(() => {
  if (!props.exercisable) return undefined

  if (isNextOptionExercised.value)
    return 'You cannot cancel the exercise of this option because the next option is exercised'

  if (isPreviousOptionNotExercised.value)
    return 'You cannot exercise this option because the previous option is not exercised'

  if (isStartDateBeforeToday.value)
    return 'You cannot exercise this option because the start date is in the future'

  return props.item?.isExercised ? 'Cancel this option' : 'Exercise this option'
})

const isButtonDisabled = computed(
  () =>
    !props.exercisable ||
    isNextOptionExercised.value ||
    isPreviousOptionNotExercised.value ||
    isStartDateBeforeToday.value,
)
</script>

<template>
  <Tooltip
    class="max-w-68 ml-2 text-center text-xs"
    :class="tooltipClass"
    :content="tooltipContent"
    placement="left"
  >
    <span class="relative ml-4 flex h-full w-[100px] items-center">
      <!--  -->
      <Button
        :disabled="isButtonDisabled"
        :color="
          isButtonDisabled
            ? 'opacitySecondary'
            : item?.isExercised
              ? 'primary'
              : 'opacityPrimary'
        "
        size="sm"
        :class="{
          'cursor-not-allowed opacity-50': isButtonDisabled,
        }"
        @click.prevent="() => (isButtonDisabled ? {} : $emit('exercise', item))"
      >
        <Icon v-if="item?.isExercised && !isButtonDisabled" name="check" />
        {{ item?.isExercised ? 'Exercised' : 'Exercise' }}
      </Button>
    </span>
  </Tooltip>
</template>
