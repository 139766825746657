import type { ColumnHelper } from '@tanstack/vue-table'
import IndeterminateCheckbox from '../IndeterminateCheckbox.vue'
import { createColumnHelper } from '@tanstack/vue-table'

const columnHelper = createColumnHelper<any>()
export const createSelectionColumn = () =>
  columnHelper.display({
    id: 'select',
    // TODO: Calculate padding based on prop
    size: 48,
    header: ({ table }) => {
      return (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      )
    },
    cell: ({ row }) => {
      return (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      )
    },
  })
