import { getCurrentLeaseId, useApiLeaseCurrentValue } from '@register'

export const useLeaseDates = () => {
  const dayjs = useDayjs()
  const companyId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  // const { commencementDate, expiryDate } = storeToRefs(store)

  const { dateFormat: divisionDateFormat } = useDivisions()

  // TODO: Can we only use the fields from LeaseCurrentValue api?
  const { fields } = useCurrentLeaseDocument()

  const commencementDate = computed(() => {
    const field = fields.value.find(
      (_field) => _field.id === 'CommencementDate',
    )
    return field?.value?.value
  })

  const expiryDate = computed(() => {
    const field = fields.value.find((_field) => _field.id === 'ExpiryDate')
    return field?.value?.value
  })

  const { data: commencementDateRes } = useApiLeaseCurrentValue(
    companyId,
    leaseId,
    'CommencementDate',
  )
  const { data: expiryDateRes } = useApiLeaseCurrentValue(
    companyId,
    leaseId,
    'ExpiryDate',
  )

  const currentCommencementDate = computed(() => commencementDateRes.value)
  const currentExpiryDate = computed(() => expiryDateRes.value)

  const hasLeaseDates = computed(() => {
    return commencementDate.value && expiryDate.value
  })

  const isBeforeCommencement = (date: string | undefined) => {
    if (!date || !commencementDate.value) return false
    const formatedDate = dayjs(commencementDate.value, divisionDateFormat.value)
    return dayjs(date).isBefore(formatedDate)
  }

  const isAfterExpiry = (date: string | undefined) => {
    if (!date || !expiryDate.value) return false
    const formatedDate = dayjs(expiryDate.value, divisionDateFormat.value)
    return dayjs(date).isAfter(formatedDate)
  }

  return {
    commencementDate,
    expiryDate,
    currentCommencementDate,
    currentExpiryDate,
    hasLeaseDates,
    isBeforeCommencement,
    isAfterExpiry,
  }
}
