import { useGoogleMapsLoader } from '@shared/composables/google-maps'

export default defineNuxtPlugin({
  name: 'google-maps',
  parallel: true,
  setup() {
    const { initialize } = useGoogleMapsLoader()
    initialize({
      apiKey: useRuntimeConfig().public.googleApiKey,
      version: 'weekly',
      libraries: ['places', 'maps', 'marker'],
    })
  },
})
