import type { FormKitNode } from '@formkit/core'
import { createMessage, reset } from '@formkit/core'
import type { ILeaseDocumentField } from '@register'
import type { LeaseDocumentValue } from '../types'

/**
 * A feature that set up the field value.
 *
 * @param node - A {@link @formkit/core#FormKitNode | FormKitNode}.
 *
 * @public
 */
export function setupValue(node: FormKitNode): void {
  node.on('created', () => {
    const field = node.context.field as ILeaseDocumentField
    const value: LeaseDocumentValue = node.context._value || {
      value: node.context.fieldConfig.toAPI(
        field.value?.value ?? field.value?.text,
      ),
      bounds:
        field.value?.bounds && field.value.documentId
          ? {
              bounds: field.value.bounds ?? [],
              documentId: field.value.documentId,
            }
          : null,
      isApproved: field.value?.isApproved ?? false,
    }
    node.props.initial = node.props._init = value
    reset(node, value)

    node.hook.input((payload, next) => {
      return next({
        value: payload?.value,
        bounds: payload?.bounds
          ? {
              bounds: payload.bounds.bounds ?? [],
              documentId: payload.bounds.documentId,
            }
          : null,
        isApproved: payload?.isApproved ?? node.context.isApproved ?? false,
      })
    })

    node.on('input', ({ payload }) => {
      const isApproved = (payload as LeaseDocumentValue).isApproved
      node.context.isApproved = isApproved

      if (isApproved) {
        node.store.set(createMessage({ key: 'isApproved', visible: false }))
      } else {
        node.store.remove('isApproved')
      }
    })
    node.on('reset', ({ payload: n }) => {
      node.context.isApproved = node.context._initApproved =
        n.props.initial.isApproved
    })
  })
}
