<script lang="ts" setup>
import { AuthStatus } from '@authentication'
import { useWindowSize } from '@vueuse/core'
import { Product } from '~/composables/useAPI/types'
import { useModal } from 'vue-final-modal'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const router = useRouter()
const { status } = useAuth()
const { width: widthWindow } = useWindowSize()

const isAuthenticated = computed(
  () => status.value === AuthStatus.Authenticated,
)
const {
  currentDivision: _currentDivisionByRoute,
  createDivisionURL,
  companies,
} = useDivisions()

const hasMultipleDivisions = computed(() => {
  return companies.value.length > 1
})

const firstDivision = computed(() => {
  return companies.value[0]
})

const currentDivision = computed(() =>
  !hasMultipleDivisions.value
    ? firstDivision.value
    : _currentDivisionByRoute.value,
)

const reminderCountAPI = useApiRemindersCount()
const reminderCount = computed(() => reminderCountAPI.data.value?.count ?? 0)

const { open: openCreateLeaseModal, close } = useModal({
  component: getAsyncComponent('LeaseCreateModal'),
  attrs: {
    // @ts-expect-error - Reactivity
    divisionId: computed(() => currentDivision.value?.id),
    onClose: () => {
      close()
    },
  },
})
</script>

<template>
  <div class="flex h-full w-full justify-end gap-x-1">
    <div class="flex grow items-center justify-start lg:gap-x-1">
      <NuxtLink
        to="/"
        class="flex max-w-[100px] focus:appearance-none focus:outline-none focus:outline focus:outline-offset-4 lg:min-w-[100px]"
      >
        <AccuraitLogo
          class="h-8 self-center"
          :type="widthWindow > 980 ? 'full' : 'compact'"
        />
      </NuxtLink>

      <NavigationSelectCompanyDropdown
        v-if="hasMultipleDivisions"
        class="flex"
      />

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="-translate-y-4 opacity-0"
        enter-to-class="translate-y-0 opacity-100 "
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="-translate-y-1 opacity-0 "
      >
        <!-- On single division, always show the links -->
        <div
          v-if="currentDivision"
          class="ml-2 hidden w-full grow flex-row lg:flex"
        >
          <NavigationButtonIcon
            :to="createDivisionURL(undefined, currentDivision.id)"
            title="Dashboard"
          />

          <NavigationButtonIcon
            :to="createDivisionURL('workflow', currentDivision.id)"
            title="AI Abstraction"
          />

          <NavigationButtonIcon
            :to="createDivisionURL('portfolio', currentDivision.id)"
            title="Portfolio"
          />

          <NavigationButtonIcon
            v-if="$can('product', Product.CRITICAL_DATES)"
            :to="createDivisionURL('critical-dates', currentDivision.id)"
            title="Reminders"
            :alert="reminderCount > 0"
            :tooltip="
              reminderCount ? `${reminderCount} reminders for today` : undefined
            "
          />

          <NavigationButtonIcon
            :to="createDivisionURL('reports', currentDivision.id)"
            title="Reports"
          />
        </div>
      </transition>
    </div>

    <div class="flex shrink items-center justify-end gap-x-1">
      <!-- New lease button -->
      <Menu
        v-if="currentDivision && $can('product', Product.LEASE_MANAGER)"
        as="div"
        class="relative"
      >
        <MenuButton class="z-[1]">
          <Button
            color="opacityPrimary"
            class="flex items-center whitespace-nowrap"
            icon="plus"
            size="sm"
          >
            Add Lease
          </Button>
        </MenuButton>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 flex w-52 origin-top-right flex-col gap-1 rounded-md bg-gray-900 p-2 shadow-lg"
          >
            <MenuItem
              class="z-10 cursor-pointer"
              @click="router.push(createDivisionURL('workflow'))"
            >
              <div
                class="hover:bg-gray-850 hover:text-primary rounded-lg px-4 py-2 text-sm"
              >
                Upload a document
              </div>
            </MenuItem>
            <MenuItem class="z-10 cursor-pointer" @click="openCreateLeaseModal">
              <div
                class="hover:bg-gray-850 hover:text-primary rounded-lg px-4 py-2 text-sm"
              >
                Enter manually
              </div>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>

      <!-- Search -->
      <!-- <NavigationSearch v-if="isAuthenticated" /> -->

      <!-- Upload button -->
      <NavigationUploadingButton v-if="isAuthenticated" />

      <!-- Notification button -->
      <NavigationNotificationButton class="hidden md:block" />

      <!-- Profile button -->
      <NavigationProfileButton />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.nav-icon {
  @apply hover:text-black;
}
.nav-link {
  @apply relative pb-4;
  &:before {
    @apply hover:bg-primary absolute -bottom-0.5 left-0 right-0 h-1 content-[''];
  }
}
.router-link-active {
  @apply text-primary;
  &:before {
    @apply bg-primary;
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease;
  max-height: 400px;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
