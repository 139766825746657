import type { RowSelectionState, Updater } from '@tanstack/vue-table'
import type { TanstackTableEmits, TanstackTablePropsWithDefaults } from '..'

export const useRowSelection = (
  props: TanstackTablePropsWithDefaults,
  emit: TanstackTableEmits,
) => {
  const selection = ref<RowSelectionState>({})

  watchEffect(() => {
    for (const item of props.selection ?? []) {
      const idx = props.data.indexOf(item)
      if (idx !== -1) {
        selection.value[idx] = true
      }
    }
  })

  const setSelection = (updaterOrValue: Updater<RowSelectionState>) => {
    selection.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(selection.value)
        : updaterOrValue

    emit(
      'update:selection',
      Object.keys(selection.value).map((key) => props.data[Number(key)]),
    )
  }

  return {
    selection,
    setSelection,
  }
}
