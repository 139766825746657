import type { Ref } from 'vue'
import type { LeaseDocumentInputContext } from '@ui/formkit/inputs/leaseDocumentInput/types'
import type { MaybeElement } from '~/types/helpers'
import autosize from 'autosize'

export function useAutoSize(
  element: Ref<MaybeElement>,
  context: Ref<LeaseDocumentInputContext>,
) {
  watch(
    () => context.value.isApproved,
    () => {
      setTimeout(() => {
        const _element = unrefElement(element)
        if (context.value.fieldConfig.type !== 'textarea' || !_element) return
        autosize(_element)
      }, 1)
    },
    {
      immediate: true,
    },
  )
}
