/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SizeUnit {
    SQUARE_METRES = 'squareMetres',
    SQUARE_FEET = 'squareFeet',
    SQUARE_YARDS = 'squareYards',
    PING = 'ping',
}
