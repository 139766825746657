import type { FieldConfigFactoryTable } from '../../types'
import { isValidValue } from '../../utils'
import { stringFactory } from '@ui/formkit/inputs/leaseDocumentInput/config/string'

export const tableFactory: FieldConfigFactoryTable = (node, tableComponent) => {
  node.context.isTable = true

  const value =
    node.context._value?.value ??
    node.context.field.value?.value ??
    node.context.field.value?.text

  if (!Array.isArray(value) && !node.context.forceTable) {
    return stringFactory(node)
  }

  return {
    schema: 'table',
    component: markRaw(tableComponent),
    type: 'tableFactory',
    toFormatted: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return value
    },
    toAPI: (value: any): any => {
      if (!isValidValue(value)) return
      return value
    },
    toInput: (value: any): any => {
      if (!isValidValue(value)) return
      return value
    },
    fromDocument: (value: string): any => {
      if (!isValidValue(value)) return
      return value
    },
  }
}
