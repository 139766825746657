<script setup lang="ts">
import type { LeaseDocumentInputContext } from '../../types'
import { useInputValue } from '../composables/useInputValue'
interface Props {
  context: LeaseDocumentInputContext
}
const props = defineProps<Props>()

const context = toRef(props, 'context')

const { inputValue } = useInputValue(context)

/* === ON SELECT ===*/
const onSelect = (key: string) => {
  inputValue.value = inputValue.value === key ? undefined : key
}
</script>

<template>
  <div class="flex w-full flex-wrap gap-6">
    <FormKit
      v-for="(field, key) in context.field.enumInfo?.values ?? {}"
      :key="context.field.id + key"
      :name="key"
      type="checkbox"
      :model-value="inputValue === key"
      :label="field"
      outer-class="whitespace-nowrap"
      @change="onSelect(key)"
    />
  </div>
</template>
