<script setup lang="ts">
import ActionButton from '../ActionButton.vue'

import type { LeaseDocumentInputContext } from '../../types'

const props = defineProps<{ context: LeaseDocumentInputContext }>()

const disabled = computed(
  () => !props.context.node.canRedo || props.context.isApproved,
)
const tooltip = computed(() => {
  if (props.context.isApproved) return 'Unable to modify approved field'
  return disabled.value ? 'Nothing to redo' : 'Redo this field'
})

const onClick = async () => {
  if (disabled.value) return
  await props.context.node.redo()
  // Emit
  props.context.handlers.redo(props.context.node)
  useAnalytics({
    name: 'Lease Field Toolbar Actions',
    data: {
      source: 'Redo',
      'Is Edited': props.context.isEdited,
      'Field ID': props.context.id,
      'Field Value': props.context._value.value || props.context.value,
    },
  })
}
</script>

<template>
  <ActionButton
    :tooltip="tooltip"
    icon="redo"
    :disabled="disabled"
    @click="onClick"
  />
</template>
