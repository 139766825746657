import type { FieldConfigFactory } from '../../types'
import { isValidValue } from '../../utils'
import Textarea from '../../inputs/Textarea.vue'
import Select from '../../inputs/Select.vue'

export const booleanFactory: FieldConfigFactory = (node) => ({
  component: markRaw(Textarea),
  panelComponent: markRaw(Select),
  type: 'select',
  options: [
    { label: 'YES', value: true },
    { label: 'NO', value: false },
  ],
  toFormatted: (value: any): string | undefined => {
    if (!isValidValue(value)) return
    if (typeof value === 'boolean') return value ? 'YES' : 'NO'
    return value
  },
  toAPI: (value: any): boolean | undefined => {
    if (!isValidValue(value)) return
    if (typeof value === 'string') {
      if (value === 'YES') return true
      if (value === 'NO') return false
    }
    return value
  },
  toInput: (value: any): string | undefined => {
    if (!isValidValue(value)) return
    if (typeof value === 'boolean') return value ? 'YES' : 'NO'
    return value
  },
  toPanel: (value: any): boolean | null => {
    if (!isValidValue(value)) return null
    if (typeof value === 'boolean') return value
    if (typeof value === 'string') {
      if (value === 'YES') return true
      if (value === 'NO') return false
    }
    return null
  },
  fromDocument: (value: any): string | undefined => {
    if (!isValidValue(value)) return
    return value
  },
})
