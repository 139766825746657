import { createSection } from '@formkit/inputs'
import { markRaw } from 'vue'
import Listbox from '@ui/components/Form/Listbox'

/**
 * Input section used by selects
 *
 * @public
 */
export const selectInput = createSection('input', () => ({
  $cmp: markRaw(Listbox) as unknown as string,
  bind: '$attrs',
  props: {
    options: '$options',
    labelBy: '$labelBy',
    trackBy: '$trackBy',
    format: '$format',
    searchable: true,
    multiple: true,
    taggable: '$taggable',
    disabled: '$disabled',
    placeholder: '$placeholder',
    emptyMessage: '$emptyMessage',
    loading: '$loading || $state.loading',
    'onUpdate:modelValue': '$node.input',
    modelValue: '$_value',
    internalSearch: '$internalSearch',
    hideDisplayValue: '$hideDisplayValue',
    customLabel: '$customLabel',
    max: '$max',
    allowEmpty: '$allowEmpty',
    closeOnSelect: '$closeOnSelect',
    // Classes
    inputOuterClass: '$classes.inputOuter',
    inputInnerClass: '$classes.inputInner',
    buttonInnerClass: '$classes.buttonInner',
    buttonIconClass: '$classes.buttonIcon',
    dropdownWrapperClass: '$classes.dropdownWrapper',
    listboxClass: '$classes.listbox',
    emptyMessageClass: '$classes.emptyMessage',
    emptyMessageInnerClass: '$classes.emptyMessageInner',
    listitemClass: '$classes.listitem',
    listitemInnerClass: '$classes.listitemInner',
    loadMoreClass: '$classes.loadMore',
    loadMoreInnerClass: '$classes.loadMoreInner',
    slots: '$slots',
  },
  attrs: {
    // id: '$id',
    // disabled: '$disabled',
    // class: '$classes.input',
  },
}))
