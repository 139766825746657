<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import ActionButton from '../ActionButton.vue'
import type { LeaseDocumentInputContext } from '../../types'

interface Props {
  context: LeaseDocumentInputContext
  forceTooltip?: string
  title?: string
}
const props = withDefaults(defineProps<Props>(), {
  forceTooltip: undefined,
  title: undefined,
})

const isApproved = computed(() => props.context.isApproved)
const confidence = computed(() => props.context.confidence)
const disabled = computed(() => props.context.disabled)
const tooltip = computed(() => {
  if (props.forceTooltip) return props.forceTooltip

  if (confidence.value && isApproved.value) {
    return `Confidence: ${Math.floor(confidence.value * 100)}%`
  }

  return isApproved.value ? `Value edited` : 'Approve this value'
})

const toggle = () => {
  if (disabled.value) return

  const { lock, unlock } = props.context.node
  if (isApproved.value) {
    unlock()
  } else {
    lock()
  }
  useAnalytics({
    name: 'Lease Review Change',
    data: {
      source: 'Approve Field Button Clicked',
      value: isApproved.value,
      'Confidence Score': confidence.value,
      'Is Edited': props.context.isEdited,
      'Field ID': props.context.id,
      'Field Value': props.context._value.value || props.context.value,
    },
  })
}

const buttonClass = computed(() => {
  const classes = [
    ' h-7 w-7 z-10 font-medium text-xs border border-transparent',
  ]
  classes.push(
    isApproved.value
      ? 'bg-primary text-black'
      : 'border-secondary-750 bg-secondary-800 text-secondary-100 hover:text-secondary-100 hover:bg-secondary-750 hover:border-secondary',
  )
  return twMerge(classes)
})
</script>

<template>
  <ActionButton
    :tooltip="tooltip"
    icon="check"
    :disabled="disabled"
    :button-class="buttonClass.toString()"
    :title="title"
    :sticky="true"
    @click="toggle"
  />
</template>
