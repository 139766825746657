<script lang="ts" setup>
import type { IconName } from '#build/icons'
import type { NuxtError } from '#app'
import type { ButtonVariantProps } from '@ui/components/Button/composables/useButtonClasses'

const props = defineProps<{
  error?: NuxtError
  status?: number
  name?: string
  message?: string
  icon?: IconName
  clearButton?: {
    label: string
  } & Partial<ButtonVariantProps>
}>()

const emit = defineEmits<{ clear: [event: Event] }>()

const { isDev } = useEnv()

const status = computed(() => props.status ?? props.error?.statusCode)
const name = computed(() => props.name ?? props.error?.statusMessage)
const icon = computed(() => {
  if (props.icon) return props.icon

  if (isObject(props.error?.data) && 'icon' in props.error.data) {
    return props.error.data.icon as IconName
  }

  return undefined
})
</script>

<template>
  <div class="flex min-h-0 w-full flex-col items-center justify-center py-8">
    <p v-if="status" class="text-primary text-4xl font-semibold">
      {{ status }}
    </p>

    <Icon v-if="icon" :name="icon" class="mt-6 text-8xl text-gray-200" />

    <h1 v-if="name" class="mt-4 text-2xl font-bold">
      {{ name }}
    </h1>
    <p v-if="message" class="mt-4 max-w-lg text-center text-base/7">
      {{ message }}
    </p>
    <div
      v-if="clearButton"
      class="mt-10 flex items-center justify-center gap-x-6"
    >
      <Button
        v-bind="{ ...clearButton, label: undefined }"
        @click="emit('clear', $event)"
      >
        {{ clearButton.label }}
      </Button>
    </div>

    <div v-if="isDev && error?.data" class="mt-8">
      <div class="rounded-lg bg-gray-700/50 p-6">
        <h2>DEV Logger:</h2>
        <pre>{{ error.data }}</pre>
      </div>
    </div>
  </div>
</template>
