import { SizeUnit } from '@register'

export enum ISizeUnit {
  SQUARE_METRES = 'sqm',
  SQUARE_FEET = 'sqf',
  SQUARE_YARDS = 'yards',
  PING = 'ping',
}

export const SizeUnitSuffix: Record<SizeUnit, ISizeUnit> = {
  [SizeUnit.SQUARE_METRES]: ISizeUnit.SQUARE_METRES,
  [SizeUnit.SQUARE_FEET]: ISizeUnit.SQUARE_FEET,
  [SizeUnit.SQUARE_YARDS]: ISizeUnit.SQUARE_YARDS,
  [SizeUnit.PING]: ISizeUnit.PING,
}
