<script setup lang="ts">
import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import type { VueDatePickerProps } from './types'
import formkitTheme from '@ui/formkit/formkit.theme'
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'
import type { ComponentInstance } from 'vue'
import { promiseTimeout } from '@vueuse/core'

const props = withDefaults(defineProps<VueDatePickerProps>(), {
  dark: true,
  autoApply: true,
  partialFlow: true,
  inline: true,
  enableTimePicker: false,
  monthChangeOnScroll: false,
  inputClass: formkitTheme['family:text'].input,
  format: 'DD/MM/YYYY',
  placeholder: 'Select a date',
  clearable: true,
  readonly: false,
  slots: () => ({}),
})
const emit = defineEmits(['update:modelValue', 'flow-step'])
const modelValue = useVModel(props, 'modelValue', emit)
const internalModelValue = ref<Date | Date[] | null>(null)

const dayjs = useDayjs()

const displayValue = computed(() => {
  if (!internalModelValue.value) return

  if (typeof props.format === 'function') {
    // @ts-expect-error
    return props.format(internalModelValue.value)
  }

  if (Array.isArray(internalModelValue.value)) {
    return internalModelValue.value
      .map((date) => dayjs(date).format(props.format as string))
      .join(' - ')
  }

  return dayjs(internalModelValue.value).format(props.format)
})

const dropdownButton = ref<ComponentInstance<typeof ListboxButton> | null>(null)
const dropdownWrapper = ref<ComponentInstance<typeof ListboxOptions> | null>(
  null,
)
const { floatingStyles } = useFloating(dropdownButton, dropdownWrapper, {
  middleware: [offset(8), flip({ fallbackAxisSideDirection: 'end' }), shift()],
  whileElementsMounted: autoUpdate,
})

const onSelectDate = useThrottleFn(async (force = false) => {
  if (!force && !props.autoApply) return

  await promiseTimeout(150)
  dropdownButton.value?.$el.click()
}, 100)

const onClear = () => {
  modelValue.value = undefined
  internalModelValue.value = null
}
</script>

<template>
  <Listbox as="div" class="group w-full">
    <ListboxButton ref="dropdownButton" as="div" class="w-full">
      <input
        type="text"
        :class="[inputClass, 'w-full cursor-[inherit] pl-8']"
        readonly
        :value="displayValue"
        :placeholder="placeholder"
      />
      <span
        class="focus-visible:border-primary absolute left-0 top-1/2 flex h-6 w-8 -translate-y-1/2 justify-center self-start rounded-md py-2.5 focus:outline-none focus-visible:border focus-visible:ring-2 focus-visible:ring-[#545A3B]"
      >
        <Icon name="calendar" filled class="self-center" />
      </span>
      <span
        v-if="clearable && internalModelValue && !disabled && !readonly"
        role="button"
        class="focus-visible:border-primary absolute right-0 top-1/2 hidden h-6 w-8 -translate-y-1/2 justify-center self-start rounded-md py-2.5 focus:outline-none focus-visible:border focus-visible:ring-2 focus-visible:ring-[#545A3B] group-hover:flex"
        @click.stop="onClear"
      >
        <Icon name="close" filled class="self-center" />
      </span>
    </ListboxButton>

    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <ListboxOptions
        ref="dropdownWrapper"
        as="div"
        class="absolute z-50 shadow-md shadow-gray-950/65"
        :style="floatingStyles"
        :unmount="false"
      >
        <VueDatePicker
          v-bind="props"
          v-model="modelValue"
          :format="
            typeof format === 'string'
              ? format.replaceAll('D', 'd').replaceAll('Y', 'y')
              : format
          "
          :model-type="
            typeof modelType === 'string'
              ? modelType.replaceAll('D', 'd').replaceAll('Y', 'y')
              : undefined
          "
          class="justify-center"
          @internal-model-change="internalModelValue = $event"
          @update:model-value="onSelectDate(true)"
          @date-update="onSelectDate()"
          @range-end="onSelectDate()"
        >
        </VueDatePicker>
      </ListboxOptions>
    </Transition>
  </Listbox>
</template>

<style lang="postcss" scoped>
:deep(.dp__menu) {
  @apply overflow-hidden rounded-md border-gray-500 bg-gray-800;
}
:deep(.dp__calendar_header_separator) {
  @apply bg-gray-500;
}
:deep(.dp__input) {
  @apply border-none bg-transparent py-0 pl-0 pr-6 outline-none ring-0;
}
:deep(.dp__icon) {
  @apply p-0;
}
:deep(.dp__input_wrap) {
  div > svg {
    @apply hidden;
  }
}
</style>
