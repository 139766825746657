import type { FieldConfigFactory } from '../../types'
import { stringFactory } from '../string'
import { tableFactory } from '../tableFactory'
import RentIncreaseTable from '../../inputs/RentIncreaseTable'
import OptionExerciseDetails from '../../inputs/OptionExerciseDetails'
import OptionRentIncreaseTable from '../../inputs/OptionRentIncreaseTable'

export const FactoryMap = {
  DEFAULT: stringFactory,
  RentIncreaseTable: (node) => tableFactory(node, RentIncreaseTable),
  MarketingLevyReviewTable: (node) => tableFactory(node, RentIncreaseTable),
  OptionExerciseDetails: (node) => tableFactory(node, OptionExerciseDetails),
  OptionRentIncreaseTable: (node) =>
    tableFactory(node, OptionRentIncreaseTable),
} satisfies Record<string, FieldConfigFactory>

export type FactoryMapKey = keyof typeof FactoryMap

export const arrayFactory: FieldConfigFactory = (node) => {
  const typeDefinition = node.context.field.typeDefinition
  // const arrayItemType = node.context.field.arrayItemType
  let typeDefinitionName: FactoryMapKey = (typeDefinition?.name ??
    'DEFAULT') as FactoryMapKey
  if (!(typeDefinitionName in FactoryMap)) {
    typeDefinitionName = 'DEFAULT'
  }
  const factory = FactoryMap[typeDefinitionName]
  return factory(node)
}
