import type { Ref } from 'vue'
import type { LeaseDocumentInputContext } from '@ui/formkit/inputs/leaseDocumentInput/types'
import type { RentReviewTableValue } from '@register/components/Review/Form/RentReviewTable'

export const useDelete = (context: Ref<LeaseDocumentInputContext>) => {
  const confirm = useConfirm()

  return (rentIncrease: RentReviewTableValue) => {
    confirm.require({
      title: 'Delete rent increase',
      body: 'Are you sure you want to delete this rent increase?',
      submitButton: 'Delete',
      confirm: () => {
        const index = context.value._value.value.indexOf(rentIncrease!)
        const value = context.value._value.value.toSpliced(index, 1)

        context.value.node.input({
          ...context.value._value,
          value,
        })
      },
    })
  }
}
