import type { FieldConfigFactory } from '../../types'
// import { parseStringToNumber } from '~/utils/parseStringToNumber'
// import { extractFirstNumber } from '~/utils/extractNumber'
import { isValidValue } from '../../utils'
import Textarea from '../../inputs/Textarea.vue'

export const numberFactory: FieldConfigFactory = (node) => {
  return {
    component: markRaw(Textarea),
    type: 'number',
    toFormatted: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return String(value)
    },
    toAPI: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return String(value)
    },
    toInput: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return value
    },
    fromDocument: (
      value: string | null | undefined,
    ): string | undefined | null => {
      // const type =
      //   node.context.field.dataType === LeaseFieldDataType.INTEGER
      //     ? 'integer'
      //     : 'float'
      // const firstNumber = extractFirstNumber(value)
      // const _value = parseStringToNumber(firstNumber, type)
      // return firstNumber

      return value
    },
    fromDocumentError: 'Unable to locate a number in the selected document',
  }
}
