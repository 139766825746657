import { LeaseVariationType } from '@register'
import { exhaustiveMatchingGuard } from '~/utils/exhaustiveMatchingGuard'

export function localizeVariationType(type: LeaseVariationType) {
  switch (type) {
    case LeaseVariationType.EXTENSION:
      return 'Lease Extension'
    case LeaseVariationType.SURRENDER:
      return 'Lease Surrendered'
    case LeaseVariationType.TRANSFER:
      return 'Lease Transfer'
    case LeaseVariationType.OPTION_EXERCISE:
      return 'Option Exercised'
    case LeaseVariationType.VARIATION:
      return 'Variation'
    default:
      return exhaustiveMatchingGuard(type)
  }
}
