import { type FormKitNode, reset as _reset } from '@formkit/core'
import type { LeaseDocumentValue } from '../types'

/**
 * A feature that set up the field context.
 *
 * @param node - A {@link @formkit/core#FormKitNode | FormKitNode}.
 *
 * @public
 */
export function reset(node: FormKitNode): void {
  node.on('created', () => {
    node.context.fns.reset = () => {
      node.context.preventNextRequest = true

      //reset context
      node.context.fns.setupContext()

      // reset value
      const fieldValue = node.context.field.value
      const _value: LeaseDocumentValue = {
        value: node.context.fieldConfig.toAPI(fieldValue?.value),
        bounds: fieldValue?.bounds
          ? {
              bounds: fieldValue.bounds.bounds ?? [],
              documentId: fieldValue.bounds.documentId,
            }
          : null,
        isApproved: fieldValue?.isApproved ?? false,
      }
      node.props.initial = node.props._init = _value
      _reset(node, _value)
    }
  })
}
