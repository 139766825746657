import type { UploadResult, UppyFile, Meta } from '@uppy/core'
import type Uppy from '@uppy/core'
import type { ICompanies } from '~/composables/useAPI/types'

export enum IQueueType {
  uploading = 'uploading',
  finished = 'finished',
  error = 'error',
}

export type IFileUploadObject = {
  file: UppyFile<Meta, Record<string, unknown>>
  progress: number
  error?: Error
  response?: UppyFile<Meta, Record<string, unknown>>['response']
  result?: UploadResult<Meta, Record<string, unknown>>
}

export type FileFullObject = {
  uppy: Uppy
  uploading: IFileUploadObject[]
  finished: IFileUploadObject[]
  error: IFileUploadObject[]
  company: ICompanies
}
export type IFileUpload = Record<string, FileFullObject>

export type IFilesData = {
  files: IFileUploadObject[]
  company: ICompanies
}

export type IFilesResult = {
  [K in IQueueType]: IFilesData[]
}
