import type { FormKitNode, FormKitExtendableSchemaRoot } from '@formkit/core'

const isCheckboxAndRadioMultiple = (node: FormKitNode) =>
  (node.props.type === 'checkbox' || node.props.type === 'radio') &&
  node.props.options

export function addAsterisk(node: FormKitNode): void {
  node.on('created', () => {
    const field = node.context.field
    const isRequired = field.isRequired
    if (!isRequired) return

    const isMultiOption = isCheckboxAndRadioMultiple(node)

    // if we're going to modify the schema then we need
    // to update the schemaMemoKey so we don't get an
    // invalid cached schema.
    if (!node.props.definition) return

    node.props.definition.schemaMemoKey = `required_${
      isMultiOption ? 'multi_' : ''
    }${node.props.definition.schemaMemoKey}`

    const schemaFn = node.props.definition.schema as FormKitExtendableSchemaRoot
    node.props.definition.schema = (sectionsSchema = {}) => {
      if (isRequired) {
        if (isMultiOption) {
          sectionsSchema.legend = {
            children: ['$label || $field.name', '*'],
          }
        } else {
          sectionsSchema.label = {
            children: ['$label || $field.name', '*'],
          }
        }
      }
      return schemaFn(sectionsSchema)
    }
  })
}
