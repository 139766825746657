import type { FormKitNode } from '@formkit/core'
import type { LeaseDocumentValue } from '../types'

declare module '@formkit/core' {
  interface FormKitNodeExtensions {
    //TODO: check with João the type error
    props: {
      attrs: {
        onUndo: (node: FormKitNode) => void
        onRedo: (node: FormKitNode) => void
        onAddNote: (node: FormKitNode) => void
        onSelect: (node: FormKitNode) => void
        onChange: (node: FormKitNode, value: LeaseDocumentValue) => void
        onDelete: (node: FormKitNode) => void
        backlinkingOnly: (node: FormKitNode) => void
      }
    }
  }

  interface FormKitFrameworkContext {
    //TODO: check with João the type error
    handlers: {
      copyFromDocument: () => void
      undo: (node: FormKitNode) => void
      redo: (node: FormKitNode) => void
      addNote: (node: FormKitNode) => void
      select: (node: FormKitNode) => void
      change: (node: FormKitNode, value: LeaseDocumentValue) => void
      delete: (node: FormKitNode) => void
      backlinkingOnly: (node: FormKitNode) => void
    }
  }
}

export function emitters(node: FormKitNode) {
  node.on('created', () => {
    node.context.handlers.copyFromDocument =
      node.props.attrs.onCopyFromDocument || (() => {})

    node.context.handlers.backlinkingOnly =
      node.props.attrs.onBacklinkingOnly || (() => {})

    node.context.handlers.undo = node.props.attrs.onUndo || (() => {})

    node.context.handlers.redo = node.props.attrs.onRedo || (() => {})

    node.context.handlers.addNote = node.props.attrs.onAddNote || (() => {})

    node.context.handlers.select = node.props.attrs.onSelect || (() => {})

    node.context.handlers.change = node.props.attrs.onChange || (() => {})

    node.context.handlers.delete = node.props.attrs.onDelete || (() => {})
  })
}
