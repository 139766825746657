<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import { twMerge } from 'tailwind-merge'

interface Props {
  title?: string
  closeOnClick?: boolean
  containerClass?: string
  bodyClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Modal',
  closeOnClick: true,
  containerClass: undefined,
  bodyClass: undefined,
})

const emit = defineEmits(['close'])

// Container class
const defaultContainerClass =
  'mx-auto mb-12 mt-12 flex max-w-3xl flex-col rounded-lg border border-gray-700 bg-gray-800'
const containerClass = computed(() => {
  return twMerge(defaultContainerClass, props.containerClass)
})
// Body class
const defaultBodyClass = 'p-6'
const bodyClass = computed(() => {
  return twMerge(defaultBodyClass, props.bodyClass)
})

function close(): void {
  if (props.closeOnClick) {
    emit('close')
  }
}
</script>

<template>
  <Scrollbar
    class="absolute left-0 top-0 max-h-screen min-h-screen w-full justify-center"
  >
    <div v-on-click-outside="close" :class="containerClass">
      <h1
        class="shaddow standalone:top-safe-top standalone:pt-safe-top sticky top-0 z-50 w-full overflow-hidden rounded-t-lg bg-gray-900/60 px-6 py-3 text-base backdrop-blur-2xl backdrop-saturate-[180%]"
      >
        {{ title }}

        <Button
          color="secondary"
          class="group absolute right-4 top-2/4 float-right h-6 w-6 -translate-y-1/2 px-0 py-0"
          icon="close"
          @click="emit('close')"
        >
        </Button>
      </h1>
      <div :class="bodyClass">
        <slot />
      </div>
    </div>
  </Scrollbar>
</template>

<style lang="postcss" scoped>
:deep(.simplebar-content-wrapper) {
  @apply min-h-screen;
}
</style>
