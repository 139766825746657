import { createSection } from '@formkit/inputs'

export const actionsWrapper = createSection('actionsWrapper', () => {
  return {
    $el: 'div',
    attrs: {
      'data-side-actions': '$sideActions === true',
    },
  }
})
