import type { Ref } from 'vue'
import type { ListboxInnerValue, ListboxOptionsItem } from '../index'
import type { UseOptionsReturn } from './useOptions'

interface UseDisplayValueProps {
  multiple: Ref<boolean>
  placeholder: Ref<string>
  searchable: Ref<boolean>
}

interface UseDisplayValueParsers {
  getLabel: UseOptionsReturn['getLabel']
}

type UseDisplayValueReturn = (value: ListboxInnerValue) => string | string[]

export const useDisplayValue = (
  props: UseDisplayValueProps,
  parsers: UseDisplayValueParsers,
): UseDisplayValueReturn => {
  const { multiple, placeholder, searchable } = props
  const { getLabel } = parsers

  return (value: ListboxInnerValue) => {
    if (multiple.value) {
      if ((value as ListboxOptionsItem[]).length === 0 && !searchable.value)
        return placeholder.value

      return (value as ListboxOptionsItem[]).map((option) => getLabel(option))
    }

    if (value === undefined && !searchable.value) return placeholder.value

    return getLabel(value as ListboxOptionsItem) ?? ''
  }
}
