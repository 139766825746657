<script setup lang="ts">
import ActionButton from '../ActionButton.vue'
import type { LeaseDocumentInputContext } from '../../types'

interface Props {
  context: LeaseDocumentInputContext
}
const props = defineProps<Props>()

const { toggleAddressMap } = useCurrentLeaseDocument()
const disabled = computed(() => props.context.disabled)

const toggle = () => {
  if (disabled.value) return

  toggleAddressMap(props.context.id)
  // useAnalytics({
  //   name: 'Lease Review Change',
  //   data: {
  //     source: 'Toggle Address Clicked',
  //     value: true,
  //     'Confidence Score': confidence.value,
  //     'Is Edited': props.context.isEdited,
  //     'Field ID': props.context.id,
  //     'Field Value': props.context._value.value || props.context.value,
  //   },
  // })
}
</script>

<template>
  <ActionButton
    tooltip="See address on map"
    icon="pin-checkmark-circle"
    :disabled="disabled"
    class="z-[501]"
    button-class="hover:text-primary hover:bg-primary/5 hover:border-primary"
    @click="toggle"
  />
</template>
