import type { MaybeRef } from 'vue'
import { joinURL } from 'ufo'
import { storeToRefs } from 'pinia'
import { useCompaniesStore } from '~/stores/companies'
import { getDivisionDateFormat } from '~/utils/getDivisionDateFormat'
import { SizeUnitSuffix } from '~/constants/SizeUnit'

export function useDivisions() {
  const store = useCompaniesStore(useNuxtApp().$pinia)
  const route = useRoute('division-companyId')

  const { companies, companiesById } = storeToRefs(store)

  const currentDivision = computed(() => {
    return companiesById.value[route.params.companyId as string]
  })
  const dateFormat = computed(() =>
    getDivisionDateFormat(currentDivision.value),
  )

  // get company by id
  function getDivisionById(id: string) {
    return companiesById.value[id]
  }

  function createDivisionURL(
    path?: MaybeRef<string>,
    companyId = getCurrentDivisionId(false),
  ) {
    if (!companyId) return `/`
    const _path = unref(path)
    if (!_path) return `/division/${companyId}`
    return joinURL('/division', companyId, _path)
  }
  const unitSize = computed(() => {
    if (currentDivision.value?.localization?.sizeUnit)
      return {
        value: currentDivision.value.localization.sizeUnit,
        text: SizeUnitSuffix[currentDivision.value.localization.sizeUnit],
      }
  })

  return {
    currentDivision,
    dateFormat,
    companies,
    unitSize,
    getDivisionById,
    fetchCompanies: store.fetchCompanies,
    createDivisionURL,
  }
}

export function getCurrentDivisionId<T extends boolean = true>(
  shouldThrow?: T,
): T extends false ? string | undefined : string
export function getCurrentDivisionId(shouldThrow = true) {
  const route = useRoute('division-companyId-lease-leaseDocumentId')
  const divisionId = route.params.companyId as string | undefined

  if (shouldThrow && !divisionId) {
    return
    // throw new Error('Division not available')
  }

  return divisionId
}
