import { useScroll } from '@vueuse/core'

// This is a virtual scrollbar, that appears when the table height is bigger than the window height
export const useSyncHorizontalScrollbar = (
  wrapperEl: Ref<HTMLElement | null>,
  virtualScrollEl: Ref<HTMLElement | null>,
  hasScroller: Ref<boolean>,
) => {
  let isSyncingWrapperScroll = false
  let isSyncingVirtualScroll = false

  const syncScroll = (x: number, div: Ref<HTMLElement | null>) => {
    if (div.value) div.value.scrollLeft = x
  }

  //If the user scrolls the table, the virtual scrollbar should follow
  const { x: wrapperX } = useScroll(wrapperEl)
  watch(wrapperX, () => {
    if (!hasScroller.value) return
    if (!isSyncingWrapperScroll) {
      isSyncingVirtualScroll = true
      syncScroll(wrapperX.value, virtualScrollEl)
    }
    isSyncingWrapperScroll = false
  })

  //If the user scrolls the virtual scrollbar, the table should follow
  const { x: virtualTableScrollX } = useScroll(virtualScrollEl)
  watch(virtualTableScrollX, () => {
    if (!hasScroller.value) return
    if (!isSyncingVirtualScroll) {
      isSyncingWrapperScroll = true
      syncScroll(virtualTableScrollX.value, wrapperEl)
    }
    isSyncingVirtualScroll = false
  })
}
