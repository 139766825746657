import { storeToRefs } from 'pinia'
import { getNode } from '@formkit/core'
import {
  type ILeaseField,
  type ILeaseDocumentFieldSection,
  type ILeaseDocumentFieldGroup,
  useFieldSelectionStore,
} from '@register'
import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'
import { useLeaseReviewWebViewer } from './index'

export const useFieldSelection = () => {
  const store = useFieldSelectionStore()
  const { selectedField, snippetRowPosition, sectionContainerDiv } =
    storeToRefs(store)
  const { mainDocument, resetDocument, changeDocument } =
    useLeaseReviewWebViewer()
  const { add: addAnnotations, remove: removeAnnotations } = useAnnotations()

  const isSelectedField = (field: ILeaseField) =>
    selectedField.value?.id === field.id

  const select = (field: ILeaseField, force = false) => {
    if (isSelectedField(field) && force === false) return

    selectedField.value = field

    const fieldValue = getNode(field.id)?.value as LeaseDocumentValue
    if (fieldValue?.bounds) {
      addAnnotations(fieldValue.bounds.documentId, fieldValue.bounds.bounds)
      changeDocument(fieldValue.bounds.documentId)
    } else {
      removeAnnotations()
      resetDocument()
    }
  }

  const unselect = () => {
    selectedField.value = undefined
    removeAnnotations()
  }

  const isSelectedFieldWithBounds = (field: ILeaseField): boolean => {
    const value = getNode(field.id)?.value as LeaseDocumentValue
    return (
      isSelectedField(field) &&
      !!snippetRowPosition.value &&
      !!value?.bounds &&
      value.bounds.bounds.length > 0
    )
  }

  const isSelectedSection = (section: ILeaseDocumentFieldSection) =>
    !!selectedField.value?.id &&
    !!section.fields?.find((field) => field.id === selectedField.value?.id) &&
    !!snippetRowPosition.value

  const isSelectedGroup = (group: ILeaseDocumentFieldGroup) =>
    !!selectedField.value?.id &&
    !!group.sections
      ?.flatMap((section) => section.fields ?? [])
      ?.find((field) => field.id === selectedField.value?.id) &&
    !!snippetRowPosition.value

  return {
    selectedField,
    snippetRowPosition,
    fieldSelectionState: store.fieldSelectionState,
    sectionContainerDiv,
    select,
    unselect,
    addAnnotations: addAnnotations,
    removeAnnotations: removeAnnotations,
    isSelectedFieldWithBounds,
    isSelectedField,
    isSelectedSection,
    isSelectedGroup,
  }
}
