import type { ICompanies, IUserCompanies } from '~/composables/useAPI/types'
import type { Ref } from 'vue'

type CompaniesReturn = {
  companies: Ref<ICompanies[]>
  companiesById: Ref<Record<string, ICompanies>>
  fetchCompanies: () => void
  loadingStatus: Ref<boolean>
}

export const useCompaniesStore = defineStore(
  'companies',
  (): CompaniesReturn => {
    const companies = ref<ICompanies[]>([])
    const companiesById = ref<Record<string, ICompanies>>({})
    const loadingStatus = ref(true)

    // fetch companies list
    const fetchCompanies = () => {
      loadingStatus.value = true
      fetchAPI<IUserCompanies>('/me/companies').then((response) => {
        companies.value = response.companies ?? []
        loadingStatus.value = false

        for (const company of companies.value) {
          companiesById.value[company.id] = company
        }

        return response
      })
    }

    return {
      companies,
      companiesById,
      fetchCompanies,
      loadingStatus,
    }
  },
)
