import type { Component, AsyncComponentLoader } from 'vue'
// @ts-expect-error virtual file
import components from '#build/components.dynamic.mjs'
import type { ComponentName, ComponentType } from '#build/components.dynamic'

export interface GetAsyncComponentOptions<T extends ComponentName> {
  component: T
  loadingComponent?: ComponentName | Component
  errorComponent?: ComponentName | Component
  delay?: number
  timeout?: number
  suspensible?: boolean
  onError?: (
    error: Error,
    retry: () => void,
    fail: () => void,
    attempts: number,
  ) => any
}

export const getAsyncComponent = <T extends ComponentName>(
  source: T | string | GetAsyncComponentOptions<T>,
): ComponentType<T> => {
  if (typeof source === 'string' || !source) {
    source = { component: source } as GetAsyncComponentOptions<T>
  }

  const {
    component,
    loadingComponent,
    errorComponent,
    delay,
    timeout,
    suspensible,
    onError,
  } = source

  const componentImport: AsyncComponentLoader =
    components[component] ??
    // Creates a fake loader that throws an error if the component doesn't exist
    (() => Promise.reject(new Error(`Component not found: ${component}`)))

  return defineAsyncComponent({
    loader: componentImport,
    loadingComponent:
      typeof loadingComponent === 'string'
        ? getAsyncComponent(loadingComponent)
        : loadingComponent,
    errorComponent:
      typeof errorComponent === 'string'
        ? getAsyncComponent(errorComponent)
        : errorComponent,
    delay,
    timeout,
    suspensible,
    onError,
  })
}
