import { Loader } from '@googlemaps/js-api-loader'
import type { LoaderOptions } from '@googlemaps/js-api-loader'
import type { Ref } from 'vue'

const loader = ref<Loader>() as Ref<Loader>

export const useGoogleMapsLoader = () => {
  const initialize = (options: LoaderOptions) => {
    if (loader.value) {
      return
    }

    loader.value = markRaw(new Loader(options))
  }

  return {
    loader,
    initialize,
  }
}
