import Vue3Toasity, { type ToastContainerOptions, toast } from 'vue3-toastify'

export default defineNuxtPlugin({
  name: 'toastify',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.use(Vue3Toasity, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 5,
      theme: 'dark',
      transition: 'slide',
      dangerouslyHTMLString: true,
    } as ToastContainerOptions)

    return {
      provide: { toast },
    }
  },
})
