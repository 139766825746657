import type { Ref } from 'vue'
import type {
  ListboxOptionsItem,
  ListboxFormat,
  ListboxInnerValue,
} from '../index'

interface UseSyncValuesProps {
  innerValue: Ref<ListboxInnerValue>
  modelValue: Ref<any>
  format: Ref<ListboxFormat>
  multiple: Ref<boolean>
  options: Ref<ListboxOptionsItem[]>
}

interface UseSyncValuesTransformers {
  toValue: (option: ListboxInnerValue) => any
  toOption: (value: any) => ListboxInnerValue
}

export const useSyncValues = (
  props: UseSyncValuesProps,
  transformers: UseSyncValuesTransformers,
) => {
  const { innerValue, modelValue, multiple, format, options } = props
  const { toValue, toOption } = transformers

  // Sync `innerValue` and `modelValue`
  // The `innerValue` is always a `ListboxOptionsItem` or an array of `ListboxOptionsItem`
  // The `modelValue` is transformed based on the `format` prop
  syncRef(innerValue, modelValue, {
    transform: {
      ltr: (option) => toValue(option),
      rtl: (value) => toOption(value),
    },
  })

  // Watch for `multiple` changes, and update `innerValue` accordingly
  // If `multiple` is `true`, `innerValue` should be an array
  // If `multiple` is `false`, `innerValue` should be a single value
  watch(multiple, (multiple) => {
    if (multiple === true) {
      innerValue.value = [innerValue.value as ListboxOptionsItem]
    } else if (multiple === false) {
      innerValue.value = (innerValue.value as ListboxOptionsItem[])[0]
    }
  })

  // Watch for `format` changes, and update `modelValue` accordingly
  // The `innerValue` should not be transformed by the `format` prop
  // If `format` is `option`, `modelValue` should be the option itself
  // If `format` is `value`, `modelValue` should be the option's track value
  watch(format, (format) => {
    if (format === 'option') {
      modelValue.value = toOption(modelValue.value)
    } else if (format === 'value') {
      modelValue.value = toValue(modelValue.value)
    }
  })

  watch(options, () => {
    innerValue.value = toOption(modelValue.value)
  })
}
