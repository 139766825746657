import { localizeFromEnum } from '~/utils/localizeFromEnum'
import { localizeVariationKindWithField } from '@register'

export enum LeaseVariationKindWithField {
  ADD_VARIATION = 'ADDED_FIELD',
  CHANGE_VARIATION = 'CHANGED_FIELD',
  ADD_CLAUSE = 'ADDED_CLAUSE',
  CHANGE_CLAUSE = 'CHANGED_CLAUSE',
  REMOVE_CLAUSE = 'REMOVED_CLAUSE',
  ADD_DEFINITION = 'ADDED_DEFINITION',
  CHANGE_DEFINITION = 'CHANGED_DEFINITION',
  REMOVE_DEFINITION = 'REMOVED_DEFINITION',
  // TODO: need to add the changed_field?
}

export const leaseVariationKindWithFieldOptions = localizeFromEnum(
  LeaseVariationKindWithField,
  localizeVariationKindWithField,
).filter(({ value }) => !value.includes('CHANGED'))
export const leaseSpecialConditionKindWithFieldOptions =
  leaseVariationKindWithFieldOptions.filter(
    ({ value }) =>
      ![LeaseVariationKindWithField.ADD_VARIATION].includes(
        value as LeaseVariationKindWithField,
      ),
  )
