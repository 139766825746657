<script setup lang="ts">
import ActionButton from '../ActionButton.vue'
import type { LeaseDocumentInputContext } from '../../types'

const props = defineProps<{ context: LeaseDocumentInputContext }>()

const disabled = computed(() => props.context.disabled)
const onClick = async () => {
  // Emit
  props.context.handlers.addNote(props.context.node)
  useAnalytics({
    name: 'Lease Field Toolbar Actions',
    data: {
      source: 'Note',
      'Is Edited': props.context.isEdited,
      'Field ID': props.context.id,
      'Field Value': props.context._value.value || props.context.value,
    },
  })
}

const nodesCount = computed(() => {
  let sentence: string

  if (!props.context.field.notesCount || props.context.field.notesCount === 0) {
    sentence = 'Add a note to this field'
  } else if (props.context.field.notesCount === 1) {
    sentence = 'There is 1 note'
  } else {
    sentence = `There are ${props.context.field.notesCount} notes`
  }

  return {
    sentence,
    count: props.context.field.notesCount,
  }
})
</script>

<template>
  <ActionButton
    :tooltip="nodesCount.sentence"
    icon="document-edit"
    :badge="nodesCount.count"
    :disabled="disabled"
    @click="onClick"
  />
</template>
