import type { ILeaseRegisterLocalization } from '@register'

export const FALLBACK_DIVISION_DATE_FORMAT = 'D/M/YYYY'

export const getDivisionDateFormat = (
  division?: { localization?: ILeaseRegisterLocalization },
  fallback: string = FALLBACK_DIVISION_DATE_FORMAT,
) => {
  return division?.localization?.dateFormat ?? fallback
}
