import type { SortingState, Updater } from '@tanstack/vue-table'
import type { TanstackTableEmits, TanstackTablePropsWithDefaults } from '..'

export const useSort = (
  props: TanstackTablePropsWithDefaults,
  emit: TanstackTableEmits,
) => {
  const sort = useVModel(props, 'sort', emit, { passive: true })
  const setSort = (updaterOrValue: Updater<SortingState>) => {
    sort.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(sort.value)
        : updaterOrValue
  }

  return { sort, setSort }
}
