import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { toValue } from '@vueuse/core'
import type { MaybeRefOrGetter } from '@vueuse/core'
import { useGoogleMapsImportLibrary } from './import-library'

export type Markers<T = any> = Omit<
  google.maps.marker.AdvancedMarkerElementOptions,
  'map'
> & { data?: T }

type InfoWindowArgs<T = any> = {
  marker: google.maps.marker.AdvancedMarkerElementOptions
  data?: T
}

export interface UseMarkersOptions<T = any> {
  markerClusterer?: boolean
  infoWindow?: (
    args: InfoWindowArgs<T>,
  ) => google.maps.InfoWindowOptions | false
}

export const useGetLatitudeLongitude = (
  address: Ref<string | undefined | null>,
) => {
  const GeocodingLibrary = useGoogleMapsImportLibrary('geocoding')
  const results = ref<google.maps.GeocoderResult[]>([])
  watch(
    () => [GeocodingLibrary.value, address.value] as const,
    async ([_GeocodingLibrary, _address]) => {
      if (!_GeocodingLibrary || !_address) return

      const geocoder = new _GeocodingLibrary.Geocoder()
      const geoLoc = await geocoder.geocode({ address: _address })
      if (geoLoc.results) {
        results.value = geoLoc.results
      }
    },
    {
      flush: 'post',
    },
  )
  return results
}

export const useGoogleMapsMarkers = <T = any>(
  map: MaybeRefOrGetter<google.maps.Map | undefined>,
  markers: MaybeRefOrGetter<Markers<T>[]>,
  options: MaybeRefOrGetter<UseMarkersOptions<T>> = {},
) => {
  const MarkerLibrary = useGoogleMapsImportLibrary('marker')
  const MapsLibrary = useGoogleMapsImportLibrary('maps')
  const markerElements = ref<google.maps.marker.AdvancedMarkerElement[]>([])
  const markerClusterer = ref<MarkerClusterer>()

  type Renderer = {
    count: number
    position: google.maps.LatLng
  }

  const renderer = {
    render: ({ count, position }: Renderer) =>
      new google.maps.Marker({
        label: {
          text: String(count),
          color: 'white',
          fontSize: '10px',
          fontWeight: 'bold',
        },
        position,
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      }),
  }

  watch(
    () =>
      [
        MarkerLibrary.value,
        MapsLibrary.value,
        toValue(map),
        toValue(markers),
        toValue(options),
      ] as const,
    ([MarkerLibrary, MapsLibrary, map, markers, options]) => {
      if (!MarkerLibrary || !MapsLibrary || !map || markers.length === 0) {
        return
      }

      // Clear markers
      for (const marker of markerElements.value) {
        marker.map = null
      }

      // Add markers
      markerElements.value = markers.map((markerOptions) => {
        const marker = new MarkerLibrary.AdvancedMarkerElement({
          map: options.markerClusterer ? null : map,
          collisionBehavior: markerOptions.collisionBehavior,
          content: markerOptions.content,
          gmpDraggable: markerOptions.gmpDraggable,
          position: markerOptions.position,
          title: markerOptions.title,
          zIndex: markerOptions.zIndex,
        })

        const infoWindowOptions = options.infoWindow?.({
          marker,
          data: markerOptions.data,
        })

        if (infoWindowOptions) {
          const infoWindow = new MapsLibrary.InfoWindow(infoWindowOptions)
          marker.addListener('click', () => {
            infoWindow.open({
              anchor: marker,
              map,
            })
          })
        }

        return marker
      })

      if (options.markerClusterer) {
        if (markerClusterer.value) {
          // Update marker clusterer
          markerClusterer.value.clearMarkers()
          markerClusterer.value.setMap(map)
          markerClusterer.value.addMarkers(markerElements.value)
        } else {
          // Create marker clusterer
          markerClusterer.value = markRaw(
            new MarkerClusterer({
              map,
              markers: markerElements.value,
              renderer,
            }),
          )
        }
      } else if (markerClusterer.value) {
        // Clear marker clusterer
        markerClusterer.value.clearMarkers()
        markerClusterer.value.setMap(null)
        markerClusterer.value = undefined
      }

      // automatic zoom to fit markers
      const bounds = new google.maps.LatLngBounds()
      for (const marker of markers) {
        if (marker.position) bounds.extend(marker.position)
      }
      map?.fitBounds(bounds)
    },
    { flush: 'post' },
  )

  return {
    markers: markerElements,
    markerClusterer,
  }
}
