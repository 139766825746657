import revive_payload_client_cY336vDOG1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@3.29.4_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LnM7tCldrK from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@3.29.4_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QNYWj7a5fK from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@3.29.4_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_QEBQukY27V from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@3.29.4_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xU4xnOqIGI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@3.29.4_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_sB14Mjunz5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@3.29.4_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_wBOZAwt81m from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@3.29.4_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_7oUq8hWYD6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@9.10.0_rollup@3.29.4_sass@1.79.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import plugin_3mDLlUpvJA from "/opt/build/repo/node_modules/.pnpm/nuxt-highcharts@3.1.1/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_client_5Njh6xX1zH from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_EXcG0guNqk from "/opt/build/repo/node_modules/.pnpm/nuxt-supported-browsers@0.0.5_rollup@3.29.4/node_modules/nuxt-supported-browsers/dist/runtime/plugin.mjs";
import plugin_mLpStGXI1Y from "/opt/build/repo/node_modules/.pnpm/mixpanel-nuxt@0.0.13_rollup@3.29.4/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import plugin_jAruPhL8Ie from "/opt/build/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11_rollup@3.29.4/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_jeNVwGjCew from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.2.3_@types+node@20.16.5_@vue+test-utils@2.4.6_h3@1.12.0_nitropack@2.9.7_nuxi_uqqxsonsqgavvxgrdav72o4yyu/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_kb5NVW0Q8L from "/opt/build/repo/layers/shared/modules/sentry/runtime/plugin.ts";
import formkit_autoanimate_86IYiikJGT from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.5_@vueuse+integrations@11.1.0_esbuild@_lescfymuh3bu7etuwrs5el4n2u/node_modules/@flnt-accurait/web-components/plugins/formkit-autoanimate.ts";
import tailwind_merge_ub6jPISkk0 from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.5_@vueuse+integrations@11.1.0_esbuild@_lescfymuh3bu7etuwrs5el4n2u/node_modules/@flnt-accurait/web-components/plugins/tailwind-merge.ts";
import vfm_Lod8YdoHHL from "/opt/build/repo/node_modules/.pnpm/@flnt-accurait+web-components@0.1.22_@types+node@20.16.5_@vueuse+integrations@11.1.0_esbuild@_lescfymuh3bu7etuwrs5el4n2u/node_modules/@flnt-accurait/web-components/plugins/vfm.ts";
import api_GFfDXud5Cr from "/opt/build/repo/plugins/api.ts";
import auth_vT9JWWT9pN from "/opt/build/repo/plugins/auth.ts";
import casl_QTBkbB6FCs from "/opt/build/repo/plugins/casl.ts";
import companies_gN452sK9TF from "/opt/build/repo/plugins/companies.ts";
import google_maps_sKT4qijSb0 from "/opt/build/repo/plugins/google-maps.ts";
import newrelic_browser_CmZ534RHg2 from "/opt/build/repo/plugins/newrelic-browser.ts";
import toastify_ScRgZgP9op from "/opt/build/repo/plugins/toastify.ts";
import vfm_AT9bKzcFx9 from "/opt/build/repo/plugins/vfm.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_cY336vDOG1,
  unhead_LnM7tCldrK,
  router_QNYWj7a5fK,
  payload_client_QEBQukY27V,
  navigation_repaint_client_xU4xnOqIGI,
  chunk_reload_client_sB14Mjunz5,
  plugin_vue3_wBOZAwt81m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7oUq8hWYD6,
  formkitPlugin_pZqjah0RUG,
  floating_vue_EIcJ7xiw0h,
  plugin_3mDLlUpvJA,
  plugin_client_5Njh6xX1zH,
  plugin_EXcG0guNqk,
  plugin_mLpStGXI1Y,
  plugin_jAruPhL8Ie,
  plugin_jeNVwGjCew,
  plugin_kb5NVW0Q8L,
  formkit_autoanimate_86IYiikJGT,
  tailwind_merge_ub6jPISkk0,
  vfm_Lod8YdoHHL,
  api_GFfDXud5Cr,
  auth_vT9JWWT9pN,
  casl_QTBkbB6FCs,
  companies_gN452sK9TF,
  google_maps_sKT4qijSb0,
  newrelic_browser_CmZ534RHg2,
  toastify_ScRgZgP9op,
  vfm_AT9bKzcFx9,
  vue_query_wrmMkNpEpe
]