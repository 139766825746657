import type { Ref } from 'vue'
import { useGoogleMapsImportLibrary } from './import-library'

export const useGoogleMaps = (
  element: Ref<HTMLElement | null | undefined>,
  options?: google.maps.MapOptions,
) => {
  const MapsLibrary = useGoogleMapsImportLibrary('maps')
  const map = ref<google.maps.Map>()

  watch(
    [MapsLibrary, element],
    ([MapLibrary, element]) => {
      if (!MapLibrary || !element) {
        return
      }
      map.value = markRaw(new MapLibrary.Map(element, options))
    },
    { flush: 'post' },
  )

  return { map }
}
