<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import {
  type ButtonVariantProps,
  useButtonClasses,
} from './composables/useButtonClasses'
import type { RouteLocationRaw } from 'vue-router'
import type { IconName } from '#build/icons'

// turn off inheritAttrs as we want to merge local tailwind classes with the class attribute manually
defineOptions({
  inheritAttrs: false,
})

interface Props {
  size?: ButtonVariantProps['size']
  color?: ButtonVariantProps['color']
  shape?: ButtonVariantProps['shape']
  ring?: ButtonVariantProps['ring']
  ringOnHover?: ButtonVariantProps['ringOnHover']
  loading?: boolean
  icon?: IconName
  to?: string | RouteLocationRaw
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  color: 'primary',
  shape: undefined,
  ring: false,
  ringOnHover: false,
  loading: false,
  icon: undefined,
  to: undefined,
  disabled: false,
})

const { attrClass, attrRest } = useReactiveAttr()
const disabled = computed(() => props.disabled || props.loading)

const classes = computed(() =>
  twMerge(
    useButtonClasses({
      color: props.color,
      ring: props.ring,
      size: props.size,
      shape: props.shape,
      ringOnHover: props.ringOnHover,
    }),
    attrClass.value,
  ),
)
</script>

<template>
  <button
    v-if="!props.to"
    type="button"
    :class="[...classes.split(' ')]"
    v-bind="attrRest"
    :disabled="disabled"
  >
    <Spinner v-if="loading" size="xs" class="flex self-center" />
    <Icon
      v-else-if="props.icon"
      :name="props.icon"
      filled
      :class="{
        'text-lg': props.size === 'lg',
        'text-md': props.size === 'md',
        'text-sm': props.size === 'sm',
      }"
    />
    <slot />
  </button>
  <NuxtLink
    v-else-if="props.to"
    :to="props.to"
    :class="[...classes.split(' ')]"
    role="link"
    v-bind="attrRest"
  >
    <Spinner v-if="loading" size="xs" class="flex self-center" />
    <Icon
      v-else-if="props.icon"
      :name="props.icon"
      filled
      :class="{
        'text-lg': props.size === 'lg',
        'text-md': props.size === 'md',
        'text-sm': props.size === 'sm',
      }"
    />
    <slot />
  </NuxtLink>
</template>
