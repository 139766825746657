import type { ICreateAddressRequest } from '@register'
import type { Address as _GoogleAddress } from '~/types/googleAddress'
import type { FieldConfigFactory } from '../../types'
import { isValidValue } from '../../utils'
import Address from '../../inputs/Address.vue'

type GoogleAddress = _GoogleAddress & {
  description: string
  place_id: string
}

export const addressFactory: FieldConfigFactory = (node) => {
  node.context.isAddress = true

  return {
    component: markRaw(Address),
    type: 'address',
    toFormatted: (
      value: ICreateAddressRequest | string | null | undefined,
    ): string | undefined => {
      if (!isValidValue(value)) return
      if (typeof value === 'string') return value
      return String(value.googleAddress ?? value.address)
    },
    toAPI: (
      value: ICreateAddressRequest | GoogleAddress | string | null | undefined,
    ): ICreateAddressRequest | string | undefined => {
      if (!isValidValue(value)) return
      // Original value may be type of string
      if (typeof value === 'string') return value
      // Parse GoogleAddress input to API format
      if (isGoogleAddress(value)) return parseGoogleAddress(value)
      return value
    },
    toInput: (
      value: ICreateAddressRequest | string | null | undefined,
    ): Partial<GoogleAddress> | string | undefined => {
      if (!isValidValue(value)) return
      if (typeof value === 'string') return value
      return {
        ...value,
        description: value.googleAddress ?? value.address,
      } as GoogleAddress
    },
    fromDocument: (value: string): string | undefined => {
      if (!value) return
      return value
    },
  }

  function isGoogleAddress(value: any): value is GoogleAddress {
    return 'description' in value && 'place_id' in value
  }

  function parseGoogleAddress(value: GoogleAddress): ICreateAddressRequest {
    return {
      address: value.address,
      city: value.city,
      state: value.state,
      countryId: value.countryId,
      postcode: value.postcode,
      longitude: value.longitude,
      latitude: value.latitude,
      googleAddress: value.description,
    }
  }
}
