// If NodeKind is imported from @manager, vitest fails
import { NodeKind } from '../NodeKind'
import { FieldConfig } from './Field'
import { GroupConfig } from './Group'
import { ObjectConfig } from './Object'
import { TableConfig } from './Table'
import { FieldCollectionConfig } from './FieldCollection'
import type { INodeDynamicComponentConfigs } from './types'

export const NodeDynamicComponentConfig: INodeDynamicComponentConfigs = {
  [NodeKind.FIELD]: FieldConfig,
  [NodeKind.GROUP]: GroupConfig,
  [NodeKind.OBJECT]: ObjectConfig,
  [NodeKind.TABLE]: TableConfig,
  [NodeKind.FIELD_COLLECTION]: FieldCollectionConfig,
}
