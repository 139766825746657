import type { ComputedRef, Ref } from 'vue'
import { storeToRefs } from 'pinia'
import {
  type ILeaseDocumentField,
  type ILeaseDocumentFieldGroup,
  type ILeaseDocumentFieldSection,
  type ILeaseRegisterLease,
  type ILeaseDocumentClausesAndDefinitionsGroup,
  type ILeaseDocumentSpecialConditionsByKind,
  type ILeaseSpecialCondition,
  useLeaseStore,
} from '@register'

export interface UseCurrentLeaseDocumentReturn {
  // TODO: Remove `Required` when API return correct type
  // It's safe to use `Required` here because the properties that are optional are nullable
  lease: Ref<Required<ILeaseRegisterLease> | undefined>
  documentName: ComputedRef<string | undefined>
  dateFormat: ComputedRef<string>
  groups: ComputedRef<ILeaseDocumentFieldGroup[]>
  sections: ComputedRef<ILeaseDocumentFieldSection[]>
  fields: ComputedRef<ILeaseDocumentField[]>
  availableClausesAndDefinitions: ComputedRef<ILeaseDocumentClausesAndDefinitionsGroup>
  clausesAndDefinitions: ComputedRef<ILeaseDocumentClausesAndDefinitionsGroup>
  specialConditions: ComputedRef<ILeaseDocumentSpecialConditionsByKind>
  specialConditionFields: ComputedRef<ILeaseSpecialCondition[]>
  getSection: (sectionId: string) => ILeaseDocumentFieldSection | undefined
  getField: (id: string) => ILeaseDocumentField | undefined
  getGroupByFieldId: (id: string) => ILeaseDocumentFieldGroup | undefined
  getGroupBySectionId: (
    sectionId: string,
  ) => ILeaseDocumentFieldGroup | undefined
  getSectionByFieldId: (id: string) => ILeaseDocumentFieldSection | undefined
  toggleAddressMap: (id: string) => any
}

// Use this composable only after fetching the lease.
export const useCurrentLeaseDocument = (): UseCurrentLeaseDocumentReturn => {
  const {
    lease,
    documentName,
    dateFormat,
    groups,
    sections,
    fields,
    availableClausesAndDefinitions,
    clausesAndDefinitions,
    specialConditions,
    specialConditionFields,
  } = storeToRefs(useLeaseStore())

  const store = useLeaseStore()

  const getSection = (sectionId: string) => {
    return sections.value.find((section) => section.id === sectionId)
  }

  const getField = (id: string) => {
    return fields.value.find((field) => field.id === id)
  }

  const getGroupByFieldId = (id: string) => {
    return groups.value.find((group) =>
      group.sections
        ?.flatMap((section) => section.fields)
        .some((field) => field?.id === id),
    )
  }

  const getGroupBySectionId = (sectionId: string) => {
    return groups.value.find(
      (group) =>
        group.sections?.some((section) => section?.id === sectionId) ?? false,
    )
  }

  const getSectionByFieldId = (fieldId: string) => {
    return sections.value.find((section) =>
      section.fields?.some((field) => field.id === fieldId),
    )
  }

  return {
    lease,
    documentName,
    dateFormat,
    groups,
    sections,
    fields,
    availableClausesAndDefinitions,
    clausesAndDefinitions,
    specialConditions,
    specialConditionFields,
    getSection,
    getField,
    getGroupByFieldId,
    getGroupBySectionId,
    getSectionByFieldId,
    toggleAddressMap: store.toggleAddressMap,
  } as UseCurrentLeaseDocumentReturn
}
