import { createSection } from '@formkit/inputs'
import { markRaw } from 'vue'
import TaglistList from '../components/TaglistList.vue'

export const list = createSection('list', () => ({
  if: '$_value.length > 0',
  $cmp: markRaw(TaglistList) as unknown as string,
  bind: '$attrs',
  props: {
    options: '$options',
    labelBy: '$labelBy',
    trackBy: '$trackBy',
    format: '$format',
    'onUpdate:modelValue': '$node.input',
    modelValue: '$_value',
    // Classes
    taglistClass: '$classes.taglist',
    taggeditemClass: '$classes.taggeditem',
    taggeditemInnerClass: '$classes.taggeditemInner',
    taggeditemRemoveClass: '$classes.taggeditemRemove',
    slots: '$slots',
  },
  attrs: {
    // id: '$id',
    // disabled: '$disabled',
    // class: '$classes.input',
  },
}))
