// If LeaseFieldType is imported from @register, vitest fails
import { LeaseFieldType } from '~/data-services/gen'
import { variationFactory } from './variation'
import { clauseFactory } from './clause'
import { definitionFactory } from './definition'
import type { VariationFieldConfig, VariationFieldConfigFactory } from './types'

export const VariationFieldConfigMap = {
  [LeaseFieldType.FIELD]: variationFactory,
  [LeaseFieldType.CLAUSE]: clauseFactory,
  [LeaseFieldType.DEFINITION]: definitionFactory,
} satisfies Record<LeaseFieldType, VariationFieldConfigFactory>

export function variationFieldConfigFactory(
  fieldType: LeaseFieldType,
  context: any,
): VariationFieldConfig {
  const fieldConfig = VariationFieldConfigMap[fieldType]

  if (!fieldConfig) {
    throw new Error(`Invalid variation field type: ${fieldType}`)
  }

  return fieldConfig(context)
}
