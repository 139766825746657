import * as Sentry from '@sentry/browser'

const INTEGRATIONS: Record<string, any> = {
  browserApiErrors: Sentry.browserApiErrorsIntegration,
  browserTracing: Sentry.browserTracingIntegration,
  replay: Sentry.replayIntegration,
  browserProfiling: Sentry.browserProfilingIntegration,
}

export default defineNuxtPlugin((nuxtApp) => {
  const { sentry: options } = useRuntimeConfig().public

  Sentry.init({
    ...options,
    integrations: options.integrations
      ?.map(([name, config]) => {
        const integration = INTEGRATIONS[name]
        if (!integration) {
          console.warn(`[nuxt:sentry]: Unknown integration: ${name}`)
          return
        }
        return integration(config)
      })
      .filter(Boolean),
  })
})
