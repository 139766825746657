import type { FetchContext, IFetchError } from 'ofetch'

export interface IApiErrorData {
  type: string
  title: string
  status: number
  detail?: string
  resourceType?: string
  resourceId?: string
  traceId?: string
  errors?: Record<string, string[]>
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface IApiError extends IFetchError<IApiErrorData> {
  //
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class ApiError extends Error {
  constructor(message: string, opts?: { cause: unknown }) {
    super(message, opts)

    this.name = 'ApiError'

    // Polyfill cause for other runtimes
    if (opts?.cause && !this.cause) {
      this.cause = opts.cause
    }
  }
}

// Augment `ApiError` type to include `IApiError` properties
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging, @typescript-eslint/no-empty-object-type
export interface ApiError extends IApiError {}

export const createApiError = (
  ctx: FetchContext<IApiErrorData> & {
    genericError?: string
    errors?: Record<number, string>
  },
): IApiError => {
  // https://github.com/unjs/ofetch/blob/main/src/error.ts#L21
  const errorMessage = ctx.error?.message || ctx.error?.toString() || ''

  const method =
    (ctx.request as Request)?.method || ctx.options?.method || 'GET'
  const url = (ctx.request as Request)?.url || String(ctx.request) || '/'
  const requestStr = `[${method}] ${JSON.stringify(url)}`

  const statusStr = ctx.response
    ? `${ctx.response.status} ${ctx.response.statusText}`
    : '<no response>'

  const message = `${requestStr}: ${statusStr}${
    errorMessage ? ` ${errorMessage}` : ''
  }`

  const apiError = new ApiError(
    message,
    ctx.error ? { cause: ctx.error } : undefined,
  )

  for (const key of ['request', 'options', 'response'] as const) {
    Object.defineProperty(apiError, key, {
      get() {
        return ctx[key]
      },
    })
  }

  for (const [key, refKey] of [
    ['data', '_data'],
    ['status', 'status'],
    ['statusCode', 'status'],
  ] as const) {
    Object.defineProperty(apiError, key, {
      get() {
        return ctx.response && ctx.response[refKey]
      },
    })
  }

  // Create custom statusMessage
  const genericError = ctx.genericError || 'Something went wrong'
  const errors: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    ...(ctx.errors || {}),
  }

  let statusMessage: string | undefined
  if (ctx.response) {
    if (ctx.response._data) {
      statusMessage = ctx.response._data.title

      if (ctx.response._data.resourceType) {
        statusMessage = `${ctx.response._data.resourceType} ${statusMessage}`
      }
    } else if (errors[ctx.response.status]) {
      statusMessage = errors[ctx.response.status]
    }
  }
  statusMessage = statusMessage || genericError

  for (const key of ['statusMessage', 'statusText'] as const) {
    Object.defineProperty(apiError, key, {
      value: statusMessage,
    })
  }

  return apiError
}
