import { createVfm } from 'vue-final-modal'

export default defineNuxtPlugin({
  name: 'vue-final-modal',
  parallel: true,
  setup(nuxtApp) {
    const vfm = createVfm() as any
    nuxtApp.vueApp.use(vfm)
  },
})
