import type { FormKitEvent, FormKitNode } from '@formkit/core'
import { approved, maxLength, required } from '../rules'
import type { FormKitValidationIntent } from '@formkit/validation'

const VALIDATION_RULES = {
  initial: ['maxLength'],
  draft: ['maxLength'],
  approve: ['maxLength'],
  publish: ['maxLength', 'approved'],
}
type ValidationIntent = keyof typeof VALIDATION_RULES

/**
 * A feature that register the field validation.
 */
export function setupValidation(node: FormKitNode): void {
  node.on('created', () => {
    node.context.hasValidation = !!node.props.validation
    node.context.hasPublished = false
    node.props.validationRules = {
      maxLength: maxLength.rule,
      required: required.rule,
      approved: approved.rule,
      ...(node.props.validationRules ?? {}),
    }
    node.props.validationMessages = {
      maxLength: maxLength.message,
      required: required.message,
      approved: approved.message,
      ...(node.props.validationMessages ?? {}),
    }

    // Register validation functions
    registerFns(node)

    // Rules that should always be validated
    node.context.fns.setInitialValidation()

    // Rules that should be added when the input is in a specific state
    const messageCallback = ({ payload: message }: FormKitEvent) => {
      switch (message.key) {
        case 'drafted': {
          node.context.fns.setDraftValidation()
          break
        }
        case 'approved': {
          node.context.fns.setApproveValidation()
          break
        }
        case 'published': {
          node.context.fns.setPublishValidation()
          node.context.hasPublished = true
          break
        }
      }
    }
    node.on('message-added', messageCallback)
    node.on('message-updated', messageCallback)
    node.on('message-removed', ({ payload: message }: FormKitEvent) => {
      if (
        message.key === 'drafted' ||
        message.key === 'approved' ||
        message.key === 'published'
      ) {
        node.context.fns.setInitialValidation()
      }
    })
  })
}

function registerFns(node: FormKitNode) {
  node.context.fns.setInitialValidation = () => setValidation(node, 'initial')
  node.context.fns.setApproveValidation = () => setValidation(node, 'approve')
  node.context.fns.setDraftValidation = () => setValidation(node, 'draft')
  node.context.fns.setPublishValidation = () => setValidation(node, 'publish')
}

function setValidation(node: FormKitNode, validationIntent: ValidationIntent) {
  if (node.context.hasValidation) return

  const validation: FormKitValidationIntent[] = []
  const field = node.context.field
  const intentRules = VALIDATION_RULES[validationIntent]

  if (hasRule('required') && field.isRequired) {
    validation.push([ruleHint('required')])
  }

  if (hasRule('maxLength') && field.maxLength) {
    validation.push(['maxLength', field.maxLength])
  }

  if (hasRule('approved') && field.isRequired) {
    validation.push([ruleHint('approved')])
  }

  node.props.validation = validation

  node.props.validationVisibility = node.context.hasPublished ? 'live' : 'blur'

  function hasRule(rule: string) {
    return node.context.hasPublished || intentRules.includes(rule)
  }

  function ruleHint(rule: string) {
    return node.context.hasPublished && validationIntent !== 'publish'
      ? `?${rule}`
      : rule
  }
}
