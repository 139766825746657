import { createSection } from '@formkit/inputs'
import { markRaw } from 'vue'
import NumberIncrement from '@ui/components/Form/NumberIncrement/NumberIncrement.vue'

/**
 * Input section used by selects
 *
 * @public
 */
export const numberIncrementInput = createSection('input', () => ({
  $cmp: markRaw(NumberIncrement) as unknown as string,
  bind: '$attrs',
  props: {
    'onUpdate:modelValue': '$node.input',
    modelValue: '$_value',
    min: '$mim',
    max: '$max',
  },
}))
