import type { Ref } from 'vue'
import type {
  LeaseDocumentInputContext,
  LeaseDocumentValue,
} from '@ui/formkit/inputs/leaseDocumentInput/types'
import { useModal } from 'vue-final-modal'
import OptionExerciseDetailsForm from '@register/components/Review/Form/OptionExerciseDetails/Form.vue'
import type { IOptionExerciseDetailsValue } from '@register/components/Review/Form/OptionExerciseDetails/types'

export const useFormModal = (context: Ref<LeaseDocumentInputContext>) => {
  return (optionExerciseDetail: IOptionExerciseDetailsValue) => {
    const updateValue = (data: IOptionExerciseDetailsValue) => {
      const contextValue: LeaseDocumentValue<
        IOptionExerciseDetailsValue[] | string
      > = context.value._value

      // The original value can be a string, so we need to convert it to an empty array
      // to prevent destructing the string.
      // If the original value is an array, we clone it to prevent modifying its reference. But we don't destruct its
      // objects in order to have the same reference and be able to find their indexes.
      const value: IOptionExerciseDetailsValue[] = Array.isArray(
        contextValue.value,
      )
        ? [...(contextValue.value ?? [])]
        : []

      if (optionExerciseDetail) {
        // EDIT
        // Find index of the rent increase to update.
        const index = value.indexOf(optionExerciseDetail)
        value[index] = data
      } else {
        // CREATE
        value.push(data)
      }

      // Update input value.
      context.value.node.input({
        ...contextValue,
        value,
      })
    }

    const { open, close } = useModal({
      component: OptionExerciseDetailsForm,
      attrs: {
        optionExerciseDetail,
        onConfirm(data: IOptionExerciseDetailsValue) {
          updateValue(data)
          close()
        },
        onClose() {
          close()
        },
      },
    })

    open()
  }
}
