import { storeToRefs } from 'pinia'
import { useWebViewerStore } from '@register'

export const useLeaseReviewWebViewer = () => {
  const {
    pdfReady,
    webViewerInstance,
    selectedCopiedText,
    mainDocumentId,
    currentDocumentId,
  } = storeToRefs(useWebViewerStore())

  const isDocument = (id: string) => {
    return currentDocumentId.value === id
  }

  const changeDocument = (id: string) => {
    if (isDocument(id)) return
    currentDocumentId.value = id
    pdfReady.value = false
  }

  const resetDocument = () => {
    changeDocument(mainDocumentId.value!)
  }

  return {
    pdfReady,
    webViewerInstance,
    selectedCopiedText,
    currentDocumentId,
    changeDocument,
    resetDocument,
    isDocument,
    mainDocument: mainDocumentId,
  }
}
