import { createSection } from '@formkit/inputs'

export const label = createSection('label', () => ({
  $el: 'label',
  if: '$label',
  children: '$label',
  attrs: {
    for: {
      if: '$isApproved !== true',
      then: '$id',
    },
  },
}))
