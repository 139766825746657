import type { FormKitNode } from '@formkit/core'
import type { LeaseDocumentHistoryValue, LeaseDocumentValue } from '../types'

/**
 * A feature that set up the field history.
 *
 * @param node - A {@link @formkit/core#FormKitNode | FormKitNode}.
 *
 * @public
 */
export function setupHistory(node: FormKitNode): void {
  node.on('created', () => {
    // Register hooks
    node.hook.history((payload, next) => {
      return next(createHistoryValue(payload as LeaseDocumentValue))
    })
    node.hook.beforeSavingToHistory((payload, next) => {
      if (
        JSON.stringify(payload) ===
        JSON.stringify(createHistoryValue(node._value as LeaseDocumentValue))
      ) {
        return next(false)
      }

      return next(payload)
    })

    // Init history
    node.on('history:created', () => {
      const field = node.context.field
      const fieldValue = node.context.fieldConfig.toAPI(field.value?.value)
      const fieldOriginalValue = node.context.fieldConfig.toAPI(
        field.value?.originalValue,
      )
      if (
        fieldOriginalValue !== undefined &&
        fieldValue !== fieldOriginalValue
      ) {
        const originalValue: LeaseDocumentHistoryValue = {
          value: fieldOriginalValue,
          bounds: field.value?.originalBounds ?? [],
        }

        node.context.history.history = [
          originalValue,
          createHistoryValue(node.context._value),
        ]
        node.context.history.currentIndex =
          node.context.history.history.length - 1
      }
    })
  })
}

function createHistoryValue(
  value: LeaseDocumentValue,
): LeaseDocumentHistoryValue {
  return {
    value: typeof value.value === 'string' ? value.value.trim() : value.value,
    bounds: value.bounds,
  }
}
