<script setup lang="ts">
import Listbox from '@ui/components/Form/Listbox'
import type { LeaseDocumentInputContext } from '../types'

const props = defineProps<{
  context: LeaseDocumentInputContext
  modelValue: unknown
}>()

const emit = defineEmits(['update:modelValue'])
const innerValue = useVModel(props, 'modelValue', emit)
</script>

<template>
  <Listbox
    v-model="innerValue"
    :options="context.fieldConfig.options"
    class="!p-0"
  />
</template>
