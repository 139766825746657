export enum AuthStatus {
  Loading = 'loading',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

export enum AuthRedirectCondition {
  Unauthenticated = 'unauthenticated',
  Authenticated = 'authenticated',
  Always = 'always',
}
