import { createMessage, type FormKitNode } from '@formkit/core'
import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'

declare module '@formkit/core' {
  interface FormKitNodeExtensions {
    lock: () => void
    unlock: () => void
  }
}

export function lock(node: FormKitNode) {
  node.on('created', () => {
    node.extend('lock', {
      get: () => () => {
        node.store.set(
          createMessage({
            key: 'approved',
            value: true,
            visible: false,
          }),
        )
        node.store.set(
          createMessage({
            key: 'submitted',
            value: true,
            visible: false,
          }),
        )
        if (!node.context.state.valid) return
        node.input({
          ...(node._value as LeaseDocumentValue),
          isApproved: true,
        })
      },
      set: false,
    })

    node.extend('unlock', {
      get: () => () => {
        node.input({
          ...(node._value as LeaseDocumentValue),
          isApproved: false,
        })
      },
      set: false,
    })
  })
}
