import type { FieldConfigFactory } from '../../types'
import { isValidValue } from '../../utils'
import Textarea from '../../inputs/Textarea.vue'
import Datepicker from '../../inputs/Datepicker.vue'

export const dateFactory: FieldConfigFactory = (node) => {
  const dayjs = useDayjs()

  return {
    component: markRaw(Textarea),
    panelComponent: markRaw(Datepicker),
    type: 'datepicker',
    toFormatted: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      if (isValidDate(value)) return dayjs(value).format(getFormattingDate())
      return String(value)
    },
    toAPI: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      if (isValidDate(value)) return dayjs(value).format('YYYY-MM-DD')
      return String(value)
    },
    toInput: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      if (isValidDate(value)) return dayjs(value).format(getFormattingDate())
      return String(value)
    },
    toPanel: (value: any): Date | undefined => {
      if (!isValidValue(value) || !isValidDate(value)) return
      return dayjs(value).toDate()
    },
    fromDocument: (value: any): string | undefined => {
      //TODO: from document should accept any text without formating and validating
      // const _value = extractDate(value)
      // if (!isValidValue(value)) return
      // const date = dayjs(_value)
      // const isValidDate =
      // return date ?  date.format('YYYY-MM-DD') :
      if (!isValidValue(value)) return
      if (isValidDate(value)) return dayjs(value).format('YYYY-MM-DD')
      return String(value)
    },
    fromDocumentError: 'Unable to locate a date in the selected document',
  }

  function isValidDate(value: unknown) {
    if (value instanceof Date) {
      // If input is a Date instance, check if it's a valid date
      return !isNaN(value.getTime())
    }

    if (typeof value === 'string') {
      // If input is a string, attempt to parse it as a date
      const parsedDate = new Date(value)

      // Check if the parsed date is a valid date and not "Invalid Date"
      if (isNaN(parsedDate.getTime())) {
        return false
      }

      // Check if it's a valid ISO date string
      const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/
      if (isoDateRegex.test(value)) {
        return true
      }

      // Check if it's a valid "yyyy-mm-dd" date string
      const yyyyMmDdRegex = /^\d{4}-\d{2}-\d{2}$/
      return yyyyMmDdRegex.test(value)
    }

    return value
  }

  function getFormattingDate(): string {
    return getLeaseDateFormat({ settings: node.context.leaseSettings })
  }
}
