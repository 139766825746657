<script setup lang="ts">
import { ref, watchEffect } from '#imports'
// @ts-expect-error virtual file
import icons from '#build/icons.mjs'
import type { IconName } from '#build/icons'

type Props = {
  name: IconName | string
  filled?: boolean
  fallback?: IconName | string
}

const props = withDefaults(defineProps<Props>(), {
  filled: true,
  fallback: undefined,
})

const icon = ref<string | Record<string, any>>('')
let hasStroke = false
const hasError = ref<boolean>(false)

async function getIcon() {
  try {
    let iconKey = props.name

    if ((!iconKey || !(iconKey in icons)) && props.fallback) {
      iconKey = props.fallback
    }

    const rawIcon = await icons[iconKey]()

    if (rawIcon.includes('stroke')) {
      hasStroke = true
    }
    icon.value = rawIcon
    hasError.value = false
  } catch (error) {
    hasError.value = true
    console.warn(`[nuxt:icons] Icon '${props.name}' doesn't exist`)
  }
}

await getIcon()

watchEffect(getIcon)
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    v-if="!hasError"
    class="nuxt-icon"
    :class="{
      'nuxt-icon--fill': !filled,
      'nuxt-icon--stroke': hasStroke && !filled,
    }"
    v-html="icon"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<style>
.nuxt-icon svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
.nuxt-icon.nuxt-icon--fill,
.nuxt-icon.nuxt-icon--fill * {
  fill: currentColor !important;
}

.nuxt-icon.nuxt-icon--stroke,
.nuxt-icon.nuxt-icon--stroke * {
  stroke: currentColor !important;
}
</style>
