<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { computed } from 'vue'
import type { IconName } from '#build/icons'

interface Props {
  icon: IconName
  disabled?: boolean
  tooltip?: string
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
  buttonClass?: string
  iconSize?: string | number
  title?: string
  sticky?: boolean
  badge?: number | null
}

const props = withDefaults(defineProps<Props>(), {
  icon: 'edit',
  disabled: false,
  tooltipPlacement: 'top',
  tooltip: undefined,
  buttonClass: undefined,
  iconSize: '20',
  title: undefined,
  sticky: false,
  badge: 0,
})

const buttonClasses = computed(() => {
  const classes = [
    'flex',
    'h-8',
    'w-8',
    'justify-center',
    'rounded-lg',
    'min-w-max',
    'bg-transparent',
    'text-white',
    'hover:bg-primary',
    'hover:text-white',
    'hover:text-black',
    'focus:bg-primary',
    'focus:bg-primary/90',
    'focus:ring',
    'focus:ring-primary/20',
    'focus:text-white',
    'focus:text-black',
    'gap-1',
    'items-center',
    'relative',
  ]
  if (props.disabled) {
    classes.push(
      'disabled',
      'cursor-not-allowed',
      'opacity-40',
      'hover:bg-transparent',
      'hover:text-black  dark:hover:text-white',
    )
  }

  if (props.buttonClass) {
    classes.push(props.buttonClass)
  }

  if (props.title) {
    classes.push('px-3')
  }

  return twMerge(classes)
})
const classes = computed(() => {
  const _classes = ['flex justify-center self-center z-[500]']
  if (props.sticky) {
    _classes.push('sticky top-2 z-[501] flex')
  }
  return twMerge(_classes)
})

defineEmits(['click'])
</script>

<template>
  <VTooltip
    :class="classes.toString()"
    :delay="10"
    placement="top"
    :distance="4"
    :auto-hide="false"
  >
    <span
      role="button"
      tabindex="0"
      :aria-label="tooltip"
      :aria-disabled="disabled"
      :disabled="disabled"
      :class="buttonClasses.toString()"
      @click="$emit('click')"
      @keyup.enter="$emit('click')"
    >
      <Icon
        filled
        :name="icon"
        class="items-center self-center text-sm"
        :size="iconSize.toString()"
      />
      <span
        v-if="title"
        class="flex h-full cursor-pointer items-center text-xs"
      >
        {{ title }}
      </span>
      <span
        v-if="badge"
        class="bg-error absolute right-1 top-1 h-2 w-2 rounded-full text-[10px] text-gray-500"
      >
      </span>
    </span>

    <template #popper>
      <div class="text-xs">{{ tooltip }}</div>
    </template>
  </VTooltip>
</template>
