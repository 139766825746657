import type { ILeaseInfo } from '@register'
import type { UseQueryReturnType } from '@tanstack/vue-query'
import { Product } from '~/data-services/gen'

/* This composable should only be used once, in the lease page */
const [useProvideCurrentLease, _useCurrentLease] = createInjectionState(
  (query: UseQueryReturnType<ILeaseInfo, Error>) => {
    const route = useRoute('division-companyId-lease-leaseDocumentId')
    const { $can } = useNuxtApp()
    const data = query.data as Ref<Required<ILeaseInfo>>
    const { mainDocumentId, currentDocumentId } =
      storeToRefs(useWebViewerStore())

    watchEffect(() => {
      if (data.value.documents) {
        mainDocumentId.value = data.value.documents[0].id
        currentDocumentId.value = data.value.documents[0].id
      }
    })

    const documents = computed(() => data.value.documents ?? [])
    const getDocumentById = (documentId: string) => {
      return documents.value.find((doc) => doc.id === documentId)
    }

    // The name of the document to be displayed in the header
    const documentName = computed(
      () => data.value.name ?? documents.value[0]?.name ?? undefined,
    )

    // Date format for this lease
    const dateFormat = computed(() => getLeaseDateFormat(data.value))

    const product = computed(() => {
      const hasRegister = data.value.products.includes(Product.LEASE_REGISTER)
      const hasManager = data.value.products.includes(Product.LEASE_MANAGER)
      const canAccessRegister =
        $can('product', Product.LEASE_REGISTER) && hasRegister
      const canAccessManager =
        $can('product', Product.LEASE_MANAGER) && hasManager

      // If the lease has the `manager` product, it means the lease has
      // already been published. So the "current" product is the manager.
      const current = hasManager
        ? Product.LEASE_MANAGER
        : Product.LEASE_REGISTER

      // We can specify which product to access by using the `product` query.
      // If the user has access to the product, we will use that product.
      let accessing: Product.LEASE_MANAGER | Product.LEASE_REGISTER

      if (route.query.product === Product.LEASE_MANAGER && canAccessManager) {
        accessing = Product.LEASE_MANAGER
      } else if (
        route.query.product === Product.LEASE_REGISTER &&
        canAccessRegister
      ) {
        accessing = Product.LEASE_REGISTER
      } else {
        accessing = canAccessManager
          ? Product.LEASE_MANAGER
          : Product.LEASE_REGISTER
      }

      return {
        current,
        accessing,
      }
    })

    return {
      ...query,
      data,
      documents,
      documentName,
      dateFormat,
      product,
      getDocumentById,
    }
  },
)

export { useProvideCurrentLease }

/* @returns Information needed no mount the lease page */
export function useCurrentLease() {
  const query = _useCurrentLease()
  if (!query)
    throw new Error('`useCurrentLease` can only be used in lease page.')
  return query
}
