/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LeaseVariationType {
    EXTENSION = 'extension',
    SURRENDER = 'surrender',
    TRANSFER = 'transfer',
    OPTION_EXERCISE = 'optionExercise',
    VARIATION = 'variation',
}
