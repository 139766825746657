import { createSection } from '@formkit/inputs'
import Section from '../inputs/Section.vue'

export const maps = createSection('maps', () => ({
  $cmp: markRaw(Section) as unknown as string,
  bind: '$attrs',
  props: {
    context: '$node.context',
  },
}))
