import type { FormKitNode } from '@formkit/core'
import type { ToRefs } from 'vue'
import type { IOptionExerciseDetailsForm } from '../types'

export const useForm = (
  props: ToRefs<{
    optionExerciseDetail: IOptionExerciseDetailsForm
    isManager: boolean
  }>,
) => {
  const { optionExerciseDetail, isManager } = props
  const instance = getCurrentInstance()
  const dayjs = useDayjs()
  const { dateFormat: divisionDateFormat } = useDivisions()

  // FORM REF
  const form = ref<{ node: FormKitNode }>()

  // SUBMIT HANDLER
  const onSubmit = (data: IOptionExerciseDetailsForm) => {
    const value = {
      ...optionExerciseDetail.value,
      exerciseStartDate: formatDate(data.exerciseStartDate),
      exerciseEndDate: formatDate(data.exerciseEndDate),
    }

    instance?.emit('confirm', value)
  }

  // INITIAL FORM VALUE
  const initialFormValue = (): Partial<IOptionExerciseDetailsForm> => {
    return {
      exerciseStartDate: parseDate(
        optionExerciseDetail.value.exerciseStartDate,
      ),
      exerciseEndDate: parseDate(optionExerciseDetail.value.exerciseEndDate),
    }
  }

  return {
    form,
    onSubmit,
    initialFormValue,
  }

  function formatDate<T extends string | undefined>(date: T): T {
    if (!date) return undefined as T

    return (
      isManager.value
        ? dayjs(date).toISOString()
        : dayjs(date).format(divisionDateFormat.value)
    ) as T
  }

  function parseDate<T extends string | undefined>(date: T): T {
    if (!date) return undefined as T

    return (
      isManager.value
        ? dayjs(date).toISOString()
        : dayjs(date, divisionDateFormat.value).toISOString()
    ) as T
  }
}
