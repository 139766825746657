// If LeaseFieldDataType is imported from @register, vitest fails
import { LeaseFieldDataType } from '~/data-services/gen'
import { stringFactory } from './string'
import { numberFactory } from './number'
import { booleanFactory } from './boolean'
import { dateFactory } from './date'
import { enumFactory } from './enum'
import { objectFactory } from './object'
import { arrayFactory } from './array'
import { exclusiveCheckboxFactory } from './exclusiveCheckBox'
import type { FieldConfig, FieldConfigFactory } from '../types'
import type { FormKitNode } from '@formkit/core'

export const FieldConfigMap = {
  [LeaseFieldDataType.STRING]: stringFactory,
  [LeaseFieldDataType.INTEGER]: numberFactory,
  [LeaseFieldDataType.DECIMAL]: numberFactory,
  [LeaseFieldDataType.PERCENTAGE]: numberFactory,
  [LeaseFieldDataType.BOOLEAN]: booleanFactory,
  // TODO: Add guid factory
  [LeaseFieldDataType.GUID]: stringFactory,
  [LeaseFieldDataType.DATE]: dateFactory,
  // TODO: Add currency factory
  [LeaseFieldDataType.CURRENCY]: stringFactory,
  [LeaseFieldDataType.OBJECT]: objectFactory,
  [LeaseFieldDataType.ARRAY]: arrayFactory,
  [LeaseFieldDataType.ENUM]: enumFactory,
} satisfies Record<LeaseFieldDataType, FieldConfigFactory>

export function fieldConfigFactory(node: FormKitNode): FieldConfig {
  let dataType = node.context.field.dataType
  if (isExclusiveCheckBox(node)) {
    //return the exclusive checkbox factory
    return exclusiveCheckboxFactory(node)
  } else if (isForcedEnum(node)) {
    dataType = LeaseFieldDataType.ENUM
  }

  return FieldConfigMap[dataType](node)
}

function isForcedEnum(node: FormKitNode) {
  const dataType = node.context.field.dataType
  return dataType === LeaseFieldDataType.STRING && !!node.context.field.enumInfo
}

function isExclusiveCheckBox(node: FormKitNode) {
  const dataType = node.context.field.dataType
  return (
    dataType === LeaseFieldDataType.ENUM &&
    node.context.field?.displayType === 'exclusiveCheckBox'
  )
}
