type Analytics = {
  showDivision?: boolean
  name: string
  data?: Record<string, any>
}

export const useAnalytics = (analytics: Analytics): void => {
  const mixpanel = useMixpanel()
  const companyId = getCurrentDivisionId(false)
  const { data: user } = useAuth()

  const data = analytics.data ?? {}

  if (analytics.showDivision && companyId) {
    const companyId = getCurrentDivisionId()
    data.Division = companyId
  }

  if (user.value?.email) {
    data['User Email'] = user.value.email
  }

  mixpanel.track(analytics.name, data)
}
