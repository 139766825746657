import type { Ref } from 'vue'
import type { WebViewerInstance } from '@pdftron/webviewer'
import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'

export const useWebViewerStore = defineStore('webViewer', () => {
  // Determines if the PDF finished loading
  const pdfReady = ref(false)
  // WebViewer instance
  const webViewerInstance = shallowRef<WebViewerInstance>()

  // The text that was copied from the PDF
  const selectedCopiedText = ref<LeaseDocumentValue>()
  // The ID of the main document (PDF).
  const mainDocumentId = ref<string>()
  // The ID of the current document (PDF).
  // We can safely assume that the lease has at least one document.
  const currentDocumentId = ref<string>()

  const reset = () => {
    pdfReady.value = false
    selectedCopiedText.value = undefined
    mainDocumentId.value = undefined
    currentDocumentId.value = undefined
  }

  return {
    pdfReady,
    webViewerInstance,
    selectedCopiedText,
    mainDocumentId,
    currentDocumentId,
    reset,
  }
})
