<script lang="ts" setup>
import { ConfidenceLevel } from '@register'
import type { LeaseDocumentInputContext } from '../../types'

interface Props {
  context: LeaseDocumentInputContext
}
const props = defineProps<Props>()

type ConfidenceScore = {
  contentClass: string
  iconClass: string
  title: string
}

const confidenceScoreClass = computed<ConfidenceScore>(() => {
  let title = 'Confidence Scores not available'
  if (props.context.confidence) {
    title = `Confidence Scores: ${Math.floor(props.context.confidence * 100).toString()}%`
  }

  if (isEdited.value) {
    return {
      contentClass:
        'flex border border-gray-750 bg-gray-950 font-medium text-xs',
      iconClass: 'bg-gray-750',
      title: 'Value edited',
    }
  }
  switch (props.context.confidenceLevel) {
    case ConfidenceLevel.HIGH:
      return {
        contentClass:
          'flex border border-gray-750 bg-gray-950 font-medium text-xs',
        iconClass: 'bg-primary',
        title,
      }
    case ConfidenceLevel.MEDIUM:
      return {
        contentClass:
          'flex border border-gray-750 bg-gray-950 font-medium text-xs',
        iconClass: 'bg-warning',
        title,
      }
    case ConfidenceLevel.LOW:
      return {
        contentClass:
          'flex border border-gray-750 bg-gray-950 font-medium text-xs',
        iconClass: 'bg-error',
        title,
      }
    default:
      return {
        contentClass:
          'flex border border-gray-750 bg-gray-950 font-medium text-xs',
        iconClass: 'bg-gray-750',
        title,
      }
  }
})

const isEdited = computed(
  () =>
    props.context.isEdited ||
    props.context.node.props.initial.value !== props.context.value.value,
)
</script>

<template>
  <Tooltip
    placement="bottom"
    :content="confidenceScoreClass.title"
    :class="confidenceScoreClass.contentClass"
    outer-class="flex justify-center  w-6 self-center"
  >
    <span
      v-if="!context.isApproved"
      class="h-2 w-2 self-center rounded-lg"
      :class="[
        isEdited ? 'bg-gray-500 opacity-30' : confidenceScoreClass.iconClass,
      ]"
    />
  </Tooltip>
</template>
