export function getCurrentLeaseId<T extends boolean = true>(
  shouldThrow?: T,
): T extends false ? string | undefined : string
export function getCurrentLeaseId(shouldThrow = true) {
  const route = useRoute('division-companyId-lease-leaseDocumentId')
  const leaseDocumentId = route.params.leaseDocumentId as string | undefined

  if (shouldThrow && !leaseDocumentId) {
    throw new Error('Lease not available')
  }

  return leaseDocumentId
}
