export default (o, c, d) => {
  o = o || {}
  const proto = c.prototype

  proto.formatDiff = function (input) {
    const end = d(input)
    const start = this.isBefore(end) ? this : end
    const finalEnd = this.isBefore(end) ? end : this

    let years = finalEnd.year() - start.year()
    let months = finalEnd.month() - start.month()
    let days = finalEnd.date() - start.date()

    if (days < 0) {
      months -= 1
      days += start.daysInMonth() // Add the days in the previous month to the days difference
    }

    if (months < 0) {
      years -= 1
      months += 12
    }

    let result = []

    if (years) result.push(`${years}y`)
    if (months) result.push(`${months}m`)
    if (days) result.push(`${days}d`)

    return result.length > 0 ? result.join(', ') : null
  }
}
