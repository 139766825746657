import {
  type ILeaseSpecialCondition,
  type ILeaseDocumentSpecialConditionsGroup,
  LeaseFieldType,
  LeaseFieldDataType,
  SpecialConditionType,
  type ILeaseField,
} from '@register'

export const groupSpecialConditions = (
  data: ILeaseSpecialCondition[],
): ILeaseDocumentSpecialConditionsGroup => {
  const _data = data as ILeaseSpecialCondition[]
  const groupedData: ILeaseDocumentSpecialConditionsGroup = {}

  for (const _item of _data) {
    const fieldType =
      _item.type === SpecialConditionType.CLAUSE
        ? LeaseFieldType.CLAUSE
        : _item.type === SpecialConditionType.DEFINITION
          ? LeaseFieldType.DEFINITION
          : LeaseFieldType.FIELD

    const item = {
      ..._item,
      id: _item.id!,
      type: _item.type!,
      leaseField:
        _item.leaseField ??
        ({
          id: _item.id!,
          name: '',
          fieldType,
          dataType: LeaseFieldDataType.STRING,
          description: '',
        } satisfies ILeaseField),
    }
    const name = item.leaseField.name
      .replace(' Clause', '')
      .replace(' Definition', '')

    if (!groupedData[item.id]) {
      groupedData[item.id] = { name }
    }

    groupedData[item.id][item.type] = item
  }

  return groupedData
}
