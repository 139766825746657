import type { TanstackTablePropsWithDefaults } from '@ui/components/TanstackTable'
import { defaultWindow } from '@vueuse/core'

export const useStickyHeader = (
  props: TanstackTablePropsWithDefaults,
  tableRef: Ref<HTMLElement | null>,
  theadRef: Ref<HTMLElement | null>,
) => {
  const { offsetHeader = 0, scroller = () => defaultWindow } =
    typeof props.sticky === 'object' ? props.sticky : {}

  const container = _getContainer()
  const isSticky = computed(() => !!props.sticky)
  const hasScroller = computed(() => !!container)

  if (isSticky.value && container) {
    const onScroll = (event: Event) => {
      if (!window || !tableRef.value || !theadRef.value) return

      const el: Element = ((event.target as Window)?.document
        ?.documentElement ||
        (event.target as Document)?.documentElement ||
        unrefElement(event.target as HTMLElement | SVGElement)) as Element

      const header = theadRef.value
      const table = tableRef.value

      const headerTop = table.offsetTop

      let scrollTop = el.scrollTop
      if (event.target === window.document && !scrollTop)
        scrollTop = window.document.body.scrollTop

      if (scrollTop > headerTop) {
        const yTranslation =
          Math.floor(Math.abs(scrollTop - headerTop)) + offsetHeader
        header.style.setProperty('transform', `translateY(${yTranslation}px)`)
      } else {
        header.style.removeProperty('transform')
      }
    }
    useEventListener(container, 'scroll', onScroll, {
      capture: false,
      passive: true,
    })
  }

  return { isSticky, hasScroller, getOffsetHeader, container }

  function _getContainer() {
    if (typeof scroller === 'function') return scroller() || defaultWindow
  }

  function getOffsetHeader() {
    return offsetHeader !== undefined && !hasScroller.value
      ? `${offsetHeader}px`
      : undefined
  }
}
