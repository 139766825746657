import { createSection } from '@formkit/inputs'
import LeaseDocumentInput from '../inputs/LeaseDocumentInput.vue'

export const input = createSection('input', () => ({
  $cmp: markRaw(LeaseDocumentInput) as unknown as string,
  bind: '$attrs',
  props: {
    context: '$node.context',
  },
  children: '$initialValue',
}))
