export enum FieldValueType {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  MONTH_AND_DAY = 'MONTHANDDAY',
}
