import { createSection } from '@formkit/inputs'

/**
 * Inner section
 *
 * @public
 */
export const inner = createSection('inner', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.inner',
    'data-approved': '$isApproved || undefined',
    'data-confidence-score': '$canApprove !== false',
  },
}))
