import type { Ref } from 'vue'
import type { LeaseDocumentInputContext, LeaseDocumentValue } from '../../types'
import { isNullish } from '@morev/utils'

export function useInputValue(context: Ref<LeaseDocumentInputContext>) {
  const value = computed(
    () => (context.value._value as LeaseDocumentValue | undefined)?.value,
  )
  const inputValue = computed({
    get: () => context.value.fieldConfig.toInput(value.value),
    set: (newValue) => toAPI(newValue),
  })
  const panelValue = computed({
    get: () => context.value.fieldConfig.toPanel?.(value.value),
    set: (newValue) => toAPI(newValue),
  })
  const approvedValue = computed(() =>
    toText(context.value.fieldConfig.toFormatted(value.value)),
  )
  const placeholderValue = computed(() =>
    context.value.fieldConfig.toFormatted(value.value),
  )

  return {
    inputValue,
    panelValue,
    approvedValue,
    placeholderValue,
  }

  function toText(value: unknown): string {
    if (isNullish(value)) return 'N/A'
    return String(value).trim() || 'N/A'
  }

  function toAPI(newValue: unknown) {
    if (newValue === value.value) return
    context.value.node.input({
      value: context.value.fieldConfig.toAPI(newValue),
      bounds: null,
    })
    context.value.handlers.change(context.value.node, context.value._value)
  }
}
