<script setup lang="ts">
import ActionButton from '../ActionButton.vue'
import type { LeaseDocumentInputContext } from '../../types'
import { twMerge } from 'tailwind-merge'

interface Props {
  context: LeaseDocumentInputContext
}

const props = defineProps<Props>()

const confidence = computed(() => props.context.confidence)
const disabled = computed(() => props.context.disabled)

const onDelete = () => {
  if (disabled.value) return
  props.context.handlers.delete(props.context.node)
  useAnalytics({
    name: 'Lease Review Change',
    data: {
      source: 'Delete Field Button Clicked',
      'Confidence Score': confidence.value,
      'Is Edited': props.context.isEdited,
      'Field ID': props.context.id,
      'Field Value': props.context._value.value || props.context.value,
    },
  })
}

const buttonClass = computed(() => {
  return twMerge([
    'hover:ring  h-7 w-7 z-10',
    'bg-gray-750 hover:bg-red-500 hover:ring-red-500/10 hover:text-red-50 sticky top-10',
  ])
})
</script>

<template>
  <ActionButton
    icon="trash"
    :disabled="disabled"
    class="z-[500]"
    :button-class="buttonClass.toString()"
    :sticky="true"
    @click="onDelete"
  />
</template>
