import type { FormKitTypeDefinition } from '@formkit/core'
import type { FormKitInputs } from '@formkit/inputs'
import {
  setupContext,
  setupValue,
  setupHistory,
  setupValidation,
  emitters,
  lock,
  addAsterisk,
  reset,
} from './features'
import { schema } from './schemas'
import type { Combine } from '~/types/combine'
import type {
  ILeaseDocumentField,
  ILeaseField,
  ILeaseRegisterSettings,
} from '@register'
import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'

declare module '@formkit/inputs' {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    'lease-document': {
      type: 'lease-document'
      field: Combine<ILeaseField, ILeaseDocumentField>
      selectedText?: LeaseDocumentValue | undefined
      disabled?: boolean
      describedBy?: string
      actions?: boolean
      leaseSettings?: ILeaseRegisterSettings
      selected?: boolean
      canApprove?: boolean
      canDelete?: boolean
      hideMapButton?: boolean
      sideActions?: boolean
      value?: LeaseDocumentValue
    }
  }
}

/**
 * Input definition for a textarea.
 * @public
 */
export const leaseDocument: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema,
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * An array of extra props to accept for this input.
   */
  props: [
    'field',
    'leaseSettings',
    'selectedText',
    'loading',
    'selected',
    'actions',
    'canApprove',
    'canDelete',
    'hideMapButton',
    'sideActions',
  ],
  /**
   * Additional features that should be added to your input
   */
  features: [
    setupContext,
    setupValue,
    setupHistory,
    setupValidation,
    lock,
    emitters,
    addAsterisk,
    reset,
  ],
}
