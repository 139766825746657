import type { Ref } from 'vue'
import type {
  LeaseDocumentInputContext,
  LeaseDocumentValue,
} from '@ui/formkit/inputs/leaseDocumentInput/types'
import { useModal } from 'vue-final-modal'
import { ReviewFormRentReviewTableForm } from '#components'
import type { RentReviewTableValue } from '@register/components/Review/Form/RentReviewTable'
import type { ILeaseRegisterSettings } from '@register'

export const useFormModal = (
  context: Ref<LeaseDocumentInputContext>,
  {
    commencementDate,
    expiryDate,
    settings,
  }: {
    commencementDate: Ref<string>
    expiryDate: Ref<string>
    settings: Ref<ILeaseRegisterSettings | undefined>
  },
) => {
  return (rentIncrease?: RentReviewTableValue) => {
    const updateValue = (data: RentReviewTableValue) => {
      const contextValue: LeaseDocumentValue<RentReviewTableValue[] | string> =
        context.value._value

      // The original value can be a string, so we need to convert it to an empty array
      // to prevent destructing the string.
      // If the original value is an array, we clone it to prevent modifying its reference. But we don't destruct its
      // objects in order to have the same reference and be able to find their indexes.
      const value: RentReviewTableValue[] = Array.isArray(contextValue.value)
        ? [...(contextValue.value ?? [])]
        : []

      if (rentIncrease) {
        // EDIT
        // Find index of the rent increase to update.
        const index = value.indexOf(rentIncrease)
        value[index] = data
      } else {
        // CREATE
        value.push(data)
      }

      // Update input value.
      context.value.node.input({
        ...contextValue,
        value,
      })
    }

    const { open, close } = useModal({
      component: ReviewFormRentReviewTableForm,
      attrs: {
        isOption: context.value.field.id === 'OptionRentIncreaseTable',
        rentIncrease: rentIncrease ?? null,
        // @ts-expect-error - Reactivity
        commencementDate,
        // @ts-expect-error - Reactivity
        expiryDate,
        // @ts-expect-error - Reactivity
        settings,
        onConfirm(data: RentReviewTableValue) {
          updateValue(data)
          close()
        },
        onClose() {
          close()
        },
      },
    })

    open()
  }
}
