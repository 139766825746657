import type { Ref } from 'vue'
import type {
  ListboxOptionsItem,
  ListboxFormat,
  ListboxInnerValue,
  ListboxOptionsTrack,
} from '../index'

interface UseOptionsProps {
  options: Ref<ListboxOptionsItem[]>
  labelBy: Ref<string>
  trackBy: Ref<string>
  format: Ref<ListboxFormat>
  multiple: Ref<boolean>
  customLabel: Ref<((option: ListboxOptionsItem) => string) | undefined>
}

export interface UseOptionsReturn {
  getLabel: (option: ListboxOptionsItem) => string
  getTrack: (option: ListboxOptionsItem) => any
  toValue: (
    option: ListboxOptionsItem | ListboxOptionsItem[] | undefined,
  ) => any
  toOption: (value: any) => ListboxInnerValue
  compare: (a: ListboxOptionsItem, b: ListboxOptionsItem) => boolean
}

export const useOptions = (props: UseOptionsProps): UseOptionsReturn => {
  const { options, multiple, labelBy, trackBy, format, customLabel } = props

  const getLabel = (option: ListboxOptionsItem): string => {
    if (customLabel?.value) return customLabel.value(option)
    if (typeof option === 'string') return option
    if (option.isTag) return option.label
    return option[labelBy.value]
  }

  const getTrack = (option: ListboxOptionsItem): ListboxOptionsTrack => {
    if (typeof option === 'object' && option !== null)
      return option[trackBy.value]
    return option
  }

  const toValue = (option: ListboxInnerValue): any => {
    if (multiple.value) {
      return ((option ?? []) as ListboxOptionsItem[]).map((option) =>
        _toValue(option),
      )
    }

    return _toValue(option)
  }

  const toOption = (value: any): ListboxInnerValue => {
    if (multiple.value) {
      return ((value ?? []) as ListboxOptionsItem[]).map((v) => _toOption(v))
    }

    return _toOption(value)
  }

  const compare = (a: ListboxOptionsItem, b: ListboxOptionsItem): boolean => {
    return getTrack(a) === getTrack(b)
  }

  return {
    getLabel,
    getTrack,
    toValue,
    toOption,
    compare,
  }

  function _toValue(option: ListboxOptionsItem | undefined): any {
    if (option === undefined) return
    if (format.value === 'value') return getTrack(option)
    return option
  }

  function _toOption(
    value: ListboxOptionsItem | undefined,
  ): ListboxOptionsItem | undefined {
    const fallback =
      format.value === 'option'
        ? value
        : { [labelBy.value]: value, [trackBy.value]: value }

    return (
      options.value.find((option) => {
        if (value === undefined) return false
        return getTrack(option) === getTrack(value)
      }) ?? fallback
    )
  }
}
