<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import type { LeaseDocumentInputContext } from '../types'

interface Props {
  context: LeaseDocumentInputContext
  modelValue?: any
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
})

const emit = defineEmits(['update:modelValue'])
const innerValue = useVModel(props, 'modelValue', emit)
</script>

<template>
  <Popover>
    <PopoverButton
      v-if="!context.isApproved"
      class="focus-visible:border-primary absolute right-0 top-1/2 flex h-6 w-8 -translate-y-1/2 justify-center self-start rounded-lg py-2.5 focus:outline-none focus-visible:border focus-visible:ring-2 focus-visible:ring-[#545A3B]"
    >
      <Icon name="calendar" filled class="self-center" />
    </PopoverButton>

    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <PopoverPanel
        class="absolute left-0 top-full z-50 mt-2 w-full rounded-md"
      >
        <VueDatePicker
          v-model="innerValue"
          format="dd/MM/yyyy"
          :dark="true"
          class="justify-center"
          auto-apply
          :enable-time-picker="false"
          :month-change-on-scroll="false"
          inline
        />
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<style lang="postcss" scoped>
:deep(.dp__menu) {
  @apply rounded-lg border-gray-500 bg-gray-800;
}
:deep(.dp__calendar_header_separator) {
  @apply bg-gray-500;
}
:deep(.dp__input) {
  @apply border-none bg-transparent py-0 pl-0 pr-6 outline-none ring-0;
}
:deep(.dp__icon) {
  @apply p-0;
}
:deep(.dp__input_wrap) {
  div > svg {
    @apply hidden;
  }
}
</style>
