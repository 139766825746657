export default defineNuxtPlugin({
  name: 'auth',
  dependsOn: ['casl', 'api'],
  async setup() {
    const { data } = useAuthState()
    const { fetchUser } = useAuth()

    // Only fetch session if it was not yet initialized server-side
    if (typeof data.value !== 'undefined') {
      return
    }

    const route = useRoute()
    if (route.path !== '/callback') {
      await fetchUser()
    }
  },
})
