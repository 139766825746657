import type { TanstackTablePropsWithDefaults } from '@ui/components/TanstackTable'
import { baseTanstackTable } from '@ui/components/TanstackTable/style'

type VariantProps = Parameters<typeof baseTanstackTable>[0]

export const useInnerTableVariants = (
  props: TanstackTablePropsWithDefaults,
  variants: VariantProps,
) => {
  if (!props.variant) return baseTanstackTable(variants)
  return props.variant.tv(mergeProps(variants, props.variant.props ?? {}))
}

function mergeProps(obj1: VariantProps, obj2: VariantProps) {
  // Create a new object with the prototype of the first object
  const result = Object.create(Object.getPrototypeOf(obj1))

  // Get property descriptors of the first object
  const descriptors1 = Object.getOwnPropertyDescriptors(obj1)

  // Define these properties on the result object
  Object.defineProperties(result, descriptors1)

  // Get property descriptors of the second object
  const descriptors2 = Object.getOwnPropertyDescriptors(obj2)

  // Define these properties on the result object
  Object.defineProperties(result, descriptors2)

  return result
}
