import type {
  ILeaseDocumentField,
  ILeaseDocumentClausesAndDefinitionsGroup,
} from '@register'

export const groupClausesAndDefinitions = (
  data: ILeaseDocumentField[],
): ILeaseDocumentClausesAndDefinitionsGroup | undefined => {
  const groupedData: ILeaseDocumentClausesAndDefinitionsGroup = {}

  data.forEach((item) => {
    const id = item.id.replace('Clause', '').replace('Definition', '')
    const itemType = item.fieldType === 'clause' ? 'clause' : 'definition'

    const name = item.name.replace(' Clause', '').replace(' Definition', '')

    if (!groupedData[id]) {
      groupedData[id] = { id, name }
    }

    groupedData[id][itemType] = item
  })

  return Object.keys(groupedData).length === 0 ? undefined : groupedData
}
