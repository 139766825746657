import type { Address as _GoogleAddress } from '~/types/googleAddress'
import type { FieldConfigFactory } from '../../types'
import { isValidValue } from '../../utils'
import ExclusiveCheckBox from '../../inputs/ExclusiveCheckBox/ExclusiveCheckBox.vue'

type Checkbox = {
  values: {
    [key: string]: string
  }
  checked: keyof Checkbox['values']
}

export const exclusiveCheckboxFactory: FieldConfigFactory = (node) => {
  node.context.isExclusiveCheckboxFactory = true

  return {
    schema: 'exclusiveCheckbox',
    type: 'exclusiveCheckbox',
    component: markRaw(ExclusiveCheckBox),
    toFormatted: (value: string | null | undefined): string | undefined => {
      if (!isValidValue(value)) return
      return value
    },
    toAPI: (value: string | null | undefined): string | undefined => {
      if (!isValidValue(value)) return

      return value
    },
    toInput: (value: any | null | undefined): any | undefined => {
      if (!isValidValue(value)) return
      return value
    },
    fromDocument: (value: string): string | undefined => {
      if (!value) return
      return value
    },
  }
}
