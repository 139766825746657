import { outer, marginBottom, label, exclusiveCheckbox } from '../sections'

import { help, messages, message } from '@formkit/inputs'

export const exclusiveCheckboxSchema = outer(
  marginBottom(
    label('$label || $field.name'),
    exclusiveCheckbox(),
    help(),
    messages(message('$message.value')),
  ),
)
