import { computed } from 'vue'
import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  textInput,
  $attrs,
} from '@formkit/inputs'
import IMask from 'imask'

/**
 * Input definition for a text.
 * @public
 */
export const text: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    /* Outer */
    wrapper(
      label('$label'),
      inner(
        /* Inner */
        icon('prefix', 'label'),
        prefix(),
        $attrs(
          {
            value: {
              if: '$_mask !== undefined',
              then: '$_mask.value',
              else: '$_value',
            },
          },
          textInput(),
        ),
        suffix(),
        icon('suffix'),
      ),
    ),
    help('$help'),
    messages(message('$message.value')),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'text',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['mask', 'unmask'],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: 'text',
  /**
   * Additional features that should be added to your input
   */
  features: [mask],
}

/**
 * Here we add our masks.
 */
function mask(node: FormKitNode) {
  const hasMask = computed<boolean>(() => isObject(node.context?.mask))
  //TODO: check with João the type error
  const mask = ref<IMask.DeduceMasked<IMask.AnyMaskedOptions>>()

  node.hook.prop((prop, next) => {
    // If the prop is not a mask, we don't care.
    if (prop.prop !== 'mask') {
      return next(prop)
    }

    // Normalize mask to an object
    if (!isObject(prop.value)) {
      prop.value = { mask: prop.value }
    }

    // Only update if there is a mask and the new mask has the same type as the old mask.
    // if (mask.value && typeof mask.value!.mask === prop.value.mask) {
    //   mask.value.updateOptions(prop.value)
    //
    //   return next(prop)
    // }

    // If we end up here, means that we don't have a mask or the mask has a different type.
    // If the mask has a different type, we need to save the value so that we can restore it after the mask is updated.
    const tempValue = mask.value?.value

    // Create the mask.
    mask.value = IMask.createMask<IMask.AnyMaskedOptions>(prop.value)

    // Restore the value.
    if (tempValue) {
      mask.value!.resolve(tempValue)
    }

    return next(prop)
  })

  node.hook.input((value, next) => {
    if (hasMask.value) {
      const maskedValue = mask.value!.resolve(value || '')
      // Change the value to be emitted to masked or unmasked value, based on prop
      value =
        node.context!.unmask === false ? maskedValue : mask.value!.unmaskedValue
    }

    return next(value)
  })

  node.on('created', () => {
    if (hasMask.value) {
      // Save the mask instance, so we can access it through the context
      node.context!._mask = mask
    }
  })

  node.on('dom-input-event', (e) => {
    const target = e.payload.target as HTMLInputElement

    if (hasMask.value) {
      // Update the input element to display the masked value
      target.value = mask.value!.value
    }
  })

  node.on('prop:mask', () => {
    // If the mask has changed, update the value to reflect the new mask
    node.input(node.context!.value)
  })
}
