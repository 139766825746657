import { defu } from 'defu'
import defaultTheme from '@flnt-accurait/web-components/formkit.theme'
import classNames from 'classnames'

const inputText = classNames(
  'sm:text-sm',
  'text-base',
  'text-gray-100',
  'placeholder-gray-400',
  'formkit-disabled:text-gray-500',
)
const innerInvalid = classNames(
  'formkit-invalid:ring-red',
  'formkit-invalid:border-red',
  'formkit-invalid:focus-within:ring-red/20',
  'formkit-invalid:focus-within:border-red',
  '[&>span:first-child]:formkit-invalid:focus-within:text-red ',
  '[&>label:first-child]:formkit-invalid:focus-within:text-red ',
)
const inputInvalid = classNames(
  'formkit-invalid:text-red',
  'formkit-invalid:border-red',
  'formkit-invalid:focus-within:border-red',
  'formkit-invalid:placeholder-red',
  'formkit-invalid:data-[placeholder="true"]:placeholder-red',
  'formkit-invalid:data-[placeholder="true"]:border-red',
)
const innerBackground = classNames('bg-black', 'bg-opacity-10')

const innerRing = classNames(
  'border',
  'border-gray-550',
  'focus-within:ring-4',
  'focus-within:border-primary/70',
  'focus-within:ring-primary/20',
  '[&>span:first-child]:focus-within:text-gray-100',
)

export default defu(
  {
    'family:text': {
      inner: classNames(
        'flex',
        'items-center',
        innerBackground,
        innerRing,
        'rounded-md',
        'mb-1',
        innerInvalid,
      ),
      input: classNames(
        'w-full',
        'p-2.5',
        'border-none',
        inputText,
        inputInvalid,
      ),
    },
    textarea: {
      inner: classNames(
        'flex',
        'items-center',
        innerBackground,
        innerRing,
        'rounded-md',
        'mb-1',
        innerInvalid,
      ),
      input: classNames(
        'w-full',
        'p-2.5',
        'border-none',
        inputText,
        inputInvalid,
      ),
    },

    'family:box': {
      // decorator: classNames(
      // ),
      decorator: classNames(
        'block',
        'relative',
        'h-5',
        'w-5',
        'mr-2',
        'rounded',
        'text-transparent',
        innerBackground,
        'cursor-pointer',
        'ring-1',
        'ring-gray-300',
        // focus
        'peer-focus:outline',
        'peer-focus:outline-2',
        'peer-focus:outline-offset-[3px]',
        'peer-focus:outline-primary/40',
        // checked
        'peer-checked:ring-primary/40',
        'peer-checked:bg-primary',
        'peer-checked:text-black',
        // disabled
        'formkit-disabled:cursor-not-allowed',
        // invalid
        // 'formkit-invalid:bg-red-50',
        'formkit-invalid:ring-red',
        'formkit-invalid:peer-checked:ring-red/40',
        'formkit-invalid:peer-checked:bg-red',
        'formkit-invalid:peer-focus:outline-red-300/40',
      ),
    },
    toggle: {
      altLabel:
        'block w-full mb-1 font-medium text-sm text-gray-900 ' +
        // invalid
        'formkit-invalid:text-red ' +
        // transition
        'transition ' +
        'duration-100',
      inner: '$reset inline-block mr-2 ',
      input: 'peer absolute opacity-0 pointer-events-none',
      innerLabel:
        'cursor-pointer text-primary text-[10px] absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1.5',
      thumb:
        'cursor-pointer ' +
        'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-250',
      track:
        'overflow-hidden ' +
        'cursor-pointer ' +
        'p-1 ' +
        'min-w-[3.4em] ' +
        'relative ' +
        'rounded-full ' +
        'transition-all ' +
        'bg-gray-700 ' +
        // checked
        'peer-checked:bg-primary ' +
        'peer-checked:[&>div:last-child]:left-full ' +
        'peer-checked:[&>div:last-child]:-translate-x-full ' +
        'peer-checked:[&>div:first-child:not(:last-child)]:left-0 ' +
        'peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0 ' +
        'peer-checked:[&>div:first-child:not(:last-child)]:text-gray-700 ' +
        'peer-checked:[&>.formkit-thumb]:bg-gray-800 ' +
        // invalid
        'formkit-invalid:bg-red/10 ' +
        'formkit-invalid:ring-red ' +
        'formkit-invalid:peer-checked:ring-red ' +
        'formkit-invalid:peer-checked:bg-red ' +
        '',
      label:
        'cursor-pointer ' +
        'font-normal ' +
        'text-sm ' +
        'text-gray-900 ' +
        '!mb-0 ' +
        // invalid
        'formkit-invalid:text-red ' +
        // transition
        'transition ' +
        'duration-100',
      valueLabel: 'text-sm text-gray-900 mb-0 formkit-invalid:text-red',
      wrapper: 'flex flex-wrap items-center mb-1',
    },
    multiselect: {
      outer: classNames('multiselect'),
      inner: classNames(
        'flex',
        'rounded-md',
        'mb-1',
        'flex-auto',
        innerBackground,
        innerRing,
        innerInvalid,
      ),
    },
    datepicker: {
      // input: classNames(
      //   'block',
      //   'w-full',
      //   // '[textarea]:min-h-[60px]',
      //   'p-2.5',
      //   'border-none',
      //   'transition-all',
      //   'duration-100',
      //   'ease-in-out',
      //   'outline-none',
      //   'appearance-none',
      //   inputText,
      //   inputInvalid,
      // ),
      inner: classNames(
        'relative',
        'rounded-md',
        'flex',
        'flex-auto',
        'cursor-pointer',
        innerBackground,
        innerRing,
        innerInvalid,
      ),
    },
    'lease-document': {
      outer: classNames('group'),
      marginBottom: 'mb-4',
      label: classNames(
        'cursor-pointer',
        'group-data-[selected=true]:!font-extrabold',
        'group-data-[selected=true]:!text-white',
        'group-data-[selected=true]:-mt-1',
        'group-data-[selected=true]:!text-base',
      ),
      field: classNames('flex'),
      inner: classNames(
        'relative',
        'flex',
        'rounded-md',
        'flex-auto',
        innerBackground,
        innerRing,
        innerInvalid,
        'focus-within:!border-gray-650',
        'focus-within:!ring-gray-800',
        'data-[approved=true]:rounded-md',
        'data-[approved=true]:!border-primary',
        'data-[approved=true]:!bg-gray-900/30',
        'data-[approved=true]:!bg-opacity-5',
        'data-[approved=true]:!ring-[1px]',
        'data-[approved=true]:!ring-primary/70',
        'data-[approved=true]:data-[confidence-score=false]:pl-5',
      ),
      input: classNames(
        'block',
        'w-full',
        // '[textarea]:min-h-[60px]',
        'p-2.5',
        'border-none',
        'transition-all',
        'duration-100',
        'ease-in-out',
        'outline-none',
        'appearance-none',
        inputText,
        inputInvalid,
      ),
      help: 'transition group-hover:mt-0',
      messages:
        ' px-4 py-2 bg-red/10 text-red rounded-md border border-red mr-11 group-hover:mt-11 transition-all',

      approved: classNames(
        'flex',
        'min-h-[44px]',
        'w-full',
        'cursor-pointer',
        'whitespace-break-spaces',
        'py-2.5',
        'text-base',
        'font-medium',
        'text-black',
        'text-white',
        '[&>span]:break-all',
        '[&>span]:pr-1.5',
        '[&>span]:w-full',
      ),
      sideActions: classNames(
        'px-1.5',
        'gap-1',
        'flex',
        'flex-none',
        'flex-col',
        'space-y-2',
        'z-10',
      ),
      actionsWrapper: classNames(
        'z-[40]',
        'mt-0.5',
        'data-[side-actions=true]:pr-10',
      ),
      actions: classNames(
        '-translate-y-3',
        'group-hover:opacity-100',
        'group-hover:translate-y-0',
        'bg-gray-750/80',
        'backdrop-blur-xl',
        'backdrop-saturate-[180%]',
        'border',
        'border-gray-550',
        'rounded-md',
        'right-11',
        'left-0',
        'p-1',
        'flex',
        'opacity-0',
        'absolute',
        'justify-between',
        'transition-all',
      ),
    },
    listbox: {
      outer: classNames('multiselect'),
      inner: classNames(
        'relative',
        'rounded-md',
        'flex',
        'flex-auto',
        innerBackground,
        innerRing,
        innerInvalid,
      ),
      inputOuter: classNames(
        'relative',
        'block',
        'w-full',
        'min-h-[40px]',
        'min-w-[55px]',
        'p-2.5',
        'pr-5',
        'border-none',
        'transition-all',
        'duration-100',
        'ease-in-out',
        'outline-none',
        'appearance-none',
        'text-left',
        'bg-transparent',
        'focus:ring-0',
        inputText,
        inputInvalid,
      ),
      inputInner: classNames(
        'border-none',
        'transition-all',
        'duration-100',
        'ease-in-out',
        'outline-none',
        'appearance-none',
        'text-left',
        'bg-transparent',
        'focus:ring-0',
        inputText,
        'formkit-invalid:text-red',
        'formkit-invalid:placeholder-red',
        'w-full',
        'formkit-invalid:data-[placeholder="true"]:placeholder-red',
      ),
      buttonInner: classNames(
        'block',
        'truncate',
        'data-[placeholder=true]:text-gray-400',
        'formkit-invalid:data-[placeholder=true]:text-red',
      ),
      buttonIcon: classNames(
        // 'pointer-events-none',
        'absolute',
        'inset-y-0',
        'right-0',
        'flex',
        'items-center',
        'pr-2',
      ),
      dropdownWrapper: classNames(
        'absolute',
        'left-0',
        'z-40',
        'max-h-60',
        // 'min-w-[12rem]',
        'w-full',
        'overflow-auto',
        // Background
        'bg-gray-800',
        'shadow-lg',
        // Border
        'rounded-md',
        'border',
        'border-gray-500',
        // Text
        'text-base',
        'sm:text-sm',
        // Ring
        'ring-1',
        'ring-black',
        'ring-opacity-5',
        'focus:outline-none',
        'z-50',
      ),
      listbox: classNames('focus:outline-none'),
      listitem: classNames(
        'relative',
        'cursor-pointer',
        'whitespace-nowrap',
        'p-3',
        'text-sm',
        'bg-gray-800',
        'text-gray-200',
        'transition',
        'duration-100',
        'ui-selected:text-white',
        'ui-active:text-white',
        'ui-selected:bg-gray-750',
        'ui-active:bg-gray-700',
      ),
      listitemInner: classNames(
        'block',
        'truncate',
        'font-normal',
        'ui-selected:font-medium',
      ),
      emptyMessage: classNames(
        'relative',
        'inline-block',
        'whitespace-nowrap',
        'text-sm',
        'text-gray-200',
        'text-center',
        'w-full',
      ),
      emptyMessageInner: '',
      loadMore: classNames(
        'relative',
        'cursor-pointer',
        'select-none',
        'whitespace-nowrap',
        'p-3',
        'text-sm',
        'text-gray-200',
        'text-center',
        'hover:bg-gray-700',
        'transition',
        'duration-100',
      ),
      loadMoreInner: '',
    },
    slider: {
      fill: classNames(
        'bg-primary',
        'h-full',
        'rounded-full',
        'absolute',
        'top-0',
        'mx-[-4px]',
      ),
      tooltip: classNames(
        'formkit-tooltip',
        'absolute',
        'bottom-full',
        'left-1/2',
        '-translate-x-1/2',
        '-translate-y-[4px]',
        'bg-primary',
        'text-black',
        'py-1',
        'px-2',
        'text-xs',
        'leading-none',
        'whitespace-nowrap',
        'rounded-sm',
        'opacity-0',
        'pointer-events-none',
        'transition-opacity',
        'after:content-[&quot;&quot;]',
        'after:absolute',
        'after:top-full',
        'after:left-1/2',
        'after:-translate-x-1/2',
        'after:-translate-y-[1px]',
        'after:border-4',
        'after:border-transparent',
        'after:border-t-primary',
        'group-hover:opacity-100',
        'group-focus-visible:opacity-100',
        'group-data-[show-tooltip=&quot;true&quot;]:opacity-100',
        'formkit-tooltip-max',
      ),
    },
    taglist: {
      outer: classNames('multiselect'),
      inner: classNames(
        'relative',
        'rounded-md',
        'flex',
        'flex-auto',
        innerBackground,
        innerRing,
        innerInvalid,
      ),
      inputOuter: classNames(
        'relative',
        'block',
        'w-full',
        'min-h-[40px]',
        'min-w-[55px]',
        'p-2.5',
        'pr-5',
        'border-none',
        'transition-all',
        'duration-100',
        'ease-in-out',
        'outline-none',
        'appearance-none',
        'text-left',
        'bg-transparent',
        'focus:ring-0',
        inputText,
        inputInvalid,
      ),
      inputInner: classNames(
        'w-full',
        'border-none',
        'transition-all',
        'duration-100',
        'ease-in-out',
        'outline-none',
        'appearance-none',
        'text-left',
        'bg-transparent',
        'focus:ring-0',
        inputText,
        'formkit-invalid:text-red',
        'formkit-invalid:placeholder-red',
        'formkit-invalid:data-[placeholder="true"]:placeholder-red',
      ),
      buttonInner: classNames(
        'block',
        'truncate',
        'data-[placeholder=true]:text-gray-400',
        'formkit-invalid:data-[placeholder=true]:text-red ',
      ),
      buttonIcon: classNames(
        // 'pointer-events-none',
        'absolute',
        'inset-y-0',
        'right-0',
        'flex',
        'items-center',
        'pr-2',
      ),
      dropdownWrapper: classNames(
        'absolute',
        'left-0',
        'z-40',
        'max-h-60',
        // 'min-w-[12rem]',
        'w-full',
        'overflow-auto',
        // Background
        'bg-white',
        'bg-gray-800',
        'shadow-lg',
        // Border
        'rounded-md',
        'border',
        'border-gray-500',
        // Text
        'text-base',
        'sm:text-sm',
        // Ring
        'ring-1',
        'ring-black',
        'ring-opacity-5',
        'focus:outline-none',
        'z-50',
      ),
      listbox: classNames('bg-gray-800', 'focus:outline-none'),
      listitem: classNames(
        'relative',
        'cursor-pointer',
        'whitespace-nowrap',
        'p-3',
        'text-sm',
        'text-gray-200',
        'transition',
        'duration-100',
        'ui-selected:text-white',
        'ui-active:text-white',
        'ui-selected:bg-gray-750',
        'ui-active:bg-gray-700',
      ),
      listitemInner: classNames(
        'block',
        'truncate',
        'font-normal',
        'ui-selected:font-medium',
      ),
      emptyMessage: classNames(
        'relative',
        'inline-block',
        'whitespace-nowrap',
        'text-sm',
        'text-gray-200',
        'text-center',
        'w-full',
      ),
      emptyMessageInner: '',
      loadMore: classNames(
        'relative',
        'cursor-pointer',
        'select-none',
        'whitespace-nowrap',
        'p-3',
        'text-sm',
        'text-gray-200',
        'text-center',
        'hover:bg-gray-700',
        'transition',
        'duration-100',
      ),
      loadMoreInner: '',
      taglist: classNames('mt-4'),
      taggeditem: classNames(
        'flex',
        'items-center',
        'justify-between',
        'rounded-md',
        'pl-3',
        'pr-1',
        'py-2',
        'hover:bg-gray-800',
      ),
      taggeditemInner: 'overflow-hidden',
      taggeditemRemove: classNames(
        'cursor-pointer',
        'rounded-md',
        'p-1.5',
        'text-sm',
        'hover:bg-gray-700',
        'transition',
        'duration-100',
      ),
      help: classNames('mt-1'),
    },
    togglebuttons: {
      inner: classNames(
        'relative',
        'rounded-md',
        innerBackground,
        innerRing,
        innerInvalid,
      ),
      input: classNames(
        'relative',
        'block',
        'w-full',
        'min-h-[40px]',
        'p-2.5',
        'border-none',
        'transition-all',
        'duration-100',
        'ease-in-out',
        'outline-none',
        'appearance-none',
        'text-left',
        'bg-transparent',
        'focus:ring-0',
        inputText,
        inputInvalid,
      ),
      inputInner: classNames('block', 'w-full', inputText),
      buttonInner: classNames(
        'block',
        'truncate',
        'data-[placeholder=true]:text-gray-400',
        'formkit-invalid:data-[placeholder=true]:text-red ',
      ),
      buttonIcon: classNames(
        // 'pointer-events-none',
        'absolute',
        'inset-y-0',
        'right-0',
        'flex',
        'items-center',
        'pr-2',
      ),
      listitemInner: classNames(
        'block',
        'truncate',
        'font-normal',
        'ui-selected:font-medium',
      ),
      emptyMessage: classNames(
        'relative',
        'whitespace-nowrap',
        'p-3',
        'text-sm',
        'text-gray-200',
        'text-center',
      ),
      emptyMessageInner: '',
      loadMore: classNames(
        'relative',
        'cursor-pointer',
        'select-none',
        'whitespace-nowrap',
        'p-3',
        'text-sm',
        'text-gray-200',
        'text-center',
        'hover:bg-gray-700',
        'transition',
        'duration-100',
      ),
      loadMoreInner: '',
    },
  },
  defaultTheme,
)
