import { createSection } from '@formkit/inputs'

/**
 * Wrapper input section
 *
 * @public
 */
export const wrapper = createSection('wrapper', () => ({
  $el: 'div',
  attrs: {
    if: '$disabled !== true',
    then: {
      class: '$classes.wrapper',
      onClick: '$handlers.select',
    },
    else: {
      class: '$classes.wrapper',
    },
  },
}))
