import type { ToRefs } from 'vue'
import {
  createDynamicColumn,
  createSelectionColumn,
  createSkeletonColumn,
} from '../columns'
import type { TanstackTablePropsWithDefaults } from '..'

export const useInitializeColumns = (
  props: ToRefs<TanstackTablePropsWithDefaults>,
) => {
  const { columns: _columns, selection, loading } = props

  const slots = useSlots()

  const selectionColumn = createSelectionColumn()
  const dynamicColumns = computed(() =>
    _columns.value.map((column) =>
      loading.value
        ? createSkeletonColumn(column, slots)
        : createDynamicColumn(column, slots),
    ),
  )

  return computed(() => {
    const columns = dynamicColumns.value

    if (selection?.value) {
      columns.unshift(selectionColumn)
    }

    return columns
  })
}
