import { IncreaseTableMethod } from '@register'

export const localizeRentReviewMethod = (method: IncreaseTableMethod) => {
  switch (method) {
    case IncreaseTableMethod.FIXED_AMOUNT:
      return 'Fixed amount'
    case IncreaseTableMethod.FIXED_PERCENT:
      return 'Fixed percentage'
    case IncreaseTableMethod.CPI:
      return 'CPI'
    case IncreaseTableMethod.CPI_PLUS:
      return 'CPI +'
    case IncreaseTableMethod.MARKET:
      return 'Market review'
    // case IncreaseTableMethod.MULTIPLE:
    //   return 'Multiple'
    case IncreaseTableMethod.FORMULA:
      return 'Formula'
    default:
      return '-'
  }
}
