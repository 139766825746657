<script setup lang="ts">
import type { LeaseDocumentInputContext } from '../types'

import { useInputValue } from './composables/useInputValue'

interface Props {
  context: LeaseDocumentInputContext
}
const props = defineProps<Props>()

const context = toRef(props, 'context')
const { panelValue } = useInputValue(context)
</script>

<template>
  <component
    :is="context.fieldConfig.panelComponent"
    v-if="context.fieldConfig.panelComponent"
    v-model="panelValue"
    :context="context"
  />
</template>
