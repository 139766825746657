import { $if } from '@formkit/inputs'
import { fieldSchema } from './field'
import { tableSchema } from './table'
import { mapsSchema } from './maps'
import { exclusiveCheckboxSchema } from './exclusiveCheckbox'
import type { FormKitSchemaExtendableSection } from '@formkit/inputs'

// Define the mapper object
const components: Record<string, FormKitSchemaExtendableSection> = {
  exclusiveCheckbox: exclusiveCheckboxSchema,
  maps: mapsSchema,
  table: tableSchema,
}

const generateIfSchemaRecursive = (
  keys: string[],
  components: Record<string, FormKitSchemaExtendableSection>,
  fallback: FormKitSchemaExtendableSection,
): FormKitSchemaExtendableSection => {
  if (keys.length === 0) {
    return fallback
  }

  const currentKey = keys[0]
  const currentSchema = components[currentKey] || {}
  const checkField = `$fieldConfig.schema === "${currentKey}"`

  return $if(
    checkField,
    currentSchema,
    generateIfSchemaRecursive(keys.slice(1), components, fallback),
  )
}

export const schema = generateIfSchemaRecursive(
  Object.keys(components),
  components,
  fieldSchema,
)
