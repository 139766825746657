import { createSection } from '@formkit/inputs'
import ExclusiveCheckbox from '../inputs/ExclusiveCheckBox/ExclusiveCheckBox.vue'

export const exclusiveCheckbox = createSection('exclusiveCheckbox', () => ({
  $cmp: markRaw(ExclusiveCheckbox) as unknown as string,
  bind: '$attrs',
  props: {
    context: '$node.context',
  },
}))
