import { tv } from 'tailwind-variants'

export const baseTanstackTable = tv({
  slots: {
    base: 'w-full',
    wrapper: 'w-full',
    table: 'w-full',
    thead: 'border-b border-gray-750',
    theadTr: 'group/tr bg-gray-850',
    theadTh: 'group/th text-left text-sm font-semibold text-white bg-inherit',
    theadThInner: 'flex items-center justify-start',
    sortButton: 'ml-1',
    tbody: 'divide-y divide-gray-800',
    tbodyTr: 'group/tr transition-colors duration-100',
    tbodyTd: 'group/td text-left text-sm bg-inherit',
  },
  variants: {
    hoverable: {
      true: {
        tbodyTr: 'hover:bg-gray-800',
      },
    },
    clickable: {
      true: {
        tbodyTr: 'cursor-pointer',
      },
    },
    stripedRows: {
      true: {
        tbodyTr: 'odd:bg-gray-850 even:bg-gray-800',
      },
      false: {
        tbodyTr: 'bg-gray-850',
      },
    },
    showGridlines: {
      true: {
        table: 'border-separate border-spacing-0',
        tbodyTd: 'border-b border-r border-gray-750 first:border-l',
      },
    },
    padding: {
      sm: {
        theadTh: 'px-2 py-1',
        tbodyTd: 'px-2 py-1',
      },
      md: {
        theadTh: 'px-4 py-2.5',
        tbodyTd: 'px-4 py-2.5',
      },
      lg: {
        theadTh: 'px-6 py-3.5',
        tbodyTd: 'px-6 py-3.5',
      },
      none: undefined,
    },
    rounded: {
      true: {
        base: 'rounded-lg',
        theadTr:
          '[&>th:first-child]:overflow-hidden [&>th:first-child]:rounded-tl-lg [&>th:last-child]:overflow-hidden [&>th:last-child]:rounded-tr-lg',
        tbody:
          '[&>tr:last-child>td:first-child]:overflow-hidden [&>tr:last-child>td:first-child]:rounded-bl-lg [&>tr:last-child>td:last-child]:rounded-br-lg',
      },
    },
    sticky: {
      true: {
        thead: 'sticky z-10',
      },
    },
    hasScroller: {
      true: {
        wrapper:
          'overflow-auto scroll-smooth md:overscroll-x-none lg:scrollbar:w-0 lg:scrollbar:h-0',
      },
    },
    columnCanSort: {
      true: {
        theadTh: 'cursor-pointer select-none max-w-full',
      },
    },
    hideResize: {
      true: {
        theadTh:
          'before:hidden after:hidden !bg-transparent !pointer-events-none',
      },
    },

    isPlaceholder: {
      false: undefined,
    },
    isPinned: {
      true: {
        theadTh: 'sticky z-[1]',
        tbodyTd: 'sticky z-[1]',
      },
      false: {
        theadTh: 'relative',
        tbodyTd: 'relative',
      },
    },
    isLastLeftPinnedColumn: {
      true: {
        tbodyTd:
          "before:pointer-events-none before:absolute before:inset-0 before:border-r before:border-gray-750/20 before:shadow-[5px_0px_5px_0px_#00000024] before:content-['']",
      },
    },
    isFirstRightPinnedColumn: {
      true: {
        tbodyTd:
          "before:pointer-events-none before:absolute before:inset-0 before:border-l before:border-gray-750/20 before:shadow-[-5px_0px_5px_0px_#00000024] before:content-['']",
      },
    },
  },
  compoundVariants: [
    {
      hoverable: true,
      stripedRows: true,
      class: {
        tbodyTr: 'hover:bg-gray-750',
      },
    },
    {
      showGridlines: true,
      isPlaceholder: false,
      class: {
        theadTh: 'border-y border-r border-gray-750 first:border-l',
      },
    },
  ],
  defaultVariants: {
    hoverable: true,
    clickable: false,
    stripedRows: false,
    showGridlines: false,
    padding: 'md',
    rounded: false,
    sticky: false,
    hasScroller: true,
  },
})
