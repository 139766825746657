import { createSection } from '@formkit/inputs'
import Panel from '../inputs/Panel.vue'

export const panel = createSection('panel', () => ({
  if: '$node.context.fieldConfig.panelComponent',
  $cmp: markRaw(Panel) as unknown as string,
  bind: '$attrs',
  props: {
    context: '$node.context',
  },
}))
