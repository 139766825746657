import type { Library } from '@googlemaps/js-api-loader'
import { useGoogleMapsLoader } from './loader'

export interface LibraryCache {
  core: google.maps.CoreLibrary
  maps: google.maps.MapsLibrary
  places: google.maps.PlacesLibrary
  geocoding: google.maps.GeocodingLibrary
  routes: google.maps.RoutesLibrary
  marker: google.maps.MarkerLibrary
  geometry: google.maps.GeometryLibrary
  elevation: google.maps.ElevationLibrary
  streetView: google.maps.StreetViewLibrary
  journeySharing: google.maps.JourneySharingLibrary
  drawing: google.maps.DrawingLibrary
  visualization: google.maps.VisualizationLibrary
}

const libraryCache: Map<string, unknown> = new Map()

export const useGoogleMapsImportLibrary = <T extends Library>(library: T) => {
  const { loader } = useGoogleMapsLoader()
  const lib = ref<LibraryCache[T] | undefined>()

  if (libraryCache.has(library)) {
    lib.value = libraryCache.get(library) as any
  } else {
    loader.value.importLibrary(library).then((libraryInstance) => {
      libraryCache.set(library, libraryInstance)
      lib.value = libraryInstance as any
    })
  }

  return lib
}
