<script lang="ts" setup>
import { ModalsContainer } from 'vue-final-modal'
const route = useRoute()

const { isLocal, isStaging } = useEnv()
const prefix = computed(() =>
  isStaging.value ? '[STG]: ' : isLocal.value ? '[DEV]: ' : '',
)

const title = computed<string | undefined>(() => {
  return route.meta.title
    ? `${prefix.value}${route.meta.title} - Accurait`
    : `${prefix.value}Accurait`
})

useHead({
  title: 'Accurait',
  titleTemplate: (pageName: string | undefined): string => {
    return pageName
      ? `${prefix.value}${pageName} - Accurait`
      : `${prefix.value}Accurait`
  },
})

const colorMode = useColorMode()
colorMode.preference = 'dark'
</script>

<template>
  <Html lang="en-AU">
    <Head>
      <Meta http-equiv="content-type" content="text/html;charset=utf-8" />
      <Meta charset="utf-8" />
      <Meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, viewport-fit=cover"
      />
      <Link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <Link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <Link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffffff" />
      <Meta name="apple-mobile-web-app-title" content="Accurait" />
      <Meta name="application-name" content="Accurait" />
      <Meta name="msapplication-TileColor" content="#dff974" />
      <Meta name="theme-color" content="#DFF974" />
      <Meta name="mobile-web-app-capable" content="yes" />
      <Meta name="apple-mobile-web-app-capable" content="yes" />
      <Meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <Link
        rel="apple-touch-startup-image"
        media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        href="/splash_screens/iPhone_14_Pro_Max_landscape.png"
      />

      <Link
        rel="icon"
        type="image/png"
        sizes="196x196"
        href="/splash_screens/favicon-196.png"
      />

      <Link rel="apple-touch-icon" href="/splash_screens/apple-icon-180.png" />
    </Head>
    <Body class="">
      <NuxtLayout class="min-h-screen">
        <NuxtLoadingIndicator :height="4" :duration="3000" :throttle="400" />

        <NuxtPage />
      </NuxtLayout>
      <ModalsContainer />
    </Body>
  </Html>
</template>
