import type { PaginationState, Updater } from '@tanstack/vue-table'
import type { TanstackTableEmits, TanstackTablePropsWithDefaults } from '..'

export const usePagination = (
  props: TanstackTablePropsWithDefaults,
  emit: TanstackTableEmits,
) => {
  const pagination = ref<PaginationState>({ pageIndex: 0, pageSize: 25 })

  watchEffect(() => {
    if (props.pagination) {
      pagination.value = {
        pageIndex: props.pagination.page - 1,
        pageSize: props.pagination.pageSize,
      }
    }
  })

  const setPagination = (updaterOrValue: Updater<PaginationState>) => {
    pagination.value =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(pagination.value)
        : updaterOrValue

    emit('update:pagination', {
      page: pagination.value.pageIndex + 1,
      pageSize: pagination.value.pageSize,
    })
  }

  return {
    pagination,
    setPagination,
  }
}
