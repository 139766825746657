import type { Ref } from 'vue'
import { nanoid } from 'nanoid'
import { storeToRefs } from 'pinia'
import { useWebViewerStore, useFieldSelectionStore } from '@register'

export interface FieldSelectionState {
  id: string | undefined
  documentId: string | undefined
  bounds: BoundBox[] | undefined
  snippet: string | undefined
  page: number | undefined
}

export interface BoundBox {
  top?: number
  left?: number
  width?: number
  height?: number
  page?: number
}

export function useAnnotations() {
  const fieldSelectionStore = useFieldSelectionStore()
  const { webViewerInstance, pdfReady, currentDocumentId } =
    storeToRefs(useWebViewerStore())

  const remove = () => {
    if (!webViewerInstance.value) return
    removeAllAnnotations()
    fieldSelectionStore.fieldSelectionState.id = undefined
    fieldSelectionStore.fieldSelectionState.documentId = undefined
    fieldSelectionStore.fieldSelectionState.snippet = undefined
    fieldSelectionStore.fieldSelectionState.bounds = undefined
  }

  const add = (documentId: string, bounds: BoundBox[]) => {
    if (!webViewerInstance.value || !documentId || !bounds) return

    remove()

    const id = nanoid()

    // Otherwise, add annotations
    fieldSelectionStore.fieldSelectionState.id = id
    fieldSelectionStore.fieldSelectionState.documentId = documentId
    fieldSelectionStore.fieldSelectionState.bounds = bounds

    // If document is not loaded yet, save bounds for later
    if (!pdfReady.value) return

    if (documentId !== currentDocumentId.value) return

    const { setFitMode, FitMode, getZoomLevel } = webViewerInstance.value.UI

    if (getZoomLevel() > 2) setFitMode(FitMode.FitWidth)

    generateSnippetScreenshot(bounds)

    // If the bound only have page, we scroll to the page.
    if (
      bounds.length === 1 &&
      !bounds[0].top &&
      !bounds[0].left &&
      !bounds[0].width &&
      !bounds[0].height &&
      bounds[0].page !== undefined
    ) {
      const { documentViewer } = webViewerInstance.value.Core
      documentViewer.setCurrentPage(bounds[0].page, true)
      return
    }

    // Add annotations with flicker effect
    bounds.forEach((boundBox) => {
      addAnnotation(boundBox)
      setTimeout(() => {
        if (fieldSelectionStore.fieldSelectionState.id !== id) return
        return removeAllAnnotations()
      }, 300)
      setTimeout(() => {
        if (fieldSelectionStore.fieldSelectionState.id !== id) return
        return addAnnotation(boundBox)
      }, 400)
      setTimeout(() => {
        if (fieldSelectionStore.fieldSelectionState.id !== id) return
        return removeAllAnnotations()
      }, 500)
      setTimeout(() => {
        if (fieldSelectionStore.fieldSelectionState.id !== id) return
        return addAnnotation(boundBox)
      }, 600)
    })

    scrollToPosition()
  }

  return {
    state: fieldSelectionStore.fieldSelectionState,
    add,
    remove,
  }

  function scrollToPosition() {
    if (!webViewerInstance.value) return
    const { annotationManager } = webViewerInstance.value.Core
    const [annot] = annotationManager.getAnnotationsList()

    if (annot) {
      annotationManager.jumpToAnnotation(annot, {
        isSmoothScroll: true,
        verticalOffset: '15%',
      })
    }
  }

  async function generateSnippetScreenshot(bounds?: BoundBox[]) {
    if (!webViewerInstance.value) return
    const { documentViewer } = webViewerInstance.value.Core
    fieldSelectionStore.fieldSelectionState.snippet = undefined
    if (!bounds?.length) return

    const firstBounds = bounds?.[0] ?? undefined

    if (!firstBounds || !firstBounds.top) return

    const pageW = documentViewer.getPageWidth(firstBounds.page || 0)
    const pageH = documentViewer.getPageHeight(firstBounds.page || 0)

    const x1 = 0
    const y1 = Math.max(0, firstBounds.top * pageH - 30)
    const x2 = pageW
    const y2 = y1 + 100

    documentViewer.getDocument().loadCanvas({
      pageNumber: firstBounds.page || 0,
      useProgress: true,
      renderRect: { x1, y1, x2, y2 },
      drawComplete: (canvas) => {
        fieldSelectionStore.fieldSelectionState.snippet =
          canvas.toDataURL('image/jpeg')
      },
    })
  }

  function addAnnotation(boundBox: BoundBox) {
    if (!webViewerInstance.value) return

    fieldSelectionStore.fieldSelectionState.page = boundBox.page
    const { documentViewer, annotationManager, Annotations } =
      webViewerInstance.value.Core

    const padding = 2.8
    const bound = boundBox
    const w = documentViewer.getPageWidth(bound.page ?? 0)
    const h = documentViewer.getPageHeight(bound.page ?? 0)

    if (!bound.left || !bound.top || !bound.width || !bound.height) return

    const rectAnnot = new Annotations.RectangleAnnotation({
      PageNumber: bound.page,
      X: bound.left * w - padding / 2,
      Y: bound.top * h - padding / 2,
      Width: bound.width * w + padding,
      Height: bound.height * h + padding,
      Author: annotationManager.getCurrentUser(),
      StrokeColor: new Annotations.Color(204, 148, 27, 0),
      FillColor: new Annotations.Color(204, 148, 27, 0.5),
    })

    annotationManager.addAnnotation(rectAnnot)
    annotationManager.redrawAnnotation(rectAnnot)
  }

  function removeAllAnnotations() {
    if (!webViewerInstance.value) return
    const { annotationManager } = webViewerInstance.value.Core
    const annots = annotationManager.getAnnotationsList()
    annotationManager.deleteAnnotations(annots)
  }
}

export function useSnipPosition(
  id: Ref<string | undefined>,
  findIndex: (id: string) => number,
  container: Ref<HTMLElement | undefined>,
) {
  const { width } = useElementSize(container)

  /** container width
    @xs	  (min-width: 20rem  320px )
    @sm	  (min-width: 24rem  384px)
    @md	  (min-width: 28rem  448px)
    @lg	  (min-width: 32rem  512px)
    @xl	  (min-width: 36rem  576px)
    @2xl	(min-width: 42rem  672px)
    @3xl	(min-width: 48rem  768px)
    @4xl	(min-width: 56rem  896px)
    @5xl	(min-width: 64rem  1024px)
    @6xl	(min-width: 72rem  1152px)
    @7xl	(min-width: 80rem  1280px)
  */

  const gridColumns = ref(1)

  watch(width, () => {
    if (width.value >= 1152) gridColumns.value = 4
    else if (width.value >= 768) gridColumns.value = 3
    else if (width.value >= 512) gridColumns.value = 2
    else gridColumns.value = 1
  })

  return computed(() => {
    if (!id.value) return
    return calculateRowPosition(id.value)
  })

  function calculateRowPosition(id: string) {
    const index = findIndex(id)
    if (index === -1) return
    const rowIndex = Math.floor(index / gridColumns.value)
    const nextRowIndex = rowIndex + 1
    return nextRowIndex + 1
  }
}
