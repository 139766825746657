import { cva, type VariantProps } from 'class-variance-authority'

// define the props
export type ButtonVariantProps = VariantProps<typeof useButtonClasses>

export const useButtonClasses = cva(
  [
    'focus:outline-none',
    'font-medium',
    'rounded-lg',
    'inline-flex justify-center items-center',
    'transition-all ease-in-out',
    'gap-1',
  ],
  {
    variants: {
      color: {
        primary: [
          'bg-primary hover:bg-primary/90 focus:bg-primary/90 text-black focus:ring focus:ring-gray-550/80',
        ],
        secondary: [
          'border-secondary-750 bg-secondary-800 text-secondary-100 hover:bg-secondary-700 hover:border-secondary focus:bg-secondary-700 focus:border-secondary focus:ring focus:ring-gray-550/80',
        ],
        link: [
          'bg-transparent hover:text-primary-500 hover:underline focus:ring focus:ring-gray-550/80',
        ],
        danger: [
          'bg-danger text-white hover:bg-danger/90 focus:bg-danger/90 border-danger hover:text-red-50 focus:text-red-50 focus:ring focus:ring-gray-550/80',
        ],
        warning: [
          'bg-warning text-black hover:bg-warning/90 focus:bg-warning/90 border-warning focus:ring focus:ring-gray-550/80',
        ],
        transparentPrimary: [
          'bg-transparent text-primary hover:bg-primary hover:text-black focus:ring focus:ring-gray-550/80',
        ],
        transparentSecondary: [
          'bg-transparent text-white hover:bg-secondary-750 hover:text-white focus:bg-secondary-750 focus:text-white focus:ring focus:ring-gray-550/80',
        ],
        transparentDanger: [
          'bg-transparent text-danger border-danger hover:bg-danger hover:text-black hover:text-red-100 focus:bg-danger focus:text-black focus:text-red-100 focus:ring focus:ring-gray-550/80',
        ],
        transparentWarning: [
          'bg-transparent text-warning border-warning hover:bg-warning hover:text-black focus:bg-warning focus:text-black focus:ring focus:ring-gray-550/80',
        ],

        // border border-primary/20 bg-primary/5 text-primary
        opacityPrimary: [
          'bg-primary/5 border border-primary/20 text-primary hover:bg-primary/20 hover:border-primary focus:bg-primary/20 focus:border-primary focus:ring focus:ring-gray-550/80',
        ],
        opacitySecondary: [
          'bg-secondary/5 border border-secondary/20 text-white hover:bg-secondary/20 focus:bg-secondary/20 focus:ring focus:ring-gray-550/80',
        ],
        opacityDanger: [
          'bg-danger/5 border border-danger/20 text-danger hover:bg-danger/20 hover:border-danger focus:bg-danger/20 focus:border-danger focus:ring focus:ring-gray-550/80',
        ],
        opacityWarning: [
          'bg-warning/5 border border-warning/20 text-warning hover:bg-warning/20 hover:border-warning focus:bg-warning/20 focus:border-warning focus:ring focus:ring-gray-550/80',
        ],
      },
      size: {
        sm: ['text-xs', 'px-3', 'py-1.5'],
        md: ['text-sm', 'px-5', 'py-2.5'],
        lg: ['text-base', 'px-6', 'py-3'],
      },
      shape: {
        pill: ['rounded-full'],
      },
      ring: {
        false: ['focus:ring-0'],
        true: ['focus:ring-4'],
      },

      ringOnHover: {
        false: [],
        true: ['ring-4 ring-transparent'],
      },
    },

    compoundVariants: [
      //ring on focus
      {
        color: 'primary',
        ringOnHover: true,
        class: 'focus:ring-primary/20',
      },
      {
        color: 'secondary',
        ringOnHover: true,
        class: 'focus:ring-primary/20',
      },
      {
        color: 'link',
        ringOnHover: true,
        class: 'focus:ring-0',
      },
      {
        color: 'transparentPrimary',
        ringOnHover: true,
        class: 'focus:ring-0',
      },
      {
        color: 'transparentSecondary',
        ringOnHover: true,
        class: 'focus:ring-0',
      },
      {
        color: 'transparentDanger',
        ringOnHover: true,
        class: 'focus:ring-0',
      },
      {
        color: 'transparentWarning',
        ringOnHover: true,
        class: 'focus:ring-0',
      },
      {
        color: 'danger',
        ringOnHover: true,
        class: 'focus:ring-danger/20',
      },
      {
        color: 'warning',
        ringOnHover: true,
        class: 'focus:ring-warning/20',
      },
      //ring on hover
      {
        color: 'primary',
        ring: true,
        class: 'hover:ring-primary/30',
      },
      {
        color: 'secondary',
        ring: true,
        class: 'hover:ring-secondary-750/30',
      },
      {
        color: 'link',
        ring: true,
        class: 'hover:ring-0',
      },
      {
        color: 'transparentPrimary',
        ring: true,
        class: 'hover:ring-0',
      },
      {
        color: 'transparentSecondary',
        ring: true,
        class: 'hover:ring-0',
      },
      {
        color: 'transparentDanger',
        ring: true,
        class: 'hover:ring-0',
      },
      {
        color: 'transparentWarning',
        ring: true,
        class: 'hover:ring-0',
      },
      {
        color: 'danger',
        ring: true,
        class: 'hover:ring-danger/30',
      },
      {
        color: 'warning',
        ring: true,
        class: 'hover:ring-warning/30',
      },
    ],
    defaultVariants: {
      size: 'md',
      ring: false,
      color: 'primary',
      ringOnHover: false,
    },
  },
)
