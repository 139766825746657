import type { Address as _GoogleAddress } from '~/types/googleAddress'
import type { FieldConfigFactory } from '../../types'
import { isValidValue } from '../../utils'
import GoogleMapsField from '../../inputs/GoogleMapsField/GoogleMapsField.vue'
import { getNode } from '@formkit/core'

export const googleMapsFactory: FieldConfigFactory = (node) => {
  const fieldAddressNode = getNode(node.context.field.id.replace('Map', ''))
  node.context.fieldAddressNode = fieldAddressNode

  return {
    schema: 'maps',
    component: markRaw(GoogleMapsField),
    actions: false,
    type: 'googleMaps',
    toFormatted: (value: any): string | undefined => {
      return fieldAddressNode?.context.fieldConfig.toFormatted(
        fieldAddressNode.context._value.value,
      )
    },
    toAPI: (value: any): any => {
      return fieldAddressNode?.context.fieldConfig.toAPI(
        fieldAddressNode.context._value.value,
      )
    },
    toInput: (value: any): any => {
      if (!isValidValue(value)) return
      return fieldAddressNode?.context.fieldConfig.toInput(
        fieldAddressNode.context._value.value,
      )
    },
    fromDocument: (value: string): any => {
      if (!isValidValue(value)) return
      return value
    },
  }
}
