import { empty } from '@formkit/utils'
import type {
  FormKitValidationMessage,
  FormKitValidationRule,
} from '@formkit/validation'
import { sentence as s } from '@formkit/i18n'
import type { LeaseDocumentValue } from '../types'

/**
 * Determine if the given input's value was required.
 * @param context - The FormKitValidationContext
 * @public
 */
export const rule: FormKitValidationRule = function required(
  node,
  action = 'default',
) {
  const { value } = (node._value ?? {}) as LeaseDocumentValue
  return action === 'trim' && typeof value === 'string'
    ? !empty(value.trim())
    : !empty(value)
}

/**
 * This rules should run even if the inputs is empty (obviously)
 */
rule.skipEmpty = false

export const message: FormKitValidationMessage = ({ name }) => {
  return `${s(name)} is required.`
}
