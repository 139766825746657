import type { FieldConfigFactory } from '../../types'
import { stringFactory } from '../string'
import { addressFactory } from '../address'
import { googleMapsFactory } from '../googleMaps'

export const FactoryMap = {
  DEFAULT: stringFactory,
  AddressModel: addressFactory,
  GoogleMapsModel: googleMapsFactory,
}

export type FactoryMapKey = keyof typeof FactoryMap

export const objectFactory: FieldConfigFactory = (node) => {
  const typeDefinition = node.context.field.typeDefinition
  const factory =
    FactoryMap[(typeDefinition?.name ?? 'DEFAULT') as FactoryMapKey]

  return factory(node)
}
