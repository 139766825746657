import type { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  type FormKitInputs,
  textInput,
} from '@formkit/inputs'
import { input } from './sections/input'
import type { VueDatePickerProps } from '@ui/components/Form/Datepicker'

declare module '@formkit/inputs' {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    datepicker: VueDatePickerProps
  }
}

/**
 * Input definition for a select.
 * @public
 */
export const datepicker: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    wrapper(
      label('$label'),
      inner(icon('prefix'), prefix(), input(), suffix(), icon('suffix')),
    ),
    help('$help'),
    messages(message('$message.value')),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'text',
  /**
   * An array of extra props to accept for this input.
   */
  props: [
    'multiCalendars',
    'modelValue',
    'modelType',
    'position',
    'dark',
    'format',
    'autoPosition',
    'altPosition',
    'transitions',
    'formatLocale',
    'utc',
    'ariaLabels',
    'offset',
    'hideNavigation',
    'timezone',
    'vertical',
    'disableMonthYearSelect',
    'disableYearSelect',
    'dayClass',
    'yearRange',
    'enableTimePicker',
    'autoApply',
    'disabledDates',
    'monthNameFormat',
    'startDate',
    'startTime',
    'hideOffsetDates',
    'noToday',
    'disabledWeekDays',
    'allowedDates',
    'nowButtonLabel',
    'markers',
    'escClose',
    'spaceConfirm',
    'monthChangeOnArrows',
    'presetDates',
    'flow',
    'partialFlow',
    'preventMinMaxNavigation',
    'reverseYears',
    'weekPicker',
    'filters',
    'arrowNavigation',
    'highlight',
    'teleport',
    'teleportCenter',
    'locale',
    'weekNumName',
    'weekStart',
    'weekNumbers',
    'monthChangeOnScroll',
    'dayNames',
    'monthPicker',
    'customProps',
    'yearPicker',
    'modelAuto',
    'selectText',
    'cancelText',
    'previewFormat',
    'multiDates',
    'ignoreTimeValidation',
    'minDate',
    'maxDate',
    'minTime',
    'maxTime',
    'name',
    'placeholder',
    'hideInputIcon',
    'clearable',
    'required',
    'autocomplete',
    'timePicker',
    'enableSeconds',
    'is24',
    'noHoursOverlay',
    'noMinutesOverlay',
    'noSecondsOverlay',
    'hoursGridIncrement',
    'minutesGridIncrement',
    'secondsGridIncrement',
    'hoursIncrement',
    'minutesIncrement',
    'secondsIncrement',
    'range',
    'uid',
    'disabled',
    'readonly',
    'inline',
    'textInput',
    'sixWeeks',
    'actionRow',
    'focusStartDate',
    'disabledTimes',
    'timePickerInline',
    'calendar',
    'config',
    'quarterPicker',
    'yearFirst',
    'loading',
    'onInternalModelChange',
    'enableMinutes',
    'ui',
  ],
}
