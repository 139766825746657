import type { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  type FormKitInputs,
} from '@formkit/inputs'
import { selectInput } from './sections/selectInput'
import type { ListboxProps } from '@ui/components/Form/Listbox'

declare module '@formkit/inputs' {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    listbox: ListboxProps & {
      type: 'listbox'
      value?: any
    }
  }
}

/**
 * Input definition for a select.
 * @public
 */
export const listbox: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    wrapper(
      label('$label'),
      inner(icon('prefix'), prefix(), selectInput(), suffix(), icon('suffix')),
    ),
    help('$help'),
    messages(message('$message.value')),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * An array of extra props to accept for this input.
   */
  props: [
    'options',
    'labelBy',
    'trackBy',
    'format',
    'searchable',
    'multiple',
    'taggable',
    'placeholder',
    'emptyMessage',
    'hideDisplayValue',
    'internalSearch',
    'loading',
    'customLabel',
    'max',
    'allowEmpty',
    'closeOnSelect',
  ],
}
