<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: number
    min?: number
    max?: number
  }>(),
  {
    label: undefined,
    modelValue: 0,
    min: 0,
    max: undefined,
  },
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>()

const modelValue = useVModel(props, 'modelValue', emit, {
  defaultValue: 0,
  passive: true,
  clone: (v) => (v ? Number(v) : 0),
})
const { count, inc, dec } = useCounter(modelValue, {
  min: props.min,
  max: props.max,
})
</script>

<template>
  <div class="flex items-center gap-2">
    <button
      :class="['transition-colors duration-100', count === min && 'opacity-50']"
      @click.prevent="dec()"
    >
      <Icon name="chevron-left" />
    </button>
    <div class="text-primary">{{ count }}</div>
    <button
      :class="['transition-colors duration-100', count === max && 'opacity-50']"
      @click.prevent="inc()"
    >
      <Icon name="chevron-right" />
    </button>
  </div>
</template>
