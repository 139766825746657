import {
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  $if,
} from '@formkit/inputs'
import { outer, field, inner, input, label, panel, wrapper } from '../sections'
import {
  actions,
  actionsWrapper,
  addNote,
  confidenceScore,
  copyFromDocument,
  backlinkingOnly,
  copyToClipboard,
  lock,
  redo,
  sideActions,
  undo,
  toggleTable,
  toggleAddress,
  deleteAction,
} from '../actions'

export const fieldSchema = outer(
  wrapper(
    label('$label || $field.name'),
    field(
      inner(
        $if('$canApprove', confidenceScore()),
        icon('prefix', 'label'),
        prefix(),
        input(),
        panel(),
        suffix(),
        icon('suffix'),
      ),
      $if(
        '$sideActions === true',
        sideActions(
          $if('$canApprove', lock()),
          $if('$canDelete', deleteAction()),
          $if('$isTable', toggleTable()),
          $if('$isAddress && $hideMapButton === false', toggleAddress()),
        ),
      ),
    ),
  ),
  $if(
    '$actions === true',
    actionsWrapper(
      actions(
        undo(),
        redo(),
        copyFromDocument(),
        backlinkingOnly(),
        copyToClipboard(),
        addNote(),
      ),
    ),
  ),
  help('$help'),
  messages(message('$message.value')),
)
