<script setup lang="ts">
import type { LeaseDocumentInputContext } from '../types'

interface Props {
  context: LeaseDocumentInputContext
  modelValue?: string
  isMultiline?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  isMultiline: false,
})

const emit = defineEmits(['update:modelValue'])
const innerValue = useVModel(props, 'modelValue', emit)

const onKeydownEnter = (e: Event) => {
  if (!props.isMultiline) {
    e.preventDefault()
  }
}
</script>

<template>
  <textarea
    v-model="innerValue"
    :aria-label="context.label"
    @keydown.enter="onKeydownEnter"
  />
</template>
