<script setup lang="ts">
import type { NuxtError } from '#app'
import { ModalsContainer } from 'vue-final-modal'
import type { IconName } from '#build/icons'

const props = defineProps<{ error: NuxtError }>()

const handleError = () => clearError({ redirect: '/' })

const MESSAGES: Record<number, string> = {
  404: "Sorry, the page you are looking for doesn't exist",
  403: "You don't have permissions to access this page",
  500: `Our team has been notified, and we're working hard to fix it.
  Please try again later.
  We apologize for any inconvenience this may have caused.`,
}

const message = computed(() => {
  if (props.error.statusCode && props.error.statusCode in MESSAGES) {
    return MESSAGES[props.error.statusCode]
  }

  return undefined
})

const ICONS: Record<number, IconName> = {
  404: 'empty-folder',
  500: 'crash',
}

const icon = computed(() => {
  if (props.error.statusCode && props.error.statusCode in ICONS) {
    return ICONS[props.error.statusCode]
  }

  return undefined
})
</script>

<template>
  <div class="flex w-full">
    <ClientOnly>
      <TheNavigation class="fixed left-0 right-0 z-10" />
    </ClientOnly>

    <div class="container mx-auto mt-32">
      <PageError
        :error="error"
        :icon="icon"
        :message="message"
        :clear-button="{ label: 'Back to home' }"
        @clear="handleError()"
      />
    </div>

    <ModalsContainer />
  </div>
</template>
