import type { FieldConfigFactory } from '../../types'
import { isValidValue } from '../../utils'
import Textarea from '../../inputs/Textarea.vue'
import Select from '../../inputs/Select.vue'
import Listbox from '../../inputs/Listbox.vue'

export const enumFactory: FieldConfigFactory = (node) => {
  const enumValues = node.context.field.enumInfo?.values
  const forceType = node.context.field.forceType ?? false

  return {
    component: !forceType ? markRaw(Textarea) : markRaw(Listbox),
    panelComponent: !forceType ? markRaw(Select) : undefined,
    type: 'select',
    options: getOptions(),
    toFormatted: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      if (isEnumKey(value)) return getEnumValue(value)
      return String(value)
    },
    toAPI: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      if (isEnumValue(value)) return getEnumKey(value)
      return String(value)
    },
    toInput: (value: any): string | undefined => {
      // If `forceType` is true, the Listbox input will be displayed instead of the Textarea,
      // so we want to return the enum key (which is the value)
      if (forceType) return toListboxValue(value)

      if (!isValidValue(value)) return
      // If `forceType` is false, the Textarea will be displayed, so we want to return the enum value (which is the label)
      if (isEnumKey(value)) return getEnumValue(value)
      return String(value)
    },
    toPanel: toListboxValue,
    fromDocument: (value: any): string | undefined => {
      if (!isValidValue(value)) return
      return String(value)
    },
  }

  function getOptions() {
    if (!enumValues) return []
    return Object.entries(enumValues).map(([value, label]) => ({
      label,
      value,
    }))
  }

  function isEnumKey(key: any) {
    if (!enumValues) return false
    return key in enumValues
  }

  function getEnumValue(key: any) {
    if (!enumValues) return
    return enumValues[key]
  }

  function isEnumValue(value: any) {
    if (!enumValues) return false
    return Object.values(enumValues).includes(value)
  }

  function getEnumKey(value: any) {
    if (!enumValues) return
    return Object.keys(enumValues).find((key) => enumValues[key] === value)
  }

  function toListboxValue(value: any): string | undefined {
    if (!isValidValue(value)) return
    if (isEnumValue(value)) return getEnumKey(value)
    return String(value)
  }
}
