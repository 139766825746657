import type { ColumnDef, ColumnDefResolved } from '@tanstack/vue-table'
import { resolveColumnId } from '@ui/components/TanstackTable/utils'
import { createColumnHeader } from '@ui/components/TanstackTable/columns/utils'
import Skeleton from '@flnt-accurait/web-components/components/Skeleton/Skeleton.vue'

export const createSkeletonColumn = (
  column: ColumnDef<any, any>,
  slots: ReturnType<typeof useSlots>,
): ColumnDef<any> => {
  return {
    ...column,
    id: resolveColumnId(column as ColumnDefResolved<any, any>)!,
    header: (props) => createColumnHeader(column, slots, props),
    cell: () => <Skeleton height={'22px'} width={'100%'} />,
  }
}
