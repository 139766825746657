import {
  LeaseFieldType,
  LeaseFieldDataType,
  type ILeaseDocumentField,
} from '@register'
import type { VariationFieldConfigFactory } from './types'

export const variationFactory: VariationFieldConfigFactory = ({
  leaseFieldId,
  fields,
  allFields,
}) => {
  return {
    key: 'variation',
    leaseField: {
      label: 'Field',
      options: getOptions(),
    },
    value: {
      show: true,
      type: 'text',
      field: getField(),
    },
  }

  function getOptions() {
    return fields.map((field) => ({
      label: field.name,
      value: field.id,
    }))
  }

  function getField() {
    if (!leaseFieldId) return

    // FIELD
    const field = allFields.find((field) => field.id === leaseFieldId)

    // if (field)
    return field

    // console.log('field not found')
    // // DEFAULT FIELD
    // return {
    //   id: leaseFieldId!,
    //   name: leaseFieldId!,
    //   fieldType: LeaseFieldType.FIELD,
    //   dataType: LeaseFieldDataType.ARRAY,
    //   description: '',
    // }
  }
}
