<script setup lang="ts">
import {
  FlexRender,
  getCoreRowModel,
  getSortedRowModel,
  type SortDirection,
  useVueTable,
} from '@tanstack/vue-table'
import {
  useInitializeColumns,
  usePagination,
  useRowSelection,
  useSort,
  useVisibility,
  useStickyHeader,
  usePinning,
  useSyncHorizontalScrollbar,
  useInnerTableVariants,
} from './composables'
import type { TanstackTableEmits, TanstackTableProps } from './types'
import Pagination from './Pagination.vue'
import {
  breakpointsTailwind,
  useElementSize,
  useWindowSize,
} from '@vueuse/core'

// PROPS
const props = withDefaults(defineProps<TanstackTableProps>(), {
  // BASE
  columns: () => [],
  data: () => [],
  loading: false,
  fetching: false,
  fetchingMessage: 'Updating...',
  // PAGINATION
  pagination: undefined,
  totalRecords: 0,
  rowsPerPageOptions: () => [25, 50, 100],
  // SELECTION
  selection: undefined,
  // SORT
  sortable: false,
  sort: () => [],
  // STYLE
  hoverable: true,
  clickable: false,
  stripedRows: false,
  showGridlines: false,
  padding: 'md',
  rounded: false,
  sticky: false,
  variant: undefined,
})

// EMIT
const emit = defineEmits<TanstackTableEmits>()

// REFS
const wrapperRef = ref<HTMLElement | null>(null)
const tableRef = ref<HTMLElement | null>(null)
const theadRef = ref<HTMLElement | null>(null)
const virtualHorizontalScrollbarRef = ref<HTMLElement | null>(null)

// COLUMNS
const columns = useInitializeColumns(toRefs(props))

// ROW SELECTION
const { selection, setSelection } = useRowSelection(props, emit)

// PAGINATION
const { pagination, setPagination } = usePagination(props, emit)

// SORTING
const { sort, setSort } = useSort(props, emit)

// VISIBILITY
const { columnVisibility } = useVisibility(props, emit)

// PINNING
const { columnPinning, getCommonPinningStyles, getCommonPinningVariants } =
  usePinning(props, wrapperRef)

// STICKY HEADER
const { isSticky, hasScroller, getOffsetHeader } = useStickyHeader(
  props,
  tableRef,
  theadRef,
)

// VIRTUAL HORIZONTAL SCROLLBAR
const breakpoints = useBreakpoints(breakpointsTailwind)
const greaterOrEqualLg = breakpoints.greaterOrEqual('lg')
const { width: windowWidth } = useWindowSize()
const tableSize = useElementSize(tableRef)
const showVirtualScrollbar = computed(
  () =>
    hasScroller.value &&
    tableSize.width.value > windowWidth.value &&
    greaterOrEqualLg.value,
)
useSyncHorizontalScrollbar(
  wrapperRef,
  virtualHorizontalScrollbarRef,
  hasScroller,
)

// COMPUTED
const data = computed(() => (props.loading ? Array(8).fill({}) : props.data))
const _pagination = computed(() =>
  props.pagination ? pagination.value : undefined,
)
const _pageCount = computed(() =>
  Math.ceil(props.totalRecords / pagination.value.pageSize),
)
const _sortable = computed(() => !!props.sortable)
const _manualSorting = computed(() => props.sortable === 'manual')

// TABLE
const table = useVueTable({
  data,
  get columns() {
    return columns.value
  },
  state: {
    get rowSelection() {
      return selection.value
    },
    get pagination() {
      return _pagination.value
    },
    get sorting() {
      return sort.value
    },
    get columnPinning() {
      return columnPinning.value
    },
  },
  initialState: {
    get columnVisibility() {
      return columnVisibility.value
    },
  },
  // Row selection
  enableRowSelection: true,
  onRowSelectionChange: setSelection,
  getCoreRowModel: getCoreRowModel(),
  // Pagination
  get pageCount() {
    return _pageCount.value
  },
  manualPagination: true,
  onPaginationChange: setPagination,
  // Sorting
  get enableSorting() {
    return _sortable.value
  },
  sortDescFirst: false,
  getSortedRowModel: getSortedRowModel(),
  onSortingChange: setSort,
  get manualSorting() {
    return _manualSorting.value
  },
  // Visibility
  enableHiding: true,
})

const onClickRow = (value: any) => {
  if (props.clickable) {
    emit('click:row', value)
  }
}

const classes = useInnerTableVariants(props, {
  get hoverable() {
    return props.hoverable
  },
  get clickable() {
    return props.clickable
  },
  get stripedRows() {
    return props.stripedRows
  },
  get showGridlines() {
    return props.showGridlines
  },
  get padding() {
    return props.padding
  },
  get rounded() {
    return props.rounded
  },
  get sticky() {
    return isSticky.value
  },
  get hasScroller() {
    return hasScroller.value
  },
})
</script>

<template>
  <div :class="classes.base()">
    <div ref="wrapperRef" :class="classes.wrapper()">
      <div v-if="!loading && fetching">
        <slot name="fetching">
          <div class="p-4">
            <span class="text-xs text-gray-500">
              <Spinner class="fill-primary" size="sm" />
              {{ fetchingMessage }}
            </span>
          </div>
        </slot>
      </div>
      <div v-if="$slots.empty && !loading && data.length === 0">
        <slot name="empty" />
      </div>
      <template v-else>
        <table ref="tableRef" :class="classes.table()">
          <thead
            ref="theadRef"
            :class="classes.thead()"
            :style="{
              top: getOffsetHeader(),
            }"
          >
            <tr
              v-for="headerGroup in table.getHeaderGroups()"
              :key="headerGroup.id"
              :class="classes.theadTr(classTheadTr?.(row))"
            >
              <th
                v-for="header in headerGroup.headers"
                :key="header.id"
                :colSpan="header.colSpan"
                :class="
                  classes.theadTh({
                    columnCanSort: header.column.getCanSort(),
                    isPlaceholder: header.isPlaceholder,
                    ...getCommonPinningVariants(header.column),
                    ...(classTheadTh?.(header) ?? {}),
                  })
                "
                :style="{ ...getCommonPinningStyles(header.column) }"
                @click="header.column.getToggleSortingHandler()?.($event)"
              >
                <template v-if="!header.isPlaceholder">
                  <span :class="classes.theadThInner()">
                    <FlexRender
                      :render="header.column.columnDef.header"
                      :props="header.getContext()"
                    />
                    <!-- Sort Button -->
                    <Icon
                      v-if="header.column.getIsSorted()"
                      :class="classes.sortButton()"
                      :name="
                        (header.column.getIsSorted() as SortDirection) === 'asc'
                          ? 'chevron-up'
                          : 'chevron-down'
                      "
                    />
                  </span>
                </template>
              </th>
            </tr>
          </thead>
          <tbody :class="classes.tbody()">
            <tr
              v-for="row in table.getRowModel().rows"
              :key="row.id"
              :class="classes.tbodyTr(classTbodyTr?.(row))"
              @click="onClickRow(row.original)"
            >
              <td
                v-for="cell in row.getVisibleCells()"
                :key="cell.id"
                :class="
                  classes.tbodyTd({
                    ...getCommonPinningVariants(cell.column),
                    ...(classTbodyTd?.(cell) ?? {}),
                  })
                "
                :style="{ ...getCommonPinningStyles(cell.column) }"
              >
                <FlexRender
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          v-if="props.pagination"
          :loading="loading"
          :rows-per-page-options="rowsPerPageOptions"
          :pagination="table.getState().pagination"
          :total-records="totalRecords"
          :can-next-page="table.getCanNextPage()"
          :can-previous-page="table.getCanPreviousPage()"
          @previous-page="() => table.previousPage()"
          @next-page="() => table.nextPage()"
          @page-size="(value: number) => table.setPageSize(value)"
        />
      </template>
      <slot name="footer" />
    </div>

    <!-- This is a virtual scroll to see the horizontal scroll bar where the table height is bigger than the window and the scrollbar is way down -->
    <div
      v-if="showVirtualScrollbar"
      class="pb-safe-bottom sticky bottom-0 z-[950] hidden h-4 w-full bg-gray-600 lg:flex"
      :class="[props.pagination && 'md:mt-12']"
    >
      <div
        ref="virtualHorizontalScrollbarRef"
        class="overflow-x-auto overscroll-contain overscroll-y-none"
      >
        <div
          class="h-[0.5px]"
          :style="{
            paddingRight: tableSize.width.value + 'px',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>
