<script setup lang="ts">
import ActionButton from '../ActionButton.vue'

import type { LeaseDocumentInputContext } from '../../types'

const props = defineProps<{ context: LeaseDocumentInputContext }>()

const disabled = computed(
  () => !props.context.node.canUndo || props.context.isApproved,
)
const tooltip = computed(() => {
  if (props.context.isApproved) return 'Unable to modify approved field'
  return disabled.value ? 'Nothing to undo' : 'Undo this field'
})
const onClick = async () => {
  if (disabled.value) return
  await props.context.node.undo()
  // Emit
  props.context.handlers.undo(props.context.node)

  useAnalytics({
    name: 'Lease Field Toolbar Actions',
    data: {
      source: 'Undo',
      'Is Edited': props.context.isEdited,
      'Field ID': props.context.id,
      'Field Value': props.context._value.value || props.context.value,
    },
  })
}
</script>

<template>
  <ActionButton
    :tooltip="tooltip"
    icon="undo"
    :disabled="disabled"
    @click="onClick"
  />
</template>
