export function localizeFromEnum<T extends object>(
  enumDefinition: T,
  callback: (enumValue: T[keyof T]) => string,
) {
  if (!enumDefinition || !callback) return []

  return Object.keys(enumDefinition)
    .filter((key) => isNaN(Number(key)))
    .map((key) => {
      const value = enumDefinition[key as keyof T]
      return {
        label: callback(value),
        value,
      }
    })
}
