<script setup lang="ts">
import type { LeaseDocumentInputContext } from '../types'

import { useInputValue } from './composables/useInputValue'
import { useAutoSize } from './composables/useAutoSize'
import { twMerge } from 'tailwind-merge'
interface Props {
  context: LeaseDocumentInputContext
}
const props = defineProps<Props>()

const context = toRef(props, 'context')

const { inputValue, approvedValue, placeholderValue } = useInputValue(context)

const inputRef = ref()
useAutoSize(inputRef, context)

const classes = computed(() => {
  if (['date', 'enum', 'boolean'].includes(context.value.field.dataType)) {
    return twMerge(context.value.classes.input, 'pr-6')
  }
  return twMerge(context.value.classes.input)
})
</script>

<template>
  <Tooltip
    v-if="props.context.isApproved"
    placement="top"
    :content="`This field is approved. Click on the checked button for edition`"
    outer-class="flex w-full"
    class="max-[245px] bg-gray-500 text-xs"
  >
    <div :id="context.id" :class="context.classes.approved">
      <span>{{ approvedValue }}</span>
    </div>
  </Tooltip>

  <component
    :is="context.fieldConfig.component"
    v-else
    :id="context.id"
    ref="inputRef"
    v-model="inputValue"
    :context="context"
    :placeholder="placeholderValue ?? undefined"
    :class="classes"
    :name="context.node.name"
    :disabled="context.disabled || context.isLoading"
    :is-multiline="!!context.field.isMultiLine"
    :aria-describedby="context.describedBy"
    data-gramm="false"
    data-gramm_editor="false"
    data-enable-grammarly="false"
    data-1p-ignore
    @blur="context.handlers.blur"
  />
</template>
