import type { ColumnDefResolved, VisibilityState } from '@tanstack/vue-table'
import type { TanstackTableEmits, TanstackTablePropsWithDefaults } from '..'
import { resolveColumnId } from '@ui/components/TanstackTable/utils'

export const useVisibility = (
  props: TanstackTablePropsWithDefaults,
  emit: TanstackTableEmits,
) => {
  const columnVisibility = computed(() => {
    const columns = props.columns as ColumnDefResolved<any, any>[]
    return columns.reduce<VisibilityState>(
      (acc: Record<string, boolean>, item) => {
        const id = resolveColumnId(item)

        const visible = resolveColumnVisibility(item)

        if (id && visible === false) {
          acc[id] = false
        }

        return acc
      },
      {},
    )
  })

  return { columnVisibility }

  function resolveColumnVisibility(item: ColumnDefResolved<any, any>) {
    if (item.meta?.visible === undefined) return
    return typeof item.meta.visible === 'function'
      ? item.meta.visible()
      : item.meta.visible
  }
}
