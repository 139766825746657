import { LeaseVariationKindWithField } from '@register'
import { exhaustiveMatchingGuard } from '~/utils/exhaustiveMatchingGuard'

export function localizeVariationKindWithField(
  kind: LeaseVariationKindWithField,
) {
  switch (kind) {
    case LeaseVariationKindWithField.ADD_VARIATION:
    case LeaseVariationKindWithField.CHANGE_VARIATION:
      return 'Add or change a term'
    case LeaseVariationKindWithField.ADD_CLAUSE:
    case LeaseVariationKindWithField.CHANGE_CLAUSE:
      return 'Add or change a clause'
    case LeaseVariationKindWithField.REMOVE_CLAUSE:
      return 'Remove a clause'
    case LeaseVariationKindWithField.ADD_DEFINITION:
    case LeaseVariationKindWithField.CHANGE_DEFINITION:
      return 'Add or change a definition'
    case LeaseVariationKindWithField.REMOVE_DEFINITION:
      return 'Remove a definition'
    default:
      exhaustiveMatchingGuard(kind)
  }
}
