<script setup lang="ts">
import { useForm } from './composables'
import type { IOptionExerciseDetailsForm } from './types'

const props = withDefaults(
  defineProps<{
    optionExerciseDetail: IOptionExerciseDetailsForm
    isManager?: boolean
  }>(),
  {
    isManager: false,
  },
)
const emit = defineEmits<{
  (event: 'close'): void
  (event: 'confirm', value: IOptionExerciseDetailsForm): void
}>()

// FORM
const { form, onSubmit, initialFormValue } = useForm(toRefs(props))
</script>

<template>
  <ModalBase
    title="Update option exercise detail"
    primary-button-text="Update option exercise"
    modal-class="max-w-[650px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    @confirm="form?.node.submit()"
    @close="$emit('close')"
  >
    <FormKit
      ref="form"
      type="form"
      name="RentIncreaseTableValue"
      :actions="false"
      form-class="p-4 mt-4 rounded-lg bg-gray-850"
      :value="initialFormValue()"
      @submit="onSubmit"
    >
      <div class="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
        <!-- EXERCISE - START DATE -->
        <FormKit
          key="exerciseStartDate"
          type="datepicker"
          name="exerciseStartDate"
          label="Exercise Start Date"
          validation="required"
        />

        <!-- EXERCISE - END DATE -->
        <FormKit
          key="exerciseEndDate"
          type="datepicker"
          name="exerciseEndDate"
          label="Exercise End Date"
          validation="required"
        />
      </div>
    </FormKit>
  </ModalBase>
</template>
