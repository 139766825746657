import type { ILeaseRegisterSettings } from '@register'

export const FALLBACK_LEASE_DATE_FORMAT = 'D MMM YYYY'

export const getLeaseDateFormat = (
  lease?: { settings?: ILeaseRegisterSettings },
  fallback: string = FALLBACK_LEASE_DATE_FORMAT,
) => {
  return lease?.settings?.formatting?.date.mask ?? fallback
}
