<script setup lang="ts">
import ActionButton from '../ActionButton.vue'

import type { LeaseDocumentInputContext } from '../../types'
import { useClipboard } from '@vueuse/core'
import { toast } from 'vue3-toastify'

const props = defineProps<{ context: LeaseDocumentInputContext }>()

const { copy } = useClipboard()
const disabled = computed(() => props.context.disabled)
const onClick = async () => {
  try {
    await copy(props.context._value.value as string)
    toast.success(`Copied to clipboard`)
    useAnalytics({
      name: 'Lease Field Toolbar Actions',
      data: {
        source: 'Copy To Clipboard',
        'Is Edited': props.context.isEdited,
        'Field ID': props.context.id,
        'Field Value': props.context._value.value || props.context.value,
      },
    })
  } catch (error) {
    toast.error(`Error copying to clipboard`)
    useAnalytics({
      name: 'Copy To Clipboard Error',
      data: {
        source: 'Undo',
        'Is Edited': props.context.isEdited,
        'Field ID': props.context.id,
        'Field Value': props.context._value.value || props.context.value,
        context: props.context,
      },
    })
  }
}
</script>

<template>
  <ActionButton
    tooltip="Copy to clipboard"
    icon="copy-clipboard"
    :disabled="disabled"
    @click="onClick"
  />
</template>
